import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";

export const fetchRegionsAll= () => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    try {
        const response = await api.get(`practicesInfo/regions`);
        dispatch({
            type: ActionType.FETCH_ALL_REGIONS,
            payload: { regionsList: response },
        });
        return response;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

export const fetchFilteredRegions= (params: any) => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    try {
        const response = await api.get(`practicesInfo/regions-filtered/${params}`);
        dispatch({
            type: ActionType.FETCH_FILTERED_REGIONS,
            payload: { regionsList: response },
        });
        return response;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};