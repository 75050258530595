import { ActionType } from "../../model/actionTypes";
import {PracticeList, RegionsManagersList,PMDSchedulesList, PracticeInfo} from "../../model/practice";

export const fetchPractices = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PracticeList[] = await api.get("practicesInfo/getPracticeList",{},{},getState().auth.accessToken);
		
		dispatch({ type: ActionType.LOAD_PRACTICES, payload: { practiceList: response }});
		dispatch({ type: ActionType.FETCH_PRACTICES_DB, payload: response});

		return response;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PRACTICES, payload: { practiceOptions: [] } });
	}
};

export const fetchAllPractices = (params: string) => async (dispatch: Function, getState: Function, api: any) => {
	try {

		let stringifyParams;
	if(params){
		stringifyParams = JSON.stringify(params)
	}
		const response:PracticeList[] = await api.get(`practicesInfo/getAllPracticeList/${stringifyParams}`,{},{},getState().auth.accessToken);
		
		dispatch({ type: ActionType.LOAD_ALL_PRACTICES, payload: { practiceListAll: response }});
		dispatch({ type: ActionType.FETCH_PRACTICES_DB, payload: response});

		return response;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PRACTICES, payload: { practiceOptions: [] } });
	}
};

export const fetchPractice = (params: string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PracticeList = await api.get(`practicesInfo/getPractice/${params}`);
		dispatch({ type: ActionType.FETCH_PRACTICE, payload: response});
		dispatch({ type: ActionType.FETCH_PRACTICES_DB, payload: response});

		return response;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PRACTICE, payload: { practice: {} } });
	}
};

export const fetchAllRegions = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:any = await api.get("practicesInfo/regions");
		dispatch({ type: ActionType.FETCH_REGIONS, payload: response });
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_REGIONS, payload: { err } });
	}
};

export const fetchRegionManagers = (callBackFunc: Function) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:RegionsManagersList = await api.get("practicesInfo/regionmanagers");
		const regionManagersOptions:[] = callBackFunc(response);
		dispatch({ type: ActionType.FETCH_REGIONMANAGERS, payload: { regionManagersOptions: regionManagersOptions} });
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_REGIONMANAGERS, payload: { regionManagersOptions: [] } });
	}
};


export const fetchScheduleDetails = (callBackFunc: Function, params: string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PMDSchedulesList = await api.get(`practicesInfo/schedules/${params}`);
		const schedulePMDDataOptions:[] = callBackFunc(response);
		dispatch({ type: ActionType.FETCH_PMD_SCHEDULES, payload: { schedulePMDDataOptions: schedulePMDDataOptions} });
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PMD_SCHEDULES, payload: { schedulePMDDataOptions: [] } });
	}
};

export const fetchPracticesInfo = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PracticeInfo = await api.get(`practicesInfo/all`);
		dispatch({ type: ActionType.FETCH_PRACTICES_INFO, payload: { practiceInfoList: response } });
		return response;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PRACTICES_INFO, payload: { practiceInfoList: {} } });
	}
};
