import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";


export const get = (params: any = {}) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(`patient/call_log/${JSON.stringify(params)}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


export const add = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.post(`patient/call_log`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


export const update = (callLog: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const payload = {
            id: callLog.id,
            notes: callLog.notes,
            leadStatus:callLog.leadStatus,
            callType: callLog.callType,
            lostReason: callLog.lostReason,
            callResult: callLog.callResult,
            followupDate: callLog.followupDate,
            preferredTimes: callLog.preferredTimes,
            speciality: callLog.speciality
        }
        const resp = await api.put(`patient/call_log`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


