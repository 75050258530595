import React from "react";
import Template from "../components/Template";
import ReportsX from "../components/Reports";

function Reports() {

  return (
    <Template activeLink='reports'>
      <ReportsX/>
    </Template>
  );

}

export default Reports;