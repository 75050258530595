export enum ActionType {
  LOADER,
  LOAD,
  LOAD_SUCCESS,
  ALERT,
  LOAD_SESSION,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  SIDEBAR_OPEN,
  FETCH_PRACTICES,
  FETCH_PRACTICE,
  FETCH_REGIONS,
  FETCH_REGIONMANAGERS,
  FETCH_PMD_SCHEDULES,
  LOAD_DENTAL_PARTNERS,
  LOAD_DENTAL_PARTNERS_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORT_PER_PRACTICE,
  FETCH_SCHEDULE,
  FETCH_BUDGET,
  FETCH_BUDGET_PER_PRACTICE,
  SAVE_DENTAL_PARTNERS_USAGE,
  SAVE_DENTAL_PARTNERS_USAGE_SUCCESS,
  SEND_REPORT,
  FETCH_CLINICAL_SCHEDULE,
  ADJUSTMENT_SAVE_SUCCESS,
  ACKNOLEGDEMENT,
  FETCH_REFERRALS,
  FETCH_REFERRALS_WORK,
  FETCH_FIVE9_LIST,
  FETCH_FIVE9_PRACTICE_LIST,
  FIVE9_SAVE_SUCCESS,
  FETCH_REPORT_LAST_MONTHS,
  PATIENTS_VALIDATION,
  FETCH_PRACTICES_DB,
  LOAD_MDM_INFO_SUCCESS,
  SEND_FEEDBACK,
  SAVE_USAGE,
  FETCH_OPEN_SURGERY,
  LOAD_MDM_DATASOURCE,
  LOAD_MDM_PAGES,
  LOAD_MDM_REPORTS,
  FETCH_APT,
  PATIENT_VALIDATION_DEFAULT_PRACTICES,
  CARRIER_IDENTITY,
  SAVE_USAGE_PTV,
  UPDATE_OS_BUDGET,
  FETCH_PATIENT_VALIDATION_REPORT,
  MDHM_CONFIRMATION,
  GET_HELP_MARK,
  DEFAULT_PRACTICES,
  MDHM_WRITE_OFF_REPORT,
  SEND_EMAIL_REFER_STATS,
  PMD_DEFAULT_PRACTICES,
  PMD_MCO_NPW_LIST,
  PMD_MCO_RCN_LIST,
  PMD_TASKS,
  PMD_BILLER,
  FETCH_PATIENT_VALIDATION_ERROR_REPORT,
  PMD_SCHEDULE,
  XRAY_STATUS,
  LOAD_SPORE_TABLE_RESULT,
  LOAD_SPORE_USER_PRACTICES,
  LOAD_SPORE_NOTIFICATION_OMRM,
  LOAD_SPORE_NOTIFICATION_TABLE,
  SPORE_NOTIFICATION_SAVE_STATES,
  LOAD_SPORENOTIFICATION_USER_PRACTICES,
  MDHM_WRITE_OFF_D_REPORT,
  SAVE_USAGE_WO,
  LOAD_PRACTICES,
  LOAD_ALL_PRACTICES,
  LEADS_REPORT,
  PMD_SCHEDULE_COORDINATOR,
  PMD_SCHEDULE_COORDINATOR_WTX,
  PMD_SCHEDULE_COORDINATOR_APT,
  PMB_PRIORITY,
  PMD_SCHEDULE_TODAY_30_DAY_APTS,
  PMD_SCHEDULE_TODAY_30_DAY_COMPLETED,
  PMD_SCHEDULE_TODAY_30_DAY_PLAN_SCHEDULED_APTS,
  PMD_SCHEDULE_TODAY_SCH_APT_TODAY,
  PMD_SCHEDULE_TODAY_PT_SEEN_TODAY,
  PMD_TODAYS_PRODUCTION,
  FETCH_BUDGET_PERIODICAL,
  PMD_SCHEDULE_CHART_APTFILL_DAYAPT,
  FETCH_PROCEDURE_CODE_LIST,
  FETCH_PROCEDURE_CODE_DETAILS_LIST,
  PROCEDURE_CODE_SAVE_SUCCESS,
  REFERRALS_EXT_SAVE_SUCCESS,
  LOAD_LIST,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_FAIL,
  LOAD_BILLING,
  LOAD_BILLING_SUCCESS,
  LOAD_REPORT_SUCCESS,
  LOAD_REPORT_FAIL,
  LOAD_REPORT_DETAILS_SUCCESS,
  LOAD_REPORT_DETAILS_FAIL,
  LOAD_BILLING_PRACTICES_SUCCESS,
  LOAD_BILLING_FAMILY_SUCCESS,
  LOAD_BILLING_FAMILY_FAIL,
  LOAD_BILLING_PRACTICES_FAIL,
  SAVE_TODAY_PRODUCTION,
  CHANGE_INTERVAL_REQUEST_COUNT,
  LOAD_FAIL,
  SAVE_PARAMS,
  LOAD_FAMILY,
  LOAD_TREATMENT_LIST,
  LOAD_TREATMENT_PLAN,
  LOAD_COMMLOG,
  LOAD_APT_RESULT,
  CLOSE_ERROR_MESSAGE,
  LOAD_NEXT_DAYS,
  FAIL_NEXT_DAYS,
  LOAD_MEDICAL,
  LOAD_MEDICAL_FAIL,
  FETCH_ORAL_SURGERY_STATUSES,
  FETCH_BY_ID_ORAL_SURGERY_STATUS,
  SAVE_ORAL_SURGERY_STATUSES,
  DELETE_ORAL_SURGERY_STATUSES,
  UPDATE_ORAL_SURGERY_STATUS,
  FETCH_REFER_OUT_STATS,
  FETCH_PROVIDER_LIST,
  FETCH_PROVIDER,
  LOAD_BILLING_FAMILY_SUCCES,
  FETCH_ALL_REGIONS,
  FETCH_FILTERED_REGIONS,
  FETCH_PRACTICES_INFO
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}
