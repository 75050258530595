import React, { useEffect, useState } from 'react';
import { useActions } from "../redux/actions";
import { Dropdown, List, Icon, DropdownItemProps } from "semantic-ui-react";
import { practiceType, statusList } from '../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { connect } from "react-redux";
import { RootState } from "../model";
import { Referral } from '../model/referral';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PracticeList } from '../model/practice';
import * as practiceActions from "../redux/actions/practice";
import * as referralActions from "../redux/actions/referrals";
import * as billingActions from '../redux/actions/billing';
import OralReferIn from "../styles/images/oral-in.png";
import OralReferOut from "../styles/images/oral-out.png";
import OrthoRefOut from "../styles/images/ortho-out.png";
import OrthoRefIn from "../styles/images/ortho-in.png";
import { capitalizeFirstLetters, findPractice, verifyDefaultPractice } from '../utils/common';
import ReferralDetailsModal from './ReferralDetailsModal';
import RefferalStatusOptions from "./RefferalStatusOptions";
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import * as reportActions from "../redux/actions/report";
import * as Actions from "../redux/actions/userSetup";
import DatePicker from "react-datepicker";
import CustomToolBar from './CustomToolBar';
import { SetupFields } from '../enums/userSetpField';
import { SetupAppName } from '../enums/userSetup';
import { Patients } from '../model/patients';
import { UserSetup } from '../model/userSetup';
import { DropdownProps } from 'reactstrap';

const localizer = momentLocalizer(moment)

interface ReferralsProps {
    referrals: Array<Referral>;
    practiceList: Array<PracticeList>;
    practiceListAll: Array<PracticeList>;
    production: any;
    familyData: any;
    auth: any;
    userSetup: UserSetup;
}

const Referrals = (props: ReferralsProps) => {
    const practiceAction = useActions(practiceActions);
    const referralAction = useActions(referralActions);
    const billingAction = useActions(billingActions);
    const StatusActions = useActions(statusActions);
    const userSettingsActions = useActions(Actions);
    const reportAction = useActions(reportActions);
    const [oralSurgeryStatus, setOralSurgeryStatus] = useState({});
    let reportData: any = [];
    const initialState: any = {
        referralModal: false,
        detailsModalType: '',
        detailsModal: false,
        eventClick: false,
        eventDate: false,
        productionPrice: false,
        practicePriceList: false,
        startDate: moment().format('Y-MM-01'),
        todayProduction: {},
        filterPractice: 'All',
        selectedPT_Num: null,
        stateHistory: null,
        openStatusDropdown: false,
        statusList: {},
        type: 'all'
    };
    const [state, setState] = useState(initialState);
    const [statusTitle, SetStatusTitle] = useState('');
    const [reportType, SetReportType] = useState('');


    useEffect(() => {
        setData()
    }, []);

    const setData = async() => {
        let userSetup = props.userSetup
        const practiceListAll = await practiceAction.fetchAllPractices({ type: 'all' });
        if (!props.userSetup.value) {
            let userParams = { field: SetupFields.Practice, appId: SetupAppName.FIELD };
            userSetup = await userSettingsActions.getSetup(userParams);
        }
        let params = {
            startDate: state.startDate,
            endDate: moment(state.startDate, "YYYY-MM-DD").endOf('month').format('Y-MM-DD'),
            filterPractice: state.filterPractice,
            type: state.type
        }
        if (userSetup && userSetup.value !== undefined) {
            params.filterPractice = userSetup.value;
            let defaultPractices: any = []
            if (userSetup.value !== 'all') {
                const practiceOptions = userSetup.value.split(',');
                practiceOptions.forEach((practiceOption: any) => {
                    if (practiceOption !== 'all') {
                        const practice = findPractice(practiceListAll, practiceOption) || { practice: 'all' };
                        if (practice.practice !== 'all') {
                            defaultPractices.push(practice.practice)
                        }
                    }
                })
            } else {
                defaultPractices = 'All'
            }

            setState({...state, filterPractice: defaultPractices})
        }

        setTimeout(() => {
            practiceAction.fetchPractices();
            referralAction.allReferral(params);
        }, 1000)
        const queryParams = new URLSearchParams(window.location.search);
        const practice = queryParams.get("practice");
        const practiceName = queryParams.get("practiceName");
        const selectedPT_Num = queryParams.get("selectedPT_Num");

        if (practice && practiceName && selectedPT_Num) {
            setTimeout(() => billingAction.openFamilyView(selectedPT_Num, practice, practiceName), 5.0 * 1000);

        }
    }

    const onChangeFilter = (e: any, element: any) => {
        setState({ ...state, type: element.value })
        let params = {
            startDate: state.startDate,
            endDate: moment(state.startDate, "YYYY-MM-DD").endOf('month').format('Y-MM-DD'),
            filterPractice: state.filterPractice,
            type: element.value
        }
        setTimeout(() => {
            referralAction.allReferral(params);
        }, 1000)
    };



    const eventTitle = (type: string, price: number) => {
        return <div style={{ backgroundColor: "#FFFFFF", color: "#000000" }} id={type}><span>{capitalizeFirstLetter(type)} : </span> {price} <br /></div>;
    }




    const capitalizeFirstLetter = (s: string) => {
        if (s === 'oralRefOut') {
            return (<img alt="" className="cursorPointer" src={OralReferOut} />);
        } else if (s === 'orthoRefOut') {
            return (<img alt="referin" className="cursorPointer" src={OrthoRefOut} />);
        }
        else if (s === 'orthoRefIn') {
            return (<img alt="oralRefOut" className="cursorPointer" src={OrthoRefIn} />);
        }
        else if (s === 'oralRefIn') {
            return (<img alt="oralRefIn" className="cursorPointer" src={OralReferIn} />);
        } else {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }

    }


    const sortCalendarBillingList = (referralData: any) => {
        let sortList = {}
        let returnSortList: any = []
        const types = ['orthoRefOut', 'oralRefOut', 'oralRefIn', 'orthoRefIn'];


        const indexKey = 'production';
        if (Object.keys(referralData[indexKey]).length && Object.keys(referralData[indexKey].orderByDate).length) {
            Object.keys(referralData[indexKey].orderByDate).forEach((dateKey) => {
                const item = referralData[indexKey].orderByDate[dateKey];
                types.forEach(type => {
                    if (item[type]) {
                        returnSortList.push({
                            id: dateKey,
                            type: type,
                            price: item[type],
                            title: eventTitle(type, item[type]),
                            allDay: true,
                            start: moment(dateKey + ' 13:00:00'),
                            end: moment(dateKey + ' 13:00:00'),
                            hoverTitle: capitalizeFirstLetters(type, item[type])
                        });
                    }
                });
            });
        }

        return returnSortList;
    }
    let calendarBillingList = [];
    if (Object.keys(props.production).length > 0) {
        calendarBillingList = sortCalendarBillingList(props);
    }

    const onSelectEvent = (event: any, type = 'report', eventClick = 'day') => {
        let stateObj = { detailsModal: type, detailsModalType: event.type, eventClick: eventClick, eventDate: event.id, productionPrice: 0 }
        if (event.price)
            stateObj.productionPrice = event.price
        setState({ ...state, detailsModal: type, detailsModalType: event.type, eventClick: eventClick, eventDate: event.id, productionPrice: stateObj.productionPrice });
    }

    const onNavigate = (selectedDate: any, view: any) => {
        const startDate = moment(selectedDate).format('Y-MM-01');
        const currentMonth = moment().format('Y-MM-01');
        if ((state.startDate != startDate && currentMonth >= startDate)) {
            let params = {
                startDate: startDate,
                endDate: moment(selectedDate).endOf('month').format('Y-MM-DD'),
                filterPractice: state.filterPractice
            }
            setState({ ...state, startDate: startDate });
            referralAction.allReferral(params);

        }
    }

    const getDefaultPractices = () => {
        const practiceObj = props.userSetup;
        let defaultPractices: string[] = [];
        if (practiceObj && practiceObj.value !== undefined && practiceObj.value !== 'all') {
          const practiceDbNameList = verifyDefaultPractice(practiceObj.value);
          if(practiceDbNameList.length > 0){
            practiceDbNameList.forEach((practice: string) => {
              if(props.practiceListAll!==undefined){
                const practiceObj = findPractice(props.practiceListAll, practice) || {practice: 'all'};
                defaultPractices.push(practiceObj.practice);
              } else {
                defaultPractices.push(practice);
              }
              
            });
          }
        } else if (practiceObj && practiceObj.value === 'all') {
          defaultPractices = ['All'];
        }
        return defaultPractices;
      }

    const openFamilyView = async (selectedPT_Num: any, practice: any, practiceName: any) => {
        if (state.selectedPT_Num !== selectedPT_Num || state.selectedPractice !== practice)
            await billingAction.getFamilyPatients(selectedPT_Num, practice);

        const stateObj = { detailsModal: 'familyView', detailsModalType: 'familyView', selectedPT_Num: selectedPT_Num, selectedPractice: practice, selectedPracticeName: practiceName }
        if (state.stateHistory !== null && state.stateHistory !== undefined && (!state.stateHistory[state.stateHistory.length - 1] ||
            state.stateHistory[state.stateHistory.length - 1].detailsModalType !== 'familyView'))
            state.stateHistory.push(stateObj);
        setState({ ...state, detailsModalType: 'familyView', selectedPT_Num: selectedPT_Num, selectedPractice: practice, selectedPracticeName: practiceName, stateHistory: state.stateHistory });
    }

    const changeTab = (selectedPT_Num: any) => {
        if (state.selectedPT_Num !== selectedPT_Num)
            billingActions.getFamilyPatientDetails(selectedPT_Num, state.selectedPractice);
        setState({ ...state, 'selectedPT_Num': selectedPT_Num });
    }

    const hideDetailsModal = () => {
        setState({ ...state, detailsModal: false, detailsModalType: false, referralModal: false });
    }
    const getMonthlyData = (type: string) => {
        StatusActions.fetchOralSurgeryStatus().then((res: any) => {
            setOralSurgeryStatus(res);
        })
        setState({ ...state, detailsModal: 'report', detailsModalType: type, eventClick: 'month' });
    }

    const sendReport = (typeData: [], name: string, detailsModalType: string, title: string) => {
        const filterData = {
            typeData: typeData,
            name: name,
            detailsModalType: detailsModalType,
            title: title
        }
        reportAction.sendReferStatsReportDetails(filterData);
    };

    const openDropdown = (
        title: string,
        type: string,
    ) => {
        StatusActions.fetchOralSurgeryStatus().then((res: any) => {
            setOralSurgeryStatus(res);
        })
        SetReportType(type)
        SetStatusTitle(title)
        setState({ ...state, openStatusDropdown: true });
    }

    const handleStatusMenuClick = () => {
        setState({ ...state, openStatusDropdown: !state.openStatusDropdown });
    }

    if (props.production.reportData) {
        reportData = props.production.reportData
    }


    const totals = {
        orthoRefIn: props.production.orthoRefIn,
        orthoRefOut: props.production.orthoRefOut,
        oralRefIn: props.production.oralReferIn,
        oralRefOut: props.production.oralRefOut
    }

    const onChangePractice = (e:any, data: any ) => {
        if(data.value.length < 1){
            data.value = ['All'];
        }
            let params = {
                startDate: state.startDate,
                endDate: moment(state.startDate, "YYYY-MM-DD").endOf('month').format('Y-MM-DD'),
                filterPractice: data.value.join(','),
                type: state.type
            }
            setTimeout(() => {
                referralAction.allReferral(params);
            }, 1000)
            setState({...state, filterPractice: data.value});
        }

    return (
        <div className="calendar-container">
            {(state.detailsModal) && <ReferralDetailsModal
                detailsModalType={state.detailsModalType}
                hideDetailsModal={hideDetailsModal}
                detailsModal={state.detailsModal}
                onSelectEvent={onSelectEvent}
                sendReport={sendReport}
                reportData={reportData}
                eventClick={state.eventClick}
                eventDate={state.eventDate}
                productionPrice={state.productionPrice}
                startDate={state.startDate}
                showPracticeList={state.showPracticeList}
                practicePriceList={state.practicePriceList}
                todayProduction={state.todayProduction}
                practiceList={props.practiceListAll}
                openFamilyView={openFamilyView}
                selectedPractice={state.selectedPractice}
                selectedPT_Num={state.selectedPT_Num}
                selectedPracticeName={state.selectedPracticeName}
                changeTab={changeTab}
                familyData={props.familyData}
                statuses={oralSurgeryStatus}
                auth={props.auth}
            />}
            <div className="row">
                <div className="col-12">
                    <h2 className="float-left">All Referrals</h2>

                    <div className="topFilters">
                        <List horizontal className="mr30 mb15 dropdown">
                            <List.Item className="textCenter">
                                {<span className="cursorPointer" onClick={() => openDropdown('Ortho Referrals (Out)', 'orthoRefOut')}>
                                    <img alt="referout" src={OrthoRefOut} />: <b>{props.production.orthoRefOut}</b></span>
                                }
                            </List.Item>
                            <List.Item>
                                {<span className="cursorPointer" onClick={() => openDropdown('Ortho Referrals (In)', 'orthoRefIn')}>
                                    <img alt="referin" src={OrthoRefIn} />: <b>{props.production.orthoRefIn}</b></span>
                                }
                            </List.Item>
                            <List.Item className="textCenter">
                                {<span className="cursorPointer" onClick={() => openDropdown('Oral Surgery Referrals (Out)', 'oralRefOut')}>
                                    <img alt="referout" src={OralReferOut} />: <b>{props.production.oralRefOut}</b></span>
                                }
                            </List.Item>
                            <List.Item>
                                {<span className="cursorPointer" onClick={() => openDropdown('Oral Surgery Referrals (In)', 'oralRefIn')}>
                                    <img alt="referin" src={OralReferIn} />: <b>{props.production.oralRefIn}</b></span>
                                }
                            </List.Item>
                            {
                                state.openStatusDropdown && <RefferalStatusOptions
                                    openStatusMenu={state.openStatusDropdown}
                                    statusTitle={statusTitle}
                                    getMonthlyData={getMonthlyData}
                                    handleStatusMenuClick={handleStatusMenuClick}
                                    reportData={reportData}
                                    reportType={reportType}
                                    statuses={oralSurgeryStatus}
                                    totals={totals}
                                />
                            }
                        </List>

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="practice"
                            placeholder="Practice"
                            selection={true}
                            multiple={true}
                            options={
                                props.practiceListAll
                                    ? [{
                                        key: 'All',
                                        value: 'All',
                                        text: 'All'
                                    }, ...props.practiceListAll.map((practice: PracticeList) => {
                                        return {
                                            key: practice.id,
                                            value: practice.practice,
                                            text: practice.practice,
                                        };
                                    })]
                                    : []
                            }
                            onChange={onChangePractice}
                            value={state.filterPractice}

                        />
                        <Dropdown
                            search
                            className='mr10 mb15'
                            name="practiceType"
                            placeholder="Practice Type"
                            selection
                            options={practiceType}
                            onChange={onChangeFilter}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <Calendar
                        onSelectEvent={event => onSelectEvent(event)}
                        localizer={localizer}
                        events={calendarBillingList}
                        startAccessor="start"
                        endAccessor="end"
                        tooltipAccessor="hoverTitle"
                        onNavigate={(date, view) => onNavigate(date, view)}
                        popup={true}
                        showMultiDayTimes={false}
                        defaultDate={new Date(moment().startOf('month').toString())}
                        style={{ height: 800 }}
                        components={{toolbar : CustomToolBar}}
                    />

                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        referrals: state.referrals.production,
        production: state.referrals.production,
        practiceList: state.practice.practiceList,
        userSetup: state.userSetup,
        practiceListAll: state.practice.practiceListAll,
        familyData: state.billing.familyData,
        auth: state.auth,

    };
}

export default connect(mapStateToProps)(Referrals);


