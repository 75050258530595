import { ActionType } from "../../model/actionTypes";
import {ProcedureCodeDetails} from "../../model/procedureCodeList";
import {errorHandler} from "./auth";

export const fetchProcedureCodeList = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`oralCodes/getProcedureCodeList`);
		dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_LIST, payload: { procedureCodeLists: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_LIST, payload: { procedureCodeLists: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchProcedureCodeListOrtho = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`orthoCodes/getProcedureCodeList`);
		dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_LIST, payload: { procedureCodeLists: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_LIST, payload: { procedureCodeLists: [] } });
		return dispatch(errorHandler(err));
	}
};

export const updateProcedureCodeDetails = (obj: ProcedureCodeDetails) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("oralCodes/updateProcedureCodeList", obj);
		dispatch({ type: ActionType.PROCEDURE_CODE_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const deleteProcedureCodeDetails = (obj: ProcedureCodeDetails) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.delete("oralCodes/deleteProcedureCodeList", obj);
		dispatch({ type: ActionType.PROCEDURE_CODE_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveProcedureCodeDetails = (obj: ProcedureCodeDetails) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("oralCodes/saveProcedureCodeList", obj);
		dispatch({ type: ActionType.PROCEDURE_CODE_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getProcedureCodeListDetails=()=>async (dispatch:Function,getState:Function,api:any)=>{
	dispatch({ type: ActionType.LOADER, payload: true });
	try{
		const resp =  await api.get("oralCodes/procedureCodeList");
		dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_DETAILS_LIST, payload: {
			procedureCodeDetailsList:resp 
		}});
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	}catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
}