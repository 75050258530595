import React, {useEffect, useState, useRef} from "react";
import {DynamicStatsTable} from "../model/dynamicStatusTable";
import { Icon } from 'semantic-ui-react';
import {DeleteStatusModal} from "./DeleteStatusModal";
import {EditStatusModal} from "./EditStatusModal";
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {useActions} from "../redux/actions";

export default function DynamicStatusTable(props: DynamicStatsTable) {
    const initialState: any = {
        TableHead: [],
        TableData: []
    };
    let [states, setState] = useState(initialState);
    const [deleteStatusModal, SetDeleteStatusModal] = useState(false);
    const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
    const [statusId, SetStatusId] = useState(null);
    const [statusDetails, SetStatusDetails] = useState({});
    const [isNotStarted, SetIsNotStarted] = useState({});
    const StatusActions = useActions(statusActions);

    useEffect(() => {
        const getTableHeadings = () => {
            let returnArray:any = [];
            if(props.tableHead.length) {
                props.tableHead.forEach((item, key) => {
                    let index = -1;
                    const title:any = item.description;
                    if (props.excludeSorting) {
                        index = props.excludeSorting.findIndex((head: string) => head.toLowerCase() === title.toLowerCase())
                    }
                    const icon = props.onHeadClick ? (props.sortedColumn === (item.key||key) ? <Icon name={props.direction}/> : <Icon name={'sort'}/>) : null;
                    returnArray.push(
                        <th
                            className={item.className}
                            id={item.id}
                            style={item.style}
                            key={item.key||key}
                            onClick={props.onHeadClick ? props.onHeadClick(item.key||key) : void(0)}
                        >
                            {/*<div>{item.title}  {icon} </div>*/}
                            {index === -1 && item.description!=="Edit/Delete" && <div>{item.description}  {icon} </div>}

                            {index > -1 && <div>{item.description} </div>}
                        </th>)
                })
            }
            return (<thead><tr>{returnArray}</tr></thead>)
        };

        const editStatus = (id: any) => {
            const payload = {
                id: id
            }
            StatusActions.fetchByIdOralSurgeryStatus(payload).then((res: any) => {
                StatusActions.fetchByIdOralSurgeryStatus(payload).then((res: any) => {
                    SetStatusId(id);
                    SetStatusDetails(res);
                    setOpenEditStatusModal(true);
                })
            })

        }

        const OptionButtons: any = (id: any) => {
            return (
                <div>
                    <Icon
                        name="edit"
                        onClick={async() => editStatus(id)}
                    />
                    <Icon
                        className="cursorPointer"
                        name="trash"
                        onClick={() => {
                            SetStatusId(id);
                            SetDeleteStatusModal(true);
                        }}
                    />
                </div>
            )
        }

        const getTableBody = () => {
            let tableRows:any = [];
            if(props.tableData.length) {
                props.tableData.forEach((itemArray, key) => {
                    if(itemArray.length) {
                        let tableColumns:any = [];
                        itemArray.forEach((item, key2) => {
                            let row = Object.entries(item);
                            row.forEach((el, key3) => {
                                tableColumns.push(
                                    key3 === 2 ? <td className={item.className} id={item.id}  style={item.style} key={item.key||key3}>
                                            <div className="statusSetupColorContainer">
                                                <div className="statusSetupColor" style={{backgroundColor: el[1] ? el[1] : "red", border: el[1] && el[1].toLowerCase() == "#ffffff" ? "1px solid #59D69A" : ""}}/>
                                            </div>
                                        </td> :
                                        key3 === 3 && item.description !== "Not Started" ? <td className="statusSetupOptionButtons">
                                                {OptionButtons(el[1])}
                                            </td> : key3 === 3 ? <td></td> :
                                            key3 !== 2?
                                                <td className={item.className} id={item.id}  style={item.style} key={item.key||key3}>{el[1]}</td>: null
                                )
                            })

                        });
                        tableRows.push(<tr key={key}>{tableColumns}</tr>)
                    }
                })
            }
            return (<tbody>{tableRows}</tbody>)
        };

        const TableData = getTableBody();
        const TableHead = getTableHeadings();
        setState({ TableData, TableHead })
    }, [props.tableData])

    return (<div className="ui card">

        <div className="content">
            <h5>{props.heading}</h5>
            <div className="table-responsive FreezeTable">
                <table style={props.style} className={`${ props.className !== '' ? `ui celled single line table unstackable tableStyle ${props.className}` : "table table-striped table-hover" }`}>
                    {states.TableHead}
                    {states.TableData}
                </table>
            </div>
            <DeleteStatusModal
                deleteStatusModal={deleteStatusModal}
                SetDeleteStatusModal={SetDeleteStatusModal}
                id={statusId}
                setData={props.setData}
            />
            <EditStatusModal
                openEditStatusModal={openEditStatusModal}
                setOpenEditStatusModal={setOpenEditStatusModal}
                setOralEditSurgeryStatus={props.setData}
                id={statusId}
                data={statusDetails}
                setData={SetStatusDetails}
                type={props.type}
            />
        </div>
    </div>)

}
