import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, Router} from "react-router-dom";
import AppRoutes from "./AppRoutes";
import {history} from "./redux/configure";
import {User} from "./model/user";
import {Alert, RootState} from "./model";
import 'semantic-ui-css/semantic.css';
import './styles/css/style.css';
import { PMDPracticeContext} from './PMDPracticeContext';

interface Props extends RouteComponentProps<void> {
	auth: User,
	alert: Alert
}

function App(props?: Props) {
	const [theme, setTheme] = React.useState('');
	if (!props) {
		return null;
	}
	return (
		<PMDPracticeContext.Provider value={{ theme, setTheme }}>
		<Router history={history}>
			<AppRoutes auth={props.auth} alert={props.alert} />

		</Router>
		</PMDPracticeContext.Provider>
	);
}

function mapStateToProps(state: RootState) {
	return {
		auth: state.auth,
		alert: state.alert
	};
}

export default connect(mapStateToProps)(App);
