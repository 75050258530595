import * as React from "react";
import * as ReactDOM from "react-dom";
import AppRedux from "./AppRedux";

const rootElement = document.getElementById("root");
ReactDOM.render(<AppRedux/>, rootElement);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
// serviceWorker.unregister();
