import React, {useEffect, useState} from 'react';
import {useActions} from "../redux/actions";
import {Button} from 'semantic-ui-react';

import DynamicStatusTable from "./DynamicStatusTable";
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {SaveStatusModal} from "./SaveStatusModal";
import CountUpTimer from './CountUpTimer';

function StatusSetupX(props: {type: String}) {
    const StatusActions = useActions(statusActions);
    const [oralSurgeryStatus, setOralSurgeryStatus] = useState({});
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        StatusActions.fetchOralSurgeryStatus().then((res : any) => {
            setOralSurgeryStatus(res);
            setLoading(false);
        })
    }, []);

    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {description: 'Sequence'};
        TableHead[1] = {description: 'Refer Status'}
        TableHead[2] = {description: 'Status'}
        TableHead[3] = {description: 'Options'}
        return TableHead;
    };

    function createStatus(obj: any)  {
        let newArray = [];
        for(let i=0; i<obj.length; i++) {
            if (obj[i].type !== props.type) continue;
            newArray.push([{
                sequence: obj[i].sequence,
                description: obj[i].referStatus,
                statusColor: obj[i].statusColor,
                id: obj[i].id
            }]);
        }
        return newArray;
    }
    const status = createStatus(oralSurgeryStatus);
    const openSaveStatusModal = () => {
        setOpenStatusModal(prev => !prev);
    }
    const TableHead = TableHeadData();
    return (
        <div className="container">
            {
                loading && <CountUpTimer />
            }
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        {props.type.charAt(0).toUpperCase()}{props.type.substr(1)} Surgery Status Setup
                    </h2>
                    <Button primary className="cursorPointer float-right mr10 mb15" onClick={()=>openSaveStatusModal()}>NEW STATUS</Button>
                </div>
            </div>

            <SaveStatusModal
                openStatusModal={openStatusModal}
                setOpenStatusModal={setOpenStatusModal}
                setOralSurgeryStatus={setOralSurgeryStatus}
                id={null}
                type={props.type}
            />

            <div className="ui card">
                <div className="content">
                    <div className="">
                        <DynamicStatusTable
                            key={0}
                            className={'table celled single line table unstackable tableStyle table-striped table-hover statsSetup'}
                            tableHead={TableHead}
                            heading={""}
                            tableData={status}
                            setData={setOralSurgeryStatus}
                            type={props.type.toString()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusSetupX;