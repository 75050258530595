import React, {useState} from "react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as patientCallLogAction from "../../redux/actions/patientCallLog";
import {Label, Modal, Table} from "semantic-ui-react";
import SavePatientCallLog from "./savePatientCallLog";
import moment from "moment";
import { capitalizeFirstLetter, isBlankNullOrUndefined } from "../../utils/common";

interface PatientInsightProps {
    patientId: number;
    practice: string;
    refInPatId?: string;
    speciality?: string;
    updateUserInfo?: Function;
    dropDownStatus?: any;
    dataKey?: number;
    PatNum_Date?: string;
    statusId?:number;
    practiceOptionsEdit:any
    refInPractice: string
}

const PatientCallLog = (props: PatientInsightProps) => {
    let {patientId, practice, dropDownStatus, refInPatId, speciality} = props;
    refInPatId = refInPatId !== undefined ? refInPatId : '';
    if (patientId) patientId = +patientId;
    const actions = useActions(patientCallLogAction);
    const initialState: any = {
        patientCallLog: [],
        showHistory: false
    };
    const [state, setState] = useState(initialState);

    const disabled = isBlankNullOrUndefined(patientId) || isBlankNullOrUndefined(practice);

    const getHistory = (showHistory: boolean) => {
        if (disabled) return;
        actions.get({patientId, practice, speciality}).then((patientCallLog: any[]) => setState({
            ...state,
            patientCallLog,
            showHistory
        }));
    }

    const onClose = () => {
        setState({...state, showHistory: false});
    }
    return (<div>
        <small className={`linkText ${disabled && 'disabled'}`} onClick={() => getHistory(true)}>Show Logs</small>
        {
            state.showHistory &&
            <Modal open={true} onClose={onClose} closeIcon={true} size='large'>
                <Modal.Header>Refer Out Patient: {props.patientId}  Refer In Patient: {props.refInPatId}  Speciality: {capitalizeFirstLetter(props.speciality)}</Modal.Header>
                <Modal.Content>
                    <h3>
                        <span className='mr20'>Call Logs</span>
                        <SavePatientCallLog patientCallLog={{ patientId, practice }} refInPatId={refInPatId} 
                        onSaved={() => getHistory(false)} updateUserInfo={props.updateUserInfo} 
                        dropDownStatus={dropDownStatus} speciality={props.speciality} dataKey={props.dataKey} 
                        statusId={props.statusId} PatNum_Date={props.PatNum_Date} 
                        practiceOptionsEdit={props.practiceOptionsEdit} refInPractice={props.refInPractice}/>
                    </h3>
                    <Table size='small' striped={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Username</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Call Type</Table.HeaderCell>
                                <Table.HeaderCell>Call Result</Table.HeaderCell>
                                <Table.HeaderCell>Follow-Up Date</Table.HeaderCell>
                                <Table.HeaderCell>Preferred Times</Table.HeaderCell>
                                <Table.HeaderCell>Specialty</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>
                                <Table.HeaderCell/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                state.patientCallLog && state.patientCallLog.length > 0
                                    ? state.patientCallLog.map((log: any, key: number) =>
                                     
                                        <Table.Row key={`history_${key}`}>
                                            <Table.Cell>{log.username}</Table.Cell>
                                            <Table.Cell>{moment(log.createdAt).format('YYYY/MM/DD')}</Table.Cell>
                                            <Table.Cell>
                                                {log.leadStatus}
                                                {
                                                    log.leadStatus === 'Lost' &&
                                                    <i className='mt10'><br/>Reason: <b>{log.lostReason}</b></i>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>{log.callType}</Table.Cell>
                                            <Table.Cell>{log.callResult}</Table.Cell>
                                            <Table.Cell>{moment(log.followupDate).format('YYYY MMM D, HH:mm')}</Table.Cell>
                                            <Table.Cell>
                                                {
                                                    log.preferredTimes && log.preferredTimes.split(',').map((num: string, key: any) =>
                                                        <Label color='blue' basic={true} key={`patNum_${key}`}>{num}</Label>)
                                                }
                                            </Table.Cell>
                                            <Table.Cell>{log.speciality}</Table.Cell>
                                            <Table.Cell>{log.notes}</Table.Cell>
                                            <Table.Cell>
                                                <SavePatientCallLog
                                                    patientCallLog={log}
                                                    onSaved={() => getHistory(true)}
                                                    updateUserInfo={props.updateUserInfo}
                                                    dropDownStatus={dropDownStatus}
                                                    speciality={props.speciality}
                                                    dataKey={props.dataKey}
                                                    PatNum_Date={props.PatNum_Date}
                                                    refInPatId={refInPatId}
                                                    statusId={props.statusId}
                                                    practiceOptionsEdit={props.practiceOptionsEdit}
                                                    refInPractice={props.refInPractice}
                                                />
                                            </Table.Cell>
                                        </Table.Row>)
                                    : <Table.Row>
                                        <Table.Cell colSpan={8} className='textCenter'>
                                            <small><i>No Data Found</i></small>
                                        </Table.Cell>
                                    </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
        }
    </div>);
};

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(PatientCallLog);
