import React, { useEffect, useState } from "react";
import {DynamicTable} from "../model/dynamicTable";
import {Icon, Modal} from 'semantic-ui-react';
import { isBlankNullOrUndefined } from "../utils/common";
export default function DynamicHtmlTable(props: DynamicTable) {
  const initialState: any = {
    TableHead: [],
    TableData: []
  };
  let [states, setState] = useState(initialState);
  useEffect(() => {
    const getTableHeadings = () => {
      let returnArray:any = [];
      if(props.tableHead.length) {
        props.tableHead.forEach((item, key) => {
          let index = -1;
          const title:any = item.title;
          if (props.excludeSorting) {
            index = props.excludeSorting.findIndex((head: string) => head.toLowerCase() === title.toLowerCase())
          }
          const icon = props.onHeadClick ? (props.sortedColumn === (item.key||key) ? <Icon name={props.direction}/> : <Icon name={'sort'}/>) : null;
          returnArray.push(
            <th
              className={item.className}
              id={item.id}
              style={item.style}
              key={item.key||key}
              onClick={props.onHeadClick ? props.onHeadClick(item.key||key) : void(0)}
            >
              {index === -1 && item.title!=="Edit/Delete" && <div>{item.title}  {icon} </div>}
              
              {index > -1 && <div>{item.title} </div>}
            </th>)
        })
      }
      return (<thead><tr>{returnArray}</tr></thead>)
    };
  
    const getTableBody = () => {
      let tableRows:any = [];
      let modalRSOpen:Function;
      if(props.modalRSOpen !== undefined){
        modalRSOpen = props.modalRSOpen;
      }
      
      if(props.tableData.length) {
        props.tableData.forEach((itemArray, key) => {
          if(itemArray.length) {
            let tableColumns:any = [];
            itemArray.forEach((item, key2) => {
                tableColumns.push(<td className={item.className} id={item.id}  style={item.style} key={item.key||key2}> {item.title} </td>)
            });
            tableRows.push(<tr key={key}>{tableColumns}</tr>)
          }
        })
      }
      return (<tbody>{tableRows}</tbody>)
    };
    
    const TableData = getTableBody();
    const TableHead = getTableHeadings();
    setState({ TableData, TableHead })
  }, [props.tableData])

  return (<div className="ui card">
			<div className="content">
                <h5>{props.heading}</h5>
					<div className="table-responsive FreezeTable">
							<table id={`${props.id && props.id}`} style={props.style} className={`${ props.className !== '' ? `ui celled single line table unstackable tableStyle ${props.className}` : "table table-striped table-hover" }`}>
								{states.TableHead}
								{states.TableData}
							</table>
					</div>
			</div>
	</div>)

}
