import React from "react";
import {ProcedureCodeProps} from "../model/procedureCodeList";
import * as Yup from 'yup';
import DataForm, { DataFormFieldType} from './formInputs'

const AddProcedureCodeList:React.FC<any> = (props:ProcedureCodeProps) => {
    const {onCancel, initialValue, saveProcedureCodeDetails} = props;

    return (
        <DataForm
            initialValues={initialValue}
            width={8}
            validationSchema={Yup.object().shape({
                procedureCode: Yup.string().required('Procedure code is required'),
                description: Yup.string().required('Procedure code description is required')
            })}
            onSubmit={async (values, {setSubmitting}) => {
                await saveProcedureCodeDetails(values);
                setSubmitting(false);
                onCancel();
            }}
            onCancel={() => {
                onCancel();
            }}
            fieldGroups={[
                {
                    fields: [{label: 'Procedure Code', name: 'procedureCode', type: DataFormFieldType.Text},
                        {label: 'Description', name: 'description', type: DataFormFieldType.Text}]
                }
            ]}
        />
    );
};
export default AddProcedureCodeList;