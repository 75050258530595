import { Action, ActionType } from "../../model/actionTypes";
import { UserSetup } from "../../model/userSetup";
import createReducer from "./createReducer";

const initialState: UserSetup = {
    id: undefined,
    userId: undefined,
    field: undefined,
    value: undefined,
};

export const userSetup = createReducer(initialState, {
    [ActionType.DEFAULT_PRACTICES](state: UserSetup, action: Action<UserSetup>) {
        return Object.assign({...state}, action.payload);
    }
})