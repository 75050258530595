import React, { useEffect, useState } from "react";
import {Button, Modal, Dropdown, Grid, Form} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import { PracticeList } from "../model/practice";
import { SetupAppName } from "../enums/userSetup";
import { SetupFields } from "../enums/userSetpField";
import { useActions } from "../redux/actions";
import * as Actions from "../redux/actions/userSetup";
import * as practiceActions from "../redux/actions/practice";
import { findPractice } from "../utils/common";

const initialState: any = {
  userSetup: {} as any,
  practices: [],
  practiceOpts: []
}

function DefaultPracticeModal(props: any) {
  const userSettingsActions = useActions(Actions);
  const [state, setStates] = useState(initialState);
  const practiceAction = useActions(practiceActions);
  useEffect(() => {
      setData();
  }, [])

  const setData = async () => {
      const params = { field: 'practice', appId: 'cdp_my' };
      const result = await Promise.all([practiceAction.fetchAllPractices({ type: 'all' }), userSettingsActions.getSetup(params)])

      const options = result[0] ? result[0].map((r: any) => {
          return { text: r.practice, value: r.practice, key: r.id }
      }) : [];

      const userSettings = result[1] ? result[1] : {value:''};
      let defaultPractices: any = [];
      if(userSettings.value !== 'all' || userSettings.value !== ''){
        const practiceOptions = userSettings.value.split(',');
            practiceOptions.forEach((practiceOption:any)=>{
              if(practiceOption !== 'all'){
                const practice = findPractice(result[0], practiceOption) || {practice: 'all'};
                if(practice.practice !== 'all'){
                  defaultPractices.push(practice.practice)
                }
              }
            })
      }
      

      setStates({ practices: defaultPractices, practiceOpts: options, userSetup: userSettings })
  }

  const saveUserSetup = () => {
      let setup: any = {
          appId: 'cdp_my',
          field: 'practice',
          value: state.practices.join(',')
      };

      if (state.userSetup) 
          setup['id'] = state.userSetup.id;

      userSettingsActions.saveSetup(setup).then(() => {
          props.onSaveSetup()
          setStates({...state, practices: []});
      });
  }

  const onSelectChange = (data: any) => {
      setStates({ ...state, practices: data.value });
  }

  return (
      <>
          <Modal className={'modalCenter'} onClose={() => props.handleClose()} open={true}>
              <Modal.Header content='Default Practice' />
              <Modal.Content>
                  <Form className="formStyle mt10">
                      <Grid>
                          <Grid.Row>
                              <Grid.Column>
                                  <label className="mr10">Select Default Practice</label>
                                  <Dropdown
                                      search={true}
                                      className='mr10'
                                      fluid={true}
                                      placeholder='Select Practice'
                                      selection={true}
                                      multiple={true}
                                      options={state.practiceOpts ? state.practiceOpts : []}
                                      onChange={(_event, data) => onSelectChange(data)}
                                      value={state.practices}
                                  />
                              </Grid.Column>
                          </Grid.Row>
                      </Grid>
                  </Form>
              </Modal.Content>
              <Modal.Actions>
                  <Button
                      content="OK"
                      primary={true}
                      disabled={!state.practices.toString()}
                      onClick={() => {
                          saveUserSetup()
                      }}
                  />
              </Modal.Actions>
          </Modal>

      </>
  );
}

function mapStateToProps(state: RootState) {
  return {
      practice: state.practice,
      patients: state.patients,
      userSetUp: state.userSetup
  };
}

export default connect(mapStateToProps)(DefaultPracticeModal);
