import React, { useEffect, useState} from "react";
import Template from "./Template";
import * as reportActions from "../redux/actions/report";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import DynamicHtmlTable from "./DynamicHtmlTable";
import {UsageData} from "../model/report";
import _ from "lodash";
import {allowDeleteUserId, HELP_PAGE} from '../constants' ;
import {Button, Checkbox, Input} from "semantic-ui-react";
import {User} from "../model/user";
import HelpMark from "./Help/HelpMark";

interface Props {
  auth: User,
}

function UsageReport(props: Props) {
  const reportAction = useActions(reportActions);
  const initialState: UsageData = {
    direction: 'sort',
    TableData: [[{title: 'No Records Found'}]],
    TableHead: [{title: ''}],
    actualData: [],
    showHidden : false,
    column: -1,
    searchKeyword: ''
  };
  let timeout: any = 0;
  const [state, setStates] = useState(initialState);
  const user = props.auth.user;
  const allowDelete = (user && allowDeleteUserId.indexOf(user.id) >= 0)

  useEffect(() => {
    getUsageData();
  }, []);


  const getUsageData = () => {
    reportAction.fetchUsage()
      .then((res: any) => {
        const actualData = res;
        const TableData = pushTableData(res, state.showHidden);
        const TableHead = TableHeadData();
        setStates({...state, TableHead, TableData, actualData});
      });
  }
  const TableHeadData = () => {
    const TableHead = []
    TableHead[0] = {title: 'Hide'};
    TableHead[1] = {title: 'NAME'};
    TableHead[2] = {title: 'PRACTICE'};
    TableHead[3] = {title: 'LAST LOGIN DATE'};
    TableHead[4] = {title: 'TOTAL ACCESS'};
    TableHead[5] = {title: 'MOBILE COUNT'};
    TableHead[6] = {title: 'DESKTOP COUNT'};
    TableHead[7] = {title: 'RECENT MOBILE OS'};
    return TableHead;
  };

  const handleCheckBox = (hideUsage: any, userId: number) => {
    reportAction.hideUsage(userId, +hideUsage)
      .then((res:any) => {
        getUsageData();
      });
  };


  const onSearchChange = (e: any) => {
    const searchKeyword = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const data = state.actualData && state.actualData.filter((element: any) => {
        return (element.firstName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
          || element.lastName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
        )
      });
      const TableData = pushTableData(data, state.showHidden);
      setStates({...state, searchKeyword: searchKeyword, TableData: TableData});
      clearTimeout(timeout);
    }, 500);
  };


  const pushTableData = (res: any, showHidden: any = false) => {
    const TableData = new Array();
    res && res.map((item: any) => {
      if(item.hideUsage && !showHidden) {
        return true;
      }
      TableData.push([
        {
          title: <Checkbox
            onChange={(e, {checked}) => handleCheckBox(checked, item.userId)}
            checked={item.hideUsage}
          />
        },
        {title: item.firstName + ' ' + item.lastName},
        {title: item.practice? item.practice : 'all' },
        {title: item.Date},
        {title: item.total},
        {title: item.Cell},
        {title: item.Desktop},
        {title: item.deviceOS},
      ])
    })
    return TableData;
  };

  const showHiddenReports = (showHidden: any) => {
    const TableData = pushTableData(state.actualData, showHidden);
    setStates({...state, TableData, showHidden: showHidden});
  }

  const flushData = () => {
    if(window.confirm("Are you sure you want to clear all usage data? Action will not undo.")) {
      const {id} = user;
      reportAction.flushUsageData(id)
        .then((res: any) => {
          getUsageData();
        });
    }
  };

  const handleSort = (clickedColumn: number) => () => {
    let {column, TableData, direction} = state;
    if (column !== clickedColumn) {
      column = clickedColumn;
      TableData = _.sortBy(TableData, o => o[clickedColumn].title);
      direction = 'sort ascending';
      setStates({...state, TableData: TableData.slice(), column, direction});
      return;
    }
    TableData = TableData.reverse();
    direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
    setStates({...state, TableData: TableData.slice(), direction});
  };

  return (
    <Template activeLink='usage-report'>
       <div className="calendar-container">
          <div className="row">
            <div className="col-12">
              <h2 className="float-left">Referrals Usage Report<HelpMark pageId={HELP_PAGE.REFERRALS_USAGE}/></h2>
              <div className="topFilters">
            {
            allowDelete &&
            <Checkbox
                label={'Show Hidden Reports'}
                className='mt10 mr10'
                onChange={(e, {checked}) => showHiddenReports(checked)}
                checked={state.showHidden}
            />
              }
            {
            allowDelete && 
            <Button primary={true} onClick={flushData}>Flush Data</Button>}
            <Input className='mb10' icon='search' placeholder="Search" onChange={onSearchChange}/>
          </div>
            </div>
          </div>
          <div className="ui card">
            <div className="content">
            <DynamicHtmlTable
        key={0}
        className={'adjustment'}
        tableHead={state.TableHead}
        heading={""}
        tableData={state.TableData}
        onHeadClick={handleSort}
        sortedColumn={state.column}
        direction={state.direction}
      />
            </div>
          </div>
        </div>
    </Template>
  );

}

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(UsageReport);

