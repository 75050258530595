import React, {useState} from "react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as patientInsightAction from "../../redux/actions/patientInsight";
import {Modal, Table} from "semantic-ui-react";
import SavePatientInsight from "./savePatientInsight";
import moment from "moment";

interface PatientInsightProps {
    patientId: number;
    practice: string;
}

const PatientInsights = (props: PatientInsightProps) => {
    const {patientId, practice} = props;
    const actions = useActions(patientInsightAction);
    const initialState: any = {
        patientInsight: [],
        showHistory: false
    };
    const [state, setState] = useState(initialState);

    const disabled = !props.patientId || !props.practice;

    const getHistory = (showHistory: boolean) => {
        if (disabled) return;
        actions.get({patientId, practice}).then((patientInsight: any[]) => setState({
            ...state,
            patientInsight,
            showHistory
        }));
    }

    const onClose = () => {
        setState({...state, showHistory: false});
    }
    return (<div>
        <small className={`linkText ${disabled && 'disabled'}`} onClick={() => getHistory(true)}>Show History</small>
        {
            state.showHistory &&
            <Modal open={true} onClose={onClose} closeIcon={true}>
                <Modal.Header>Patient: {props.patientId}</Modal.Header>
                <Modal.Content>
                    <h3>
                        <span className='mr20'>Patient Insights</span>
                        <SavePatientInsight patientInsight={{patientId, practice}} onSaved={() => getHistory(true)}/>
                    </h3>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Time Stamp</Table.HeaderCell>
                                <Table.HeaderCell>Username</Table.HeaderCell>
                                <Table.HeaderCell>Practice</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                state.patientInsight && state.patientInsight.length > 0
                                    ? state.patientInsight.map((insight: any, key: number) =>
                                        <Table.Row key={`history_${key}`}>
                                            <Table.Cell>{moment(insight.timestamp).format('YYYY MMM D, HH:mm')}</Table.Cell>
                                            <Table.Cell>{insight.username}</Table.Cell>
                                            <Table.Cell>{insight.practice}</Table.Cell>
                                            <Table.Cell>{insight.notes}</Table.Cell>
                                            <Table.Cell>
                                                <SavePatientInsight
                                                    patientInsight={insight}
                                                    onSaved={() => getHistory(true)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>)
                                    : <Table.Row>
                                        <Table.Cell colSpan={5} className='textCenter'>
                                            <small><i>No Data Found</i></small>
                                        </Table.Cell>
                                    </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
        }
    </div>);
};

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(PatientInsights);
