import React, { useState, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const CountUpTimer = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []); // Empty dependency array ensures that the effect runs only once on component mount

  const getFormattedTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let formattedTime = `${minutes}m ${remainingSeconds}s`;
    if (minutes === 0) {
      formattedTime = `${remainingSeconds}s`;
    }
    return formattedTime;
  };

  return (
    <Dimmer active>
      <Loader size="massive">{getFormattedTime()}</Loader>
    </Dimmer>
  );
};

export default CountUpTimer;