import React, {useState} from "react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as action from "../../redux/actions/patientCallLog";
import {Button, Dropdown, Form, Icon, Input, Label, Modal, TextArea} from "semantic-ui-react";
import {CALL_RESULT, CALL_TYPE, LEAD_LOST_REASON, LEAD_STATUS, PREFERRED_TIMES} from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

interface SavePatientCallLogProps {
    patientCallLog: any;
    onSaved: Function;
    updateUserInfo?: Function;
    dataKey?: number;
    PatNum_Date?: string;
    refInPatId?: string;
    dropDownStatus: any;
    speciality?: string;
    statusId?: number;
    practiceOptionsEdit: [];
    refInPractice: string
}

const SavePatientCallLog = (props: SavePatientCallLogProps) => {
    const actions = useActions(action);
    const initialState: any = {
        showModal: false,
        patientCallLog: props.patientCallLog ? {
            ...props.patientCallLog,
            followupDate: props.patientCallLog.followupDate
                ? moment(props.patientCallLog.followupDate).toDate()
                : null,
            preferredTimes: props.patientCallLog.preferredTimes
                ? props.patientCallLog.preferredTimes.split(',')
                : null
        } : {} as any,
        disabled: true,
        speciality: props.speciality,
        stateLog: {refInPatId: props.refInPatId,
            statusId: props.statusId, refInPractice: props.refInPractice}
    };
    const [state, setState] = useState(initialState);

    const isEdit = props.patientCallLog && props.patientCallLog.id ? true : false;

    const onOpen = (showModal: boolean) => {
        const disabled = !validate();
        setState({...state, disabled, showModal});
    };

    const onSave = async () => {
        let {patientCallLog, stateLog} = state;
        patientCallLog.preferredTimes = patientCallLog.preferredTimes.join(',');
        patientCallLog.PatNum_Date = props.PatNum_Date
        if(props.updateUserInfo!== undefined) {
            await props.updateUserInfo(patientCallLog, props.dataKey, props.PatNum_Date, stateLog);
        }
        if (isEdit) {
            patientCallLog.speciality = props.speciality;
            await actions.update(patientCallLog);
            
        }         
        else{
            patientCallLog.speciality = props.speciality;
            await actions.add(patientCallLog);
        }
        
        onOpen(false);
        props.onSaved()
    }

    const validate = () =>
        ['notes', 'leadStatus', 'callType', 'callResult', 'followupDate'].every(field => !!state.patientCallLog[field]);

    const onFieldUpdate = async (name: string, value: any) => {
        const {patientCallLog} = state;
        patientCallLog[name] = value;
        const disabled = !validate();
        setState({...state, disabled, patientCallLog});
    }

    const onStateUpdate = async (name: string, value: any) => {
        const {stateLog} = state;
        const disabled = !validate();
        stateLog[name] = value;
        setState({...state, disabled, stateLog});
    }

    return (<div className='d-inline-block'>
        <Button onClick={() => onOpen(true)} secondary={isEdit} primary={!isEdit} size={isEdit ? 'small' : undefined}>
            <Icon className='text-white' name='edit'/>&nbsp;
            {isEdit ? '' : `Add`}
        </Button>
        {
            state.showModal &&
            <Modal open={state.showModal} onClose={() => onOpen(false)} closeIcon={true}>
                <Modal.Header>Patient: {props.patientCallLog.patientId}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Call Status <span className='required'>*</span></label>
                                <Dropdown
                                    clearable={true}
                                    options={LEAD_STATUS.map(value => ({text: value, value}))}
                                    selection={true}
                                    value={state.patientCallLog.leadStatus}
                                    onChange={(e, data) => onFieldUpdate('leadStatus', data.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                            <label>Referral Status</label>
                                <Dropdown
                                    options={props.dropDownStatus}
                                    name="statusId"
                                    placeholder="Status"
                                    selection
                                    onChange={(e, data) => onStateUpdate('statusId', data.value)}
                                />
                            </Form.Field>
                            {
                                state.patientCallLog.leadStatus === 'Lost' &&
                                <Form.Field>
                                    <label>Lost Reason <span className='required'>*</span></label>
                                    <Dropdown
                                        clearable={true}
                                        options={LEAD_LOST_REASON.map(value => ({text: value, value}))}
                                        selection={true}
                                        value={state.patientCallLog.lostReason}
                                        onChange={(e, data) => onFieldUpdate('lostReason', data.value)}
                                    />
                                </Form.Field>
                            }
                            <Form.Field>
                                <label>Call Type <span className='required'>*</span></label>
                                <Dropdown
                                    clearable={true}
                                    options={CALL_TYPE.map(value => ({text: value, value}))}
                                    selection={true}
                                    value={state.patientCallLog.callType}
                                    onChange={(e, data) => onFieldUpdate('callType', data.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Follow Up Date <span className='required'>*</span></label>
                                <DatePicker
                                    selected={state.patientCallLog.followupDate}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(e) => onFieldUpdate('followupDate', e)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Preferred Times <span className='required'>*</span></label>
                                <Dropdown
                                    clearable={true}
                                    options={PREFERRED_TIMES.map(value => ({text: value, value}))}
                                    selection={true}
                                    multiple={true}
                                    value={state.patientCallLog.preferredTimes || []}
                                    onChange={(e, data) => onFieldUpdate('preferredTimes', data.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Call Result <span className='required'>*</span></label>
                                <Dropdown
                                    clearable={true}
                                    options={CALL_RESULT.map(value => ({text: value, value}))}
                                    selection={true}
                                    value={state.patientCallLog.callResult}
                                    onChange={(e, data) => onFieldUpdate('callResult', data.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Notes <span className='required'>*</span></label>
                                <TextArea
                                    value={state.patientCallLog.notes}
                                    onChange={(e, data) => onFieldUpdate('notes', data.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label> Refer In Patient Number</label>
                            <Input
                                value={state.stateLog.refInPatId}
                                className='mb10'
                                onChange={(e, data) => onStateUpdate('refInPatId', data.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Refer In Practice</label>
                            <Dropdown
                            clearable={true}
                            options={props.practiceOptionsEdit}
                            value={props.refInPractice}
                            search={true}
                            selection={true}
                            onChange={(e, data) => onStateUpdate('refInPractice', data.value)}
                        />
                        </Form.Field>
                        <Form.Field>
                            <Button
                                disabled={state.disabled}
                                className='float-right mb10'
                                primary={true}
                                onClick={() => onSave()}
                            >
                                Save Call Log
                            </Button>
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </Modal>
        }
    </div>);
};

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(SavePatientCallLog);
