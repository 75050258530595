import { Billing } from "../../model/billing";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";


const initialState: Billing = {
  isLoad: false,
  isLoadFamily: false,
  loadErr: null,
  loadErrFamily: null,
  loading: false,
  billingData: null,
  reportDetailsData: null,
  reportData: null,
  billingPractices: null,
  billingParams: '',
  intervalReqCount: 0,
  todayProduction: null,
  familyData: { brokenListChild: [], aptResult: null, treatmentList: null, commlog: null, treatmentPlan: {}, lastExamResult: {} },
  initialFamilyData: { brokenListChild: [], aptResult: null, treatmentList: null, commlog: null, treatmentPlan: {}, lastExamResult: {} },
  nextDaysData: null,
  medical: { allergy: [], medication: [], service: [], medical: [], MedUrgNote: [], vitals: [] },
};

export const billing = createReducer<Billing>(initialState, {
  [ActionType.LOAD](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = true, state.loadErr = null, state.loadErrFamily = null)
  },
  [ActionType.CLOSE_ERROR_MESSAGE](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.loadErr = null, state.loadErrFamily = null)
  },
  [ActionType.LOAD_SUCCESS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.loadErr = null, state.billingData = action.payload, state.reportData = null, state.intervalReqCount = 0)
  },
  [ActionType.LOAD_FAMILY](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoadFamily = true, state.loadErr = null, state.familyData = action.payload)
  },
  [ActionType.LOAD_BILLING_FAMILY_SUCCESS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoadFamily = false, state.loadErrFamily = null, state.familyData.brokenListChild = action.payload)
  },
  [ActionType.LOAD_BILLING_FAMILY_SUCCES](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoadFamily = false, state.loadErrFamily = null, state.familyData.brokenListChild = action.payload)
  },
  
  [ActionType.LOAD_TREATMENT_LIST](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.familyData.treatmentList = action.payload)
  },

  [ActionType.LOAD_TREATMENT_PLAN](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.familyData.treatmentPlan = action.payload)
  },

  [ActionType.LOAD_COMMLOG](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.familyData.commlog = action.payload)
  },

  [ActionType.LOAD_APT_RESULT](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.familyData.aptResult = action.payload)
  },

  [ActionType.LOAD_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.loadErr = action.payload, state.billingData = null, state.reportData = {})
  },

  [ActionType.LOAD_REPORT_SUCCESS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.loadErr = null, state.reportData = action.payload)
  },

  [ActionType.LOAD_REPORT_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.loadErr = action.payload, state.reportData = null)
  },

  [ActionType.LOAD_REPORT_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.reportDetailsData = action.payload, state.loadErr = null)
  },

  [ActionType.LOAD_REPORT_DETAILS_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.loadErr = action.payload, state.reportDetailsData = null)
  },

  [ActionType.LOAD_BILLING_PRACTICES_SUCCESS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.billingPractices = action.payload, state.loadErr = null)
  },

  [ActionType.LOAD_REPORT_DETAILS_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoadFamily = false, state.loadErrFamily = null, state.familyData = action.payload)
  },

  [ActionType.LOAD_BILLING_FAMILY_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoadFamily = false, state.loadErrFamily = null, state.familyData = { brokenListChild: [], aptResult: [], treatmentList: [], commlog: [], treatmentPlan: {}, lastExamResult: {} })
  },


  [ActionType.LOAD_BILLING_PRACTICES_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.loadErr = action.payload, state.billingPractices = null)
  },

  [ActionType.SAVE_PARAMS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.billingParams = action.payload)
  },

  [ActionType.CHANGE_INTERVAL_REQUEST_COUNT](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.intervalReqCount = action.payload)
  },

  [ActionType.SAVE_TODAY_PRODUCTION](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.todayProduction = action.payload)
  },

  [ActionType.LOAD_NEXT_DAYS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.nextDaysData = action.payload)
  },

  [ActionType.FAIL_NEXT_DAYS](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.isLoad = false, state.nextDaysData = null)
  },

  [ActionType.LOAD_MEDICAL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.medical = action.payload)
  },

  [ActionType.LOAD_MEDICAL_FAIL](state: Billing, action: Action<Billing>) {
    return Object.assign({ ...state }, state.medical = { allergy: [], medication: [], service: [], medical: [], MedUrgNote: [] })
  }
});