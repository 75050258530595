import { applyMiddleware, createStore } from "redux";
import {PersistConfig, persistReducer, persistStore as _persistStore} from "redux-persist";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import * as localF from "localforage";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { ActionType } from "../model/actionTypes";

import { Api } from "../api";
import { API_URL } from "../constants";


const actionTypeEnumToString = (action: any): any =>
	action &&
	(action.type !== null && action.type !== undefined) &&
	typeof action.type === "number" &&
	ActionType[action.type] ? (
		{
			type: ActionType[action.type],
			payload: action.payload
		}
	) : action;

const persistConfig: PersistConfig<any> = {
	key: "root",
	version: 1,
	storage: localF,
	whitelist: ["auth"]
};

const logger = createLogger({ actionTransformer: actionTypeEnumToString });
const composeEnhancers = composeWithDevTools({ actionSanitizer: actionTypeEnumToString });

const history = createBrowserHistory();
const isDevelopment = process.env.NODE_ENV === "development";
const initialState = {};
const api = new Api(API_URL);

const middleware = [
	thunk.withExtraArgument(api)
];

if (isDevelopment) {
	middleware.push(logger);
}

const enhancedDevMW = isDevelopment ? composeEnhancers(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

const persistedReducer = persistReducer(persistConfig, rootReducer());

export default () => {
	const store = createStore(persistedReducer, initialState, enhancedDevMW);
	const persistStore = _persistStore(store);
	return { store, persistStore };
};

export { history };
