import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
import {MDMHCONFIRMATION} from "../../model";


const initialState:any = {
  data: [],
};

export const mdhmConfirmation = createReducer(initialState, {
  [ActionType.MDHM_CONFIRMATION](state: MDMHCONFIRMATION, action: Action<MDMHCONFIRMATION>) {
    return action.payload;
  },
});