import createReducer from "./createReducer";
import { Action, ActionType } from "../../model/actionTypes";
import {Region} from "../../model/region";

const initialState:Region = {
    regionsList: [],
}
export const region = createReducer(initialState, {
    [ActionType.FETCH_ALL_REGIONS](state: Region, action: Action<Region>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.FETCH_FILTERED_REGIONS](state: Region, action: Action<Region>) {
        return Object.assign({...state}, action.payload);
    },
})