import React, {useEffect, useState} from 'react';
import ReactDOMServer from "react-dom/server";
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import '../../styles/css/xray-viewer.css';
import * as XrayActions from '../../redux/actions/aws-bucket';
import {useActions} from "../../redux/actions";

interface XrayViewerProps {
    patientNumber: any
    practice: any
}

const XrayViewer = (props: XrayViewerProps) => {
    const medataDataId = 'xray_metadata_viewer';
    const initialState: any = {
        visible: false,
        selected: null,
        images: null
    };
    const xrayAction = useActions(XrayActions);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        load()
    }, []);

    const load = () => {
        let patientNumber = `${props.patientNumber}`;
        while(patientNumber.length < 5)
            patientNumber = '0' + patientNumber;
        xrayAction.loadList(props.practice, patientNumber).then((res : any) => {
            setState({ ...state, images: res.reverse() })
        })
    }

    const getMetaData = (metadata: any) => {
        const list = Object.keys(metadata)
            .filter(m => !(m === 'full_name' || m === 'type'))
            .sort()
            .map((key) => {
                const name = key.replace('_', ' ').toLocaleUpperCase();
                return (
                    <span key={key} className="col p-1">
                        <small>{name}</small>:<br/> <b style={{marginLeft: '.2em'}}>{metadata[key]}</b>
                    </span>
                )
            });
        return <p id={medataDataId} className="row xray-metadata-viewer">{list}</p>
    }

    const onViewXrays = () => {
        if (state.images.length === 0) return
        setState({...state, visible: true, selected: state.images[0]});
        updateMetadata(state.images[0]);
    }

    const removeMetaData = () => {
        let element = document.getElementById(medataDataId);
        if (element !== null && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    }

    const updateMetadata = (selected: any) => {
        setTimeout(() => {
            if (!selected || !selected.metadata) return;
            const metadata = getMetaData(selected.metadata);
            const html = ReactDOMServer.renderToStaticMarkup(metadata);
            document.body.insertAdjacentHTML('beforeend', html);
        }, 200);
    }

    const getText = () => {
        if (!state.images) return <p className="mt-2 text-muted"><small>...loading xrays</small></p>;
        if (state.images.length < 1) return <p className="mt-2 text-muted">No Xrays Found</p>;
        return <p className="primaryColor cursorPointer mt-2" onClick={() => onViewXrays()}>View Xrays</p>
    }

    const closeViewer = () => {
        setState({...state, visible: false, selected: null});
        removeMetaData();
    }

    const imageViewerChange = (selected: any, i: any) => {
        setState({...state, selected});
        updateMetadata(selected);
    }

    const text = getText();

    return (
        <div>
            {text}
            <Viewer
                images={state.images}
                activeIndex={0}
                defaultScale={1.8}
                onChange={imageViewerChange}
                onClose={closeViewer}
                visible={state.visible}
            />
        </div>
    );

}

export default XrayViewer;
