import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Image, Icon, List, Popup, Label} from 'semantic-ui-react';
import Logo from "../styles/images/logoSmall.png";
import {Account} from "../model/user";
import FeedbackModal from "./FeedbackModal";
import DefaultPracticeModal from './DefaultPracticeModal';
import {useActions} from "../redux/actions";
import * as appActions from "../redux/actions/app";
import * as patientActions from "../redux/actions/patients";
import * as pmdActions from "../redux/actions/pmd";
import moment from "moment";
import * as helpMarkActions from "../redux/actions/helpMark";
import {HelpPages} from "../constants";
import HelpPageModal from "./Help/HelpPageModal";
import { useDefaultPracticeContext } from '../PMDPracticeContext';
import * as partnersActions from "../redux/actions/partners";

interface Props {
  user: Account,
  logout: any,
  listenerFromParent: any
}

export default function Header(props: Props) {  
  const initialState: any = {
    showFeedbackForm: false,
    formData : {
      subject: '',
      message : '',
    },
    showDefaultPage: false,
    practices: [],
    helpPageModal : false,
    helpPageId : false,
    isNotificationOpen : false,
    notificationPages : [],
    defaultPractice : '',
    mycdp_uri: 'https://my.cdp.dental/?token'
  };

  const [state, setStates] = useState(initialState);
  const appAction = useActions(appActions);
  const patientAction = useActions(patientActions);
  const pmdAction = useActions(pmdActions);
  const helpMarkAction = useActions(helpMarkActions);
  const { theme, setTheme } = useDefaultPracticeContext();
  const partnersAction = useActions(partnersActions);
  const handleClick = (e:any) => {
    e.preventDefault();
    window.location.href = state.mycdp_uri.replace('?token', `partners?token`);
  };

  useEffect(() => {
    partnersAction.getDentalPartners().then((res:any)=>{
      if(res.partners_uri&& res.partners_uri.mycdp_uri){
        setStates({...state, mycdp_uri: res.partners_uri.mycdp_uri})
      }
    });
  }, []);

  const fetchNotification = () => {
    const {user} = props;
    let userId = user.id
    helpMarkAction.getHelpNotifications(userId)
      .then((res: any) => {
        setStates({...state, notificationPages: res });
      })
      .catch((err: any) => {
        
      });
  }


  const hideHelpPageModal =()=>{
    setStates({...state,helpPageModal: false});
  };

  const  onNotificationClick = (pageId: any, key: any, open: boolean) => {
    const { user } = props;
    let stateObj = {};
    if(open) {
       stateObj = {
        helpPageModal: true,
        helpPageId: pageId
      }
    }
    const notificationPages = state.notificationPages;
    if(notificationPages[key]) {
      notificationPages.splice(key, 1);
      setStates({...state,...stateObj, notificationPages: notificationPages, isNotificationOpen: !open });
      helpMarkAction.updateHelpNotifications({ pageId: pageId, userId: user.id });
    }
  }

  const  onNotificationOpen = (pageId: any) => {
    const stateObj = {
      helpPageModal: true,
      helpPageId: pageId
    }
    setStates({...state,...stateObj, isNotificationOpen: true });
  }

  const getNotificationList = () => {
    let pages = state.notificationPages;
    let returnArray: JSX.Element[] = [];
    if(pages && pages.length) {
      pages.forEach( (item: { pageId: string | number; }, key: string | number | undefined) => {

          returnArray.push(
            <List.Item key={key}>
              <List.Content floated='right'>
                <Icon className="primaryColor" name="close" onClick={() => onNotificationClick(item.pageId, key, false)} />
              </List.Content>
              <List.Content>
                <List.Header onClick={() => onNotificationClick(item.pageId, key, true)}>{HelpPages[item.pageId].name}</List.Header>
              </List.Content>
            </List.Item>
          );

      });

      return returnArray;
    } else {
      return null;
    }
  }


  const hideFeedbackModal = () => {
    setStates({...state, showFeedbackForm: false});
  };
  
  const onChangeDefaultSetup = (e: any, data: any) => {
    const values = data.value;
    let practices;
    if (values.length > 0 && values.indexOf('all') === values.length -1) {
      practices = ['all'];
    } else if (values.length > 1 && values.indexOf('all') === 0) {
      values.splice(0, 1);
      practices = values;
    } else {
      practices = values;
    }
    setStates({...state, practices: practices})
  }

  const onChangeFeedback = (formData: any) => {
    setStates({...state, formData});
  };

  const onSubmitForm = () => {
    const formData = {...state.formData}
    const feedback = {
      subject: formData.subject,
      message : formData.message,
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      practice: formData.practice,
      file: formData.file,
      dateTime: moment().format('MM/DD/YYYY hh:mm A')
    };
    appAction.sendFeedback(feedback);
    hideFeedbackModal();
  };

  const handleOpen = () => {
    setStates({...state, isNotificationOpen: true });
  }

  const handleClose = () => {
    setStates({...state, isNotificationOpen: false });
  }

  const {user, logout,listenerFromParent } = props;
  let image = null;
  let imgSrc = (user && user.profilePicture) ? user.profilePicture : "";
  if (user) {
    image = <span>
            <Image avatar={true} className='mr-1' src={imgSrc}/>
              <span className="userName">
                {user.firstname} {user.lastname}
              </span>
          </span>
  }
  const notificationList = getNotificationList();
  const helpUrl = state.mycdp_uri ? state.mycdp_uri.replace('?token', `admin/setup/manager-help?token`) : '';
  return (
    <div>
        <a onClick={() => setStates({showFeedbackForm: true})} className="btnFeedback">
        <small>FEEDBACK</small>
      </a>
      <Menu secondary={true} className='mb-0 mt-0'>
      <Menu.Item
        className='ml-0 mr-0 mr-lg-4 float-left float-lg-none toggle inverted'
        icon='content'
        onClick={listenerFromParent}
      />
        <a onClick={handleClick} >
      <img
        src={Logo}
        className="logoSmall"
        alt="Logo"
      />
      </a>
      { state.helpPageModal &&
      <HelpPageModal hideHelpPageModal={hideHelpPageModal} pageId={state.helpPageId} />
      }

      <Menu.Menu position='right' className='float-right float-lg-none'>
        { (notificationList && notificationList.length) ? <Popup
          trigger={<span className="notification"><Icon name="bell outline" className="bellIcon cursorPointer" /><Label circular className="bellIconLabel">{notificationList.length}</Label></span>}
          content={<List className="notification-list" selection verticalAlign='middle'>{notificationList}</List>}
          position='bottom center'
          on='click'
          open={state.isNotificationOpen}
          onClose={handleClose}
          onOpen={handleOpen}
        /> : ""}
        <Dropdown className="user" trigger={image} icon={null}/>
        <Dropdown className='setting inverted item mr-0' icon='ellipsis vertical'>
          <Dropdown.Menu>
            <Dropdown.Item text='Set Default' onClick={() => setStates({showDefaultPage: true})}/>
            <Dropdown.Item text='Feedback' onClick={() => setStates({showFeedbackForm: true})}/>
            <Dropdown.Item text={'Manage Help'}><a target="_blank" className={'removeAclr'} href={helpUrl}>Manage Help</a> </Dropdown.Item>
            <Dropdown.Item className="innerDropdown">
              <Dropdown text='Help'>
                <Dropdown.Menu>
                  {Object.keys(HelpPages).map((pageId: any, i: any) => {
                    const text = HelpPages[pageId].name.replace('MY.CDP - ','');
                    return(
                             <Dropdown.Item key={i} text={text} onClick={() => onNotificationOpen(pageId)}/>
                    );
                  })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            <Dropdown.Item text='Logout' onClick={logout}/>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>      
    </Menu>
      {state.showFeedbackForm &&
      <FeedbackModal
        hideFeedbackModal={hideFeedbackModal}
        onChangeFeedback={onChangeFeedback}
        onSubmitForm={onSubmitForm}
      />
      }
      {state.showDefaultPage &&
      <DefaultPracticeModal
      handleClose={() => setStates({ showDefaultPage: false })}
      onSaveSetup={() => setStates({ showDefaultPage: false })}
        onChangeDefaultSetup={onChangeDefaultSetup}
        practices={state.practices}
      />
      }
    </div>
  );
}
