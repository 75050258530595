import React from 'react';
import { Sidebar, Menu, Icon, List, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SubMenu, MainMenu } from '../constants';
import Logo from '../styles/images/logoMain.png';
import { Account } from "../model/user";
import { useActions } from '../redux/actions';
import * as appActions from '../redux/actions/app';
import { menusArray } from '../menus';
import { SIcon, IconPropsName } from './icons/SIcon';
import * as partnersActions from "../redux/actions/partners";

interface Props {
  visible: boolean,
  user: Account,
  savePartnerUsage: Function
}

export default function SideBarMain(props: Props) {
  const partnersAction = useActions(partnersActions);
  const handleClick = (e:any) => {
    e.preventDefault();
    partnersAction.getDentalPartners().then((res:any)=>{
      if(res.partners_uri&& res.partners_uri.mycdp_uri){
        window.location.href =res.partners_uri.mycdp_uri;
      }
    });
  };
  const parseLeftMenuPath = (menu: string) => `/${menu.toLowerCase().split(' ').join('-')}`;
  const appAction = useActions(appActions);
  const { user, visible, savePartnerUsage } = props;
  let basePath = window.location.pathname.split('/')[1];
  basePath = basePath.indexOf('-') > -1
    ? basePath.split('-').join(' ')
    : basePath;
  const basePathIndex = basePath
    ? menusArray.findIndex(i => i.name.toLowerCase() === basePath.toLowerCase())
    : -1;
  const savePartners = (partnerName: string) => {
    savePartnerUsage(partnerName)
  };
  const closeSideBar = () => {
    appAction.SidebarOpen(false)
  }
  let panels: any;

  panels = menusArray.map((menu: MainMenu, i) => {

    // Check if role is assign for menu
    if (menu.role && menu.role.length) {
      let roleExist = false;
      menu.role.forEach((role: string) => {
        if (user.role.indexOf(role.toLowerCase()) >= 0) {
          roleExist = true;
        }
      });
      if (roleExist === false) {
        return { key: `panel-${i}-${menu.name}` };
      }
    }

    const parentLink = `${parseLeftMenuPath(menu.name)}`;


    let subPanels = [];
    if (menu.subMenus !== undefined && menu.subMenus.length > 0)
      subPanels = menu.subMenus.map((subMenu, k) => {
        return (

          {
            key: `panel-${k}-${subMenu.pageId}`,
            title: (<Link
              to={parentLink}
              className={'active'}
            >
              {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}></i> : <Icon name={subMenu.icon} />}
              {subMenu.title}
            </Link>)
          }

        );

      })

    // Child Menu
    if (menu.subMenus && menu.subMenus.length > 0) {
      const angle =  'angle down';
      const childMenus = menu.subMenus.map((subMenu: SubMenu, j: number) => {

        // Dont show in menu bar if menu hide is set
        if (subMenu.hide) {
          return true;
        }

        // Check if role is assign for menu
        if (subMenu.role && subMenu.role.length) {
          let roleExist = false;
          subMenu.role.forEach((role: string) => {
            if (user.role.indexOf(role.toLowerCase()) >= 0) {
              roleExist = true;
            }
          });
          if (roleExist === false) {
            return false;
          }
        }
        let childPath = window.location.pathname.split('/')[2];
        childPath = childPath && childPath.indexOf('-') > -1
          ? childPath.split('-').join(' ')
          : childPath;
        const childLink = `${parentLink}${parseLeftMenuPath(subMenu.name)}`;


        let subSubMenus;
        
        if (subMenu.subMenux && subMenu.subMenux.length > 0) {
          subSubMenus = subMenu.subMenux.map((subSubMenu, k) => {
            if (subSubMenu.role && subSubMenu.role.length) {
              let roleExist = false;
              subSubMenu.role.forEach(role => {
                if (user.role.indexOf(role) >= 0) {
                  roleExist = true;
                }
              });
              if (roleExist === false) {
                return false;
              }

            }
            const subSubChildLink = `${parentLink}${parseLeftMenuPath(subSubMenu.name)}`;
            
            const styles = { color: 'black' }
            return (
              <List.Item
                key={`sub-panel-${j}-${subSubMenu.pageId}`}
                as='div'
                className={(childPath === subSubMenu.name.toLowerCase()) ? 'active' : ''}
              //onClick={(e) => pushToURL(e, dispatch, subSubChildLink)}
              >
                <Link
                  style={styles}
                  to={subSubChildLink}
                  onClick={closeSideBar}
                  className={(childPath === subSubMenu.name.toLowerCase()) ? 'active' : ''}>
                  {subSubMenu.customIcon ?
                    <i aria-hidden="true" className={`${subSubMenu.customIcon} icon`}></i> :
                    <SIcon name={subSubMenu.icon as IconPropsName} style={{ margin: "-6px 12px -6px 0" }} />
                  }
                  {subSubMenu.title}
                </Link>
              </List.Item>
            )
          });

          return {
            key: `panel-${i}-${subMenu.pageId}`,
            title: {
              content: (<span className={(basePath === subMenu.name) ? 'parentActive' : ''}>
                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}></i> : <Icon name={subMenu.icon} />}
                {subMenu.title}
              </span>)
            },
            content: { content: (<List>{subSubMenus}</List>), className: 'p0' }
          };
        }
        const styles = { color: 'black' }

        if (!subMenu.subMenux) {
          return (
            <List key={`listj-${j}`}>
              <List.Item
                as='div'
                className={'active'}
              //onClick={(e) => this.pushToURL(e, dispatch, childLink)}
              >
                <Link
                  style={styles}
                  to={childLink}
                  onClick={closeSideBar}
                  className={(childPath === subMenu.name.toLowerCase()) ? 'active' : ''}>
                </Link>
                {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}></i> : <Icon name={subMenu.icon} />}
                {subMenu.title}
              </List.Item>
            </List>
          );
        } else {

          return (

            {
              key: `subPanel-${i}`,
              title: {
                content: (<span className={(basePath === subMenu.name) ? 'parentActive' : ''}>
                  {subMenu.customIcon ? <i aria-hidden="true" className={`${subMenu.customIcon}  icon`}></i> : <Icon name={subMenu.icon} />}
                  {subMenu.title}
                  <Icon name={angle} />
                </span>)
              },
              content: {
                content: <Accordion.Accordion
                  className="leftMenu"
                  panels={subSubMenus}
                />, key: `accordion-${i}`
              }
            }
          );

        }

      });
      
      return {
        key: `panel-${i}-${menu.name}`,
        title: {
          content: (
            <span className={(basePath === menu.name.toLowerCase()) ? 'parentActive' : ''}>
              {menu.customIcon ?
                <i aria-hidden="true" className={`${menu.customIcon}  icon`} /> :
                <SIcon name={menu.icon as IconPropsName} style={{ margin: "-6px 12px -6px 0" }} />
              }
              {menu.title}
              <Icon name={angle} className="float-right" />
            </span>
          )
        },
        content: {
          content: (
            <Accordion.Accordion
              className="mt0 rounded0"
              panels={childMenus}
            />
          ),
          key: 'sub-accordions',
          className: 'p0'
        }
      };
    }

    // Dont show in menu bar if menu hide is set
    if (menu.hide) {
      // return false;
      return { key: `panel-${i}-${menu.name}` };
    }
    return {
      key: `panel-${i}-${menu.name}`,
      title: {
        content: <Link
          onClick={() => {
            savePartners(menu.partner);
            closeSideBar();
          }}
          to={parentLink}
        >
          {menu.customIcon ?
            <i aria-hidden="true" className={`${menu.customIcon} icon`} /> :
            <SIcon name={menu.icon as IconPropsName} style={{ margin: "-5px 12px -5px 0" }} />
          }
          {menu.title}
        </Link>
      }
    };
  });

  return (

    <Sidebar
      as={Menu}
      animation='push'
      width='thin'
      visible={visible}
      icon='labeled'
      vertical
      inverted
    >
      <div className="logoHolder">
          <a onClick={handleClick}>
          <img src={Logo} className="logo align-self-center" alt="Logo" />
        </a>
      </div>
      <Accordion className="cdp-sidebar styled leftMenu" panels={panels} defaultActiveIndex={basePathIndex} />
    </Sidebar>
  )
}
