import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Collapse, { Panel } from 'rc-collapse';

interface FamilyMembersProps {
  BrokenChild:any
  changeTab:Function
  onChange:any 
  activeKey:any 
  PhoneNumbersString:any 
  phoneTolTip:any 
  phoneTolTipState:any 
  getStatusTextClass:any
  location:any
  selectedPT_Num?:any
  treatmentPlanCalculation?:any
  BrokenChildTable?:any
}

const FamilyMembers: React.FC<FamilyMembersProps> = (props) => {


  

  const sortArray = (BrokenChild:any) => {

    if(BrokenChild.length < 1)
      return BrokenChild;

    for(let i=0; i < BrokenChild.length; i++){
      if(BrokenChild[i].PT_Num === BrokenChild[i].Guarantor){
        BrokenChild.unshift(BrokenChild[i]); // Add element on 0 index
        BrokenChild.splice(i+1,1); // Remove element from array
        break;
      }
    }
    return BrokenChild;
  }


    const { BrokenChild, changeTab, onChange, activeKey, PhoneNumbersString, phoneTolTip, phoneTolTipState, getStatusTextClass } = props;
    let BrokenChildSorted = BrokenChild;
    if(BrokenChild.length > 0 && BrokenChild[0].PT_Num !== BrokenChild[0].Guarantor){
      BrokenChildSorted = sortArray(BrokenChild);
    }
    let statusTextClass = getStatusTextClass(BrokenChild);

    return(
      <div className="col-12">
       {
         <table className="wd100">
           <tbody>
             <tr><td>
               <Collapse
                 onChange={onChange}
                 activeKey={activeKey ? activeKey.toString() : ""}
                 accordion={true}
               >
                 {
                   BrokenChildSorted && BrokenChildSorted.map( (item:any, key:number) =>
                      <Panel
                       header={ item.Guarantor === item.PT_Num ?
                         <span>Guarantor: {item.PT_Name} <b className={statusTextClass[item.PT_Num].class}>{statusTextClass[item.PT_Num].text}</b></span> :
                         <span>Dependent: {item.PT_Name} <b className={statusTextClass[item.PT_Num].class}>{statusTextClass[item.PT_Num].text}</b></span> }
                       key={item.PT_Num}
                      //  headerClass={ statusTextClass[item.PT_Num].class}
                       >
                         <table key={key} className="table"><tbody>
                           <tr>
                             <td><label> Patient Name</label>
                               <p className="cur-pointer color-secondary text-md" onClick={ () => changeTab(item.PT_Num) }>
                                 { item.PT_Name }
                               </p>
                             </td>
                             <td><label>Patient ID</label>
                               <p className="color-secondary text-md">{ item.PT_Num }</p>
                             </td>
                           </tr>
                           <tr>
                             <td><label>Age</label> <p className="color-secondary text-md">{ item.age }</p></td>
                             <td><label>Birth Date</label> <p className="color-secondary text-md">{ item.BirthDate }</p></td>
                           </tr>
                           <tr>
                             <td><label>Practice</label> <p className="color-secondary text-md">{ props.location }</p></td>
                             <td><label>Visit Count</label> <p className="color-secondary text-md">{item.Insurance && item.Insurance.Visit_Count ? item.Insurance.Visit_Count : ''}</p></td>
                           </tr>
                           <tr>
                             <td><label>Show Rate (%)</label> <p className="color-secondary text-md">{item.Insurance && item.Insurance.show_rate ? item.Insurance.show_rate : ''}</p></td>
                             <td><label>Insurance</label> <p className="color-secondary text-md">{item.Insurance && item.Insurance.Insurance ? item.Insurance.Insurance : ''}</p></td>
                           </tr>
                           <tr>
                             <td><label>Best Contact Time</label> <p className="color-secondary text-md">{ item.bestContactTime }</p></td>
                             <td><label>Ins Verified</label> <p className="color-secondary text-md">{item.DateLastVerified ? item.DateLastVerified : item.Insurance && item.Insurance.DateLastVerified ? moment(item.Insurance.DateLastVerified).format("MM/DD/YYYY") : ''}</p></td>
                           </tr>
                           <tr>
                             <td colSpan={2}><label>Phone Number</label>
                                <div className="color-secondary">
                                    { (item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone) ?
                                        <div className="phoneTolTipOuter">
                                          <a onClick={ () => phoneTolTip( item.PT_Num ) }>
                                            { item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone }
                                          </a>
                                          { phoneTolTipState && phoneTolTipState[item.PT_Num] === 'true' &&
                                            <div className="phoneTolTip childPage-phoneTolTip">
                                                { PhoneNumbersString(item.PT_Num, item.Guarantor, item.phoneStatus, [item.HmPhone, item.WkPhone, item.WirelessPhone, item.phone]) }
                                            </div>
                                          }
                                        </div> : ''
                                    }
                                </div>
                             </td>
                             <td> </td>
                           </tr>
                         </tbody></table>
                      </Panel>
                   )
                 }
               </Collapse>
             </td></tr>
           </tbody>
         </table>

       }
      </div>
    );
  
}

export default FamilyMembers;
