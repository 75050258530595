import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Dropdown, Form, Icon, Input, Modal } from 'semantic-ui-react';

interface FindMeModalProps {
    onSearch: Function,
    onClose: any,
    practices:any,
}


function FindMeModal(props: FindMeModalProps) {
    const initialState: any = {
        phoneNumber: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        patNum:null,
        practice:null
    };
    const [state, setStates] = useState(initialState);

    const formatPhoneNumber=(value:any) =>{
        if (!value) return value;   
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
        }
      
        return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }

    const onChange = (name: string, value: any) => {
        if(name=="phoneNumber"){
            const formattedPhoneNumber = formatPhoneNumber(value);
            setStates({...state, [name]: formattedPhoneNumber});
        }
        else if (name=="birthDate"){
            if(value){
            const edvalue = value.split('/');
            const dob = edvalue[2]+'-'+edvalue[0]+'-'+edvalue[1];
            setStates({...state, [name]: dob});
            }
        }
        else{                       
            setStates({...state, [name]: value});
        }
        
    }

    const submitSearch = (e: any) => {
        const hasValues = Object.values(state).some(val => val);
        props.onSearch(hasValues ? state : null);
    }

    return (
        <Modal
            open={true}
            onClose={props.onClose}
            closeIcon={true}
        >
            <Modal.Content scrolling={true}>
                <h4>Find Referral</h4>
                <Form>
                    <Form.Field>
                        <label>Phone Number</label>
                        <Input onChange={(e, data) => onChange('phoneNumber', data.value)}
                           value={state.phoneNumber}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Firstname</label>
                        <Input onChange={(e, data) => onChange('firstName', data.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Lastname</label>
                        <Input onChange={(e, data) => onChange('lastName', data.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Birthdate</label>
                        <DatePicker
                            selected={state.birthDate ? new Date(state.birthDate) : state.birthDate}
                            dateFormat='MM-dd-yyyy'
                            placeholderText="mm/dd/yyyy"
                            onChange={(date) => onChange('birthDate', date ? new Date(date.toString()).toLocaleString().split(',')[0] : date)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>PatNum</label>
                        <Input onChange={(e, data) => onChange('patNum', data.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Practice</label>
                        <Dropdown
                         clearable={true}
                         options={props.practices}
                         search={true}
                         selection={true}
                         onChange={(e, data) => onChange('practice', data.value)}
                      />
                    </Form.Field>
                    <Form.Field className='text-right'>
                        <Button secondary={true} onClick={submitSearch}>
                            <Icon name='search'/> Search
                        </Button>
                    </Form.Field>
                </Form>
            </Modal.Content>
        </Modal>
    );
}


export default FindMeModal;
