import { combineReducers } from "redux";
import * as authReducer from "./user";
import * as alertReducer from "./alert";
import * as appReducer from "./app";
import * as practiceReducer from "./practice";
import * as reportReducer from "./report";
import * as adjustmentReducer from "./adjustment";
import * as patientsReducer from './patients'
import * as mdhmConfirmation from './mdhmConfirmation'
import * as referralReducer from './referrals';
import * as billingReducer from './billing';
import * as procedureCodesReducer from "./procedureCodes";
import * as oralSurgeryStatuses from "./oralSurgeryStatuses";
import * as providerReducer from "./provider";
import * as regionReducer from './region';
import * as userSetupReducer from './userSetup';

export default () =>
	combineReducers({
		...authReducer,
		...alertReducer,
		...appReducer,
		...practiceReducer,
		...reportReducer,
		...adjustmentReducer,
		...patientsReducer,
		...mdhmConfirmation,
		...referralReducer,
		...billingReducer,
		...procedureCodesReducer,
		...oralSurgeryStatuses,
		...providerReducer,
		...regionReducer,
		...userSetupReducer
	});


