import React, {useState} from "react";
import {Button, Grid, Form} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {Redirect} from "react-router";
import {Alert, RootState} from "../model";
import {connect} from "react-redux";
import {Account} from "../model/user";
import Logo from "../styles/images/cdpLogin.png";

interface Props {
    user: Account
    alert: Alert
}

function LoginForm(props: Props) {
    const initialState = {
        username: '',
        password: '',
    };
    const [state, setState] = useState(initialState);
    const {user, alert} = props;
    const authActions = useActions(Actions);
    const onChange = (e: any) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const doLogin = (event: any) => new Promise((resolve, reject) => {
        event.preventDefault();
        const {username, password} = state;
        const obj = {loginWithForm: true, username: username, password: password};
        authActions.LogInWithForm(obj);
    });

    if (user && user.id) {
        return <Redirect to={'/'}/>;
    }
    return (
        <div className="login">
            <div className="bgShape">
                <Grid>
                    <Grid.Row>
                        <Grid.Column floated='right' className="customGrid" computer={14} tablet={16} mobile={16}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column className='textCenter'>
                                        <img
                                            src={Logo}
                                            className="align-self-center loginLogo"
                                            alt="Logo"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row centered>
                                    <Grid.Column computer={6} tablet={8} mobile={15}>
                                        <Form className="mt30" onSubmit={doLogin}>
                                            <Form.Field>
                                                <label>USERNAME</label>
                                                <input className='form-control'
                                                       type="text" name="username"
                                                       value={state.username || ""}
                                                       onChange={onChange} required/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>PASSWORD</label>
                                                <input className='form-control'
                                                       type="password" name="password"
                                                       value={state.password || ""}
                                                       onChange={onChange} required/>
                                            </Form.Field>

                                            <Button primary>Login</Button>
                                        </Form>
                                      {alert.message && <p className={'red mt30'}>{alert.message}</p>}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </div>
        </div>


    );
}


function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert
    };
}

export default connect(mapStateToProps)(LoginForm);

