import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useActions } from "../../redux/actions";
import FamilyMembers from './FamilyMembers';
import FamilyTableView from './FamilyTableView';
import XrayViewer from "../XrayViewer";
import Medical from "./Medical"
import { Button, Card } from "semantic-ui-react";
import { connect } from "react-redux";
import * as billingActions from '../../redux/actions/billing';
import { RootState } from '../../model';

interface Props {
    selectedPT_Num: any
    BrokenChildTable:any
    selectedPractice:any 
    selectedPracticeName:any 
    changeTab:any 
    BrokenChild:any 
    medical:any
    phoneTolTip?:any
}

const FamilyView: React.FC<Props> = (props) => {
    const billingAction = useActions(billingActions);

    const initialState = {
        activeKey: props.selectedPT_Num,
        showMedicalPopup: false,
        phoneTolTip:props.phoneTolTip,
        phoneStatus:''
    }

    const [state, setState] = useState(initialState);

    useEffect(()  => {
        const {selectedPT_Num,selectedPracticeName} = props;
        if(selectedPT_Num&&selectedPracticeName) {
            billingAction.getMedical(selectedPT_Num,selectedPracticeName);
        }
    }, []);
    
    const getPractice = (location: any) => {
        let practice = location;
        if (practice === 'sanantonio')
            practice = 'San Antonio';
        else if (practice === 'mineralwells')
            practice = 'Mineral Wells';
        else if (practice === 'epmontana')
            practice = 'EP Montana';
        else if (practice === 'epzaragosa' || practice === 'epzaragoza')
            practice = 'EP Zaragoza';
        else if (practice === 'mcallen')
            practice = 'McAllen';
        else if (practice === 'grandprairie')
            practice = 'Grand Prairie';

        return practice;
    }

    const treatmentPlanCalculation = (treatmentPlan:any, selectedPatient:any) => {
        let txPlanTotal = 0;
        let txPlanIgnoreTotal = 0;
        if (treatmentPlan[selectedPatient.PT_Num] && treatmentPlan[selectedPatient.PT_Num].length) {
            const txPlanIgnore = selectedPatient.txPlanIgnore ? JSON.parse(selectedPatient.txPlanIgnore) : {};
            treatmentPlan[selectedPatient.PT_Num].forEach((item:any) => {
                if (item.ProcFee && txPlanIgnore[item.ProcNum] !== "true") {
                    txPlanTotal += item.ProcFee;
                }
                if (txPlanIgnore[item.ProcNum] === "true")
                    txPlanIgnoreTotal++;
            })
            return ({
                txPlanTotal: txPlanTotal.toFixed(2),
                txPlanIgnoreTotal,
                notSelectedTotal: treatmentPlan[selectedPatient.PT_Num].length - txPlanIgnoreTotal
            });
        } else {
            return null;
        }
    }

    const matchTxTotal = (treatmentPlan:any, txPlanIgnore:any) => {
        let txPlanIgnoreObj = txPlanIgnore ? JSON.parse(txPlanIgnore) : {};
        let txPlanTotal = 0;

        treatmentPlan.forEach((item:any) => {
            if (item.ProcFee && txPlanIgnoreObj[item.ProcNum] !== 'true') {
                txPlanTotal += item.ProcFee;
            }
        });
        return txPlanTotal;
    };

    const getStatusTextClass = (BrokenChild:any) => {
        const { BrokenChildTable } = props;
        let response:any = {}
        BrokenChild.forEach((item:any) => {

            let text = null
            let cls = '';
            const NextAptDays = item.Next_Apt ? moment(item.Next_Apt).diff(moment(), 'days') : item.Next_Apt;

            const MatchTxTotal:any = (BrokenChildTable.treatmentPlan && Object.keys(BrokenChildTable.treatmentPlan).length && BrokenChildTable.treatmentPlan[item.PT_Num]) ?
                matchTxTotal(BrokenChildTable.treatmentPlan[item.PT_Num], item.txPlanIgnore) : 0;

            const lastExamDate = (BrokenChildTable.lastExamResult && Object.keys(BrokenChildTable.lastExamResult).length && BrokenChildTable.lastExamResult[item.PT_Num]) ?
                BrokenChildTable.lastExamResult[item.PT_Num] : 0;

            if (item.Next_Apt && NextAptDays <= 14 && NextAptDays >= 0 && MatchTxTotal) {
                text = `TX - ${MatchTxTotal.toFixed(2)}`;
                cls = 'text-green';
            } else if ((!item.Next_Apt || item.Next_Apt < 0 || NextAptDays > 14) && MatchTxTotal) {
                text = `TX - ${MatchTxTotal.toFixed(2)}`;
                cls = 'text-red';
            } else if (!item.Days_Since_Last_Visit && item.Next_Apt) {
                text = 'NP';
                cls = 'text-green';
            } else if (!item.Days_Since_Last_Visit && !item.Next_Apt) {
                text = 'NP';
                cls = 'text-red';
            } else if (item.age < 3 && item.Next_Apt) {
                text = 'FDH';
                cls = 'text-green';
            } else if (item.age < 3 && !item.Next_Apt) {
                text = 'FDH';
                cls = 'text-red';
            } else if (lastExamDate > 180 && item.Next_Apt) {
                text = 'RC';
                cls = 'text-green';
            } else if (lastExamDate > 180 && !item.Next_Apt) {
                text = 'RC';
                cls = 'text-red';
            } else if (lastExamDate && lastExamDate <= 180 && item.Next_Apt) {
                text = 'ND';
                cls = 'text-green';
            } else if (lastExamDate && lastExamDate <= 180 && !item.Next_Apt) {
                text = 'ND';
                cls = 'text-red';
            } else if (!lastExamDate && item.LastVisit) {
                text = 'RC';
                cls = 'text-red';
            }

            response[item.PT_Num] = { class: cls, text: text ? ' - ' + text : '' }
        });
        return response;
    };

    const onChange = (activeKey:any) => {
        setState({ ...state, activeKey});
    };

    const PhoneNumbersString = (PatNum:any, Guarantor:any, lastPhoneStatusParam:any, phoneArray:any) => {

        const savePhoneArray:any = [];
        const newArray = phoneArray.filter((item:any) => {
            if (item && item !== '' && savePhoneArray.includes(item.trim()) === false) {
                savePhoneArray.push(item.trim());
                return true;
            } else {
                return false;
            }
        });

        let response:any = [];
        if (newArray.length) {
            newArray.forEach((phoneNumber:any, key:number) => {
                if (phoneNumber) {
                    phoneNumber = phoneNumber.trim();
                    const lastPhoneStatus = (lastPhoneStatusParam && Object.keys(lastPhoneStatusParam).length) ? JSON.parse(lastPhoneStatusParam) : {};
                    let phoneStatusState = state[`phoneStatus`] || lastPhoneStatus;
                    const bellStatus = (phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][1]) ? false : true;
                    const messageStatus = (phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][2]) ? false : true;
                    const phoneStatus = (phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][3]) ? false : true;
                    const headStatus = (phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][4]) ? false : true;

                    response.push(<div className="phoneTolTipImg" key={key}>
                        <img alt="icon-bell" src={`/images/icon-bell${((!bellStatus) ? '-active' : '')}.png`} />
                        <img alt="icon-message" src={`/images/icon-message${((!messageStatus) ? '-active' : '')}.png`} />
                        <img alt="icon-phone" src={`/images/icon-phone${((!phoneStatus) ? '-active' : '')}.png`} />
                        <img alt="icon-head" src={`/images/icon-head${((!headStatus) ? '-active' : '')}.png`} />
                        <p className="phoneTolTipP">{phoneNumber}</p>
                    </div>);
                }
            });
        }
        return response;
    }
    const hideMedical = () => {
        setState({ ...state, showMedicalPopup: false })
    }
    const phoneTolTip = (PatNum: number) => {
        let flag = 'true';
        if (state.phoneTolTip && state.phoneTolTip[PatNum] === 'true')
            flag = 'false'

        setState({ ...state, phoneTolTip: { [PatNum]: flag } });
    }

        const { BrokenChildTable, selectedPT_Num, selectedPractice, selectedPracticeName, changeTab, BrokenChild, medical } = props;
        let selectedPatient = BrokenChild ? BrokenChild.filter((item:any) => item.PT_Num === selectedPT_Num) : null;
        const { showMedicalPopup } = state;
        // Assign first Dependent, If selected petient does not find in BrokenChild array
        if (BrokenChild.length > 0 && !selectedPatient[0]) {
            selectedPatient[0] = BrokenChild[0];
        }
        let vitals = ""
        if (medical && medical.vitals && medical.vitals.length > 0) {
            vitals = medical.vitals[0].vitals
        }
        let location2 = getPractice(selectedPractice);
        let TreatmentPlanCalculation = (BrokenChildTable.treatmentPlan && Object.keys(BrokenChildTable.treatmentPlan).length && selectedPatient[0]) ? treatmentPlanCalculation(BrokenChildTable.treatmentPlan, selectedPatient[0]) : {};
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="leftSection">
                            <div className="row">
                                <div className="col-12">
                                    <Card>
                                        <Card.Content className="bg-color-primary">
                                            <Card.Header >
                                                <h3 className="headingSub color-white text-uppercase text-md">Patient Information</h3>
                                                <Button primary className="notes" onClick={() => { setState({ ...state, showMedicalPopup: true }) }}>Medical</Button>
                                            </Card.Header>
                                        </Card.Content>

                                        <Card.Content className="patientInfo">
                                            <div className="row">
                                                <div className="col-12">
                                                    {selectedPatient.length && selectedPatient[0] !== undefined && selectedPatient.map((item:any) =>
                                                        <table key={item.PT_Num} className="table mb-0 wd100">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <label> Patient Name</label>
                                                                        <p className="color-secondary text-md">{item.PT_Name}</p>
                                                                    </td>
                                                                    <td><label>Patient ID</label> <p
                                                                        className="color-secondary text-md">{item.PT_Num}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Age</label>  <p
                                                                        className="color-secondary text-md">{item.age}</p></td>
                                                                    <td><label>Birth Date</label>  <p
                                                                        className="color-secondary text-md">{item.BirthDate}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Practice</label>  <p
                                                                        className="color-secondary text-md">{selectedPracticeName || selectedPractice}</p></td>
                                                                    <td><label>Visit Count</label>  <p
                                                                        className="color-secondary text-md">{item.Insurance && item.Insurance.Visit_Count ? item.Insurance.Visit_Count : ''}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Last Visit</label>  <p
                                                                        className="color-secondary text-md">{item.LastVisit ? item.LastVisit : ''}</p>
                                                                    </td>
                                                                    <td><label>Next Apt</label>  <p
                                                                        className="color-secondary text-md">{(item.Next_Apt) ? item.Next_Apt : ''}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Show Rate (%)</label>  <p
                                                                        className="color-secondary text-md">{item.Insurance && item.Insurance.show_rate ? item.Insurance.show_rate : ''}</p>
                                                                    </td>
                                                                    <td><label>Insurance</label> <p
                                                                        className="color-secondary text-md">{item.Insurance && item.Insurance.Insurance ? item.Insurance.Insurance : ''}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Best Contact Time</label> <p
                                                                        className="color-secondary text-md">{item.bestContactTime}</p>
                                                                    </td>
                                                                    <td><label>Ins Verified</label> <p
                                                                        className="color-secondary text-md">{item.DateLastVerified ? item.DateLastVerified : item.Insurance && item.Insurance.DateLastVerified ? moment(item.Insurance.DateLastVerified).format("MM/DD/YYYY") : ''}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Phone Number</label>
                                                                        <div>
                                                                            {(item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone) ?
                                                                                <div className="phoneTolTipOuter">
                                                                                    <a onClick={() => phoneTolTip(item.PT_Num)}>
                                                                                        {item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone}
                                                                                    </a>
                                                                                    {state.phoneTolTip && state.phoneTolTip[item.PT_Num] === 'true' &&
                                                                                        <div
                                                                                            className="phoneTolTip childPage-phoneTolTip">
                                                                                            {PhoneNumbersString(item.PT_Num, item.Guarantor, item.phoneStatus, [item.HmPhone, item.WkPhone, item.WirelessPhone, item.phone])}
                                                                                        </div>
                                                                                    }
                                                                                </div> : ''
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <XrayViewer
                                                                            {...props}
                                                                            practice={selectedPractice || selectedPracticeName}
                                                                            patientNumber={item.PT_Num} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><label>Vitals</label> <p
                                                                        className="color-secondary text-md">{vitals}</p>
                                                                    </td>
                                                                    <td><label>Ins Verified</label> <p
                                                                        className="color-secondary text-md">{item.DateLastVerified ? item.DateLastVerified : item.Insurance && item.Insurance.DateLastVerified ? moment(item.Insurance.DateLastVerified).format("MM/DD/YYYY") : ''}</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </Card.Content>
                                    </Card>


                                    <Card className="pd0 mt20 patientInfo">
                                        <Card.Content>
                                            <Card.Header >
                                                <h3 className="headingSub color-primary text-md">Family View</h3>
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content>
                                            <div className="row">
                                                <FamilyMembers location={location2} BrokenChild={BrokenChild}
                                                    selectedPT_Num={selectedPT_Num} changeTab={changeTab}
                                                    treatmentPlanCalculation={treatmentPlanCalculation}
                                                    BrokenChildTable={BrokenChildTable}
                                                    getStatusTextClass={getStatusTextClass}
                                                    onChange={onChange} activeKey={state.activeKey}
                                                    PhoneNumbersString={PhoneNumbersString}
                                                    phoneTolTip={phoneTolTip}
                                                    phoneTolTipState={(state.phoneTolTip) ? state.phoneTolTip : null} />
                                            </div>
                                        </Card.Content>
                                    </Card>

                        </div>
                        </div>
                        </div>
                        <div className="rightSection">
                            <FamilyTableView BrokenChildTable={BrokenChildTable} selectedPatient={selectedPatient[0]}
                                treatmentPlanCalculation={treatmentPlanCalculation} selectedPracticeName={selectedPracticeName}
                            />
                        </div>
                    </div>
                </div>
                {showMedicalPopup && <Medical hideMedical={hideMedical}
                    selectedPT_Num={props.selectedPT_Num}
                    selectedPractice={selectedPractice}
                    selectedPracticeName={selectedPracticeName}
                />
                }
            </div>

        );
    
}


    function mapStateToProps(state: RootState) {
        return {
            user: state.auth.user,
            medical: state.billing.medical,
            familyData: state.billing.familyData,
        };
    }
    
    export default connect(mapStateToProps)(FamilyView);