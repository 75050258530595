import React from 'react'
import clsx from 'clsx'
import { navigate } from '../constants'
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomToolBar = (props:any) => {
    
        let {
            localizer: { messages },
            label,
        } = props;

        const Navigate = (action:any) => {
            setTimeout(() => {
            props.onNavigate(action)
            }, 100);
        }
    
        const View = (view:any) => {
            props.onView(view)
        }
    
        const viewNamesGroup = (messages:any) => {
            let viewNames = props.views
            const view = props.view
    
            if (viewNames.length > 1) {
                return viewNames.map((name:any) => (
                    <button
                        type="button"
                        key={name}
                        className={clsx({ 'rbc-active': view === name })}
                        onClick={View.bind(null, name)}
                    >
                        {messages[name]}
                    </button>
                ))
            }
        }

        return (
            <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
              type="button"
              onClick={Navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </button>
          <button
              type="button"
              onClick={Navigate.bind(null, navigate.PREVIOUS)}
          >
            {messages.previous}
          </button>
          <button
              type="button"
              onClick={Navigate.bind(null, navigate.NEXT)}
          >
            {messages.next}
          </button>
        </span>

                <div style={{paddingLeft:'10px'}} className="datePickerRef">
                    <DatePicker
                        dateFormat="MMM yyyy"
                        showMonthYearPicker={true}
                        onChange={(date,view) => props.onNavigate(navigate.DATE,date)}
                        selected={new Date(moment(props.date).startOf('month').format('YYYY-MM-DD'))}
                    />
                </div>
                <span className="rbc-toolbar-label">{label}</span>
                <span className="rbc-btn-group">{viewNamesGroup(messages)}</span>
            </div>
        )
    

    
}

export default CustomToolBar