import React from "react";
import {ProcedureCodeProps} from "../model/procedureCodeList";
import {Button, Dropdown,Label} from 'semantic-ui-react'
import _ from "lodash";
import { useState } from "react";


const AddProcedureCodeListOral:React.FC<ProcedureCodeProps> = (props:ProcedureCodeProps) => {
    const {onCancel, saveProcedureCodeDetails,procedureCodeOptions,allProcedureCodes,initialValue} = props;
    const [selectedDesc,setSelectedDesc]=useState('')
    const [procedureObject,setProcedureObject]=useState<any>({})
    return (
        <>
        <Label>Select Procedure</Label>
        <Dropdown search selection options={procedureCodeOptions} value={typeof initialValue!=="undefined"?initialValue.id:''} onChange={(e,data)=>{
            const procedureObject=_.find(allProcedureCodes,function(item){
                if(item.procedureCode.includes(data.value as string))
                  return true
                else 
                    return false
            })
            setSelectedDesc(procedureObject.description)
            setProcedureObject(procedureObject)
        }}>
        </Dropdown>
        {}
        {selectedDesc!==""?<>
        <h4>
            Descritpion :<p>{selectedDesc}</p>
        </h4>
        <Button primary onClick={async()=>{
            const {description,procedureCode}=procedureObject
            const values ={description,procedureCode}
            await saveProcedureCodeDetails(values)
            setSelectedDesc('')
            onCancel()
        }}>
            Save 
        </Button>
        <Button onClick={()=>{
            setSelectedDesc('')
            onCancel()
        }}>
            Cancel 
        </Button>
        </>:<><Button primary onClick={
            onCancel
        }>
            Cancel 
        </Button></>}
        </>
    );
};
export default AddProcedureCodeListOral;
