import {ProcedureCodeListProps} from "../../model/procedureCodeList";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:ProcedureCodeListProps = {
	procedureCodeLists:[],
	procedureCodeDetailsList:[],
	procedureCodeListOptions:[],
	procedureCodecounts:{}

};
export const procedureCode= createReducer(initialState, {
	
	[ActionType.FETCH_PROCEDURE_CODE_LIST](state: ProcedureCodeListProps, action: Action<ProcedureCodeListProps>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PROCEDURE_CODE_DETAILS_LIST](state: ProcedureCodeListProps, action: Action<ProcedureCodeListProps>) {
		return Object.assign({...state}, action.payload);
	},
});
