import React, {useEffect, useState, useRef} from "react";
import { DynamicReportTable } from "../model/dynamicReportsTable";
import {Icon, Modal} from 'semantic-ui-react';
import {split} from "lodash";
import { isBlankNullOrUndefined } from "../utils/common";

export default function DynamicReportsTable(props: DynamicReportTable) {
    const initialState: any = {
        TableHead: [],
        TableData: []
    };

    let [states, setState] = useState(initialState);
    const [openModal, setOpenModal] = useState(false);
    const [numerator, setNumerator] = useState(0);
    const [denominator, setDenominator] = useState(0);
    const [type, setType] = useState('');

    useEffect(() => {
        const getTableHeadings = () => {
            let returnArray: any = [];
            if (props.tableHead.length) {
                props.tableHead.forEach((item, key) => {
                    let index = -1;
                    const title: any = item.title;
                    if (props.excludeSorting) {
                        index = props.excludeSorting.findIndex((head: string) => head.toLowerCase() === title.toLowerCase())
                    }
                    const icon = props.onHeadClick ? (props.sortedColumn === (item.key || key) ?
                        <Icon name={props.direction}/> : <Icon name={'sort'}/>) : null;
                        returnArray.push(
                            <th
                              className={item.className}
                              id={item.id}
                              style={item.style}
                              key={item.key||key}
                              onClick={props.onHeadClick ? props.onHeadClick(item.key||key) : void(0)}
                            >
                              {index === -1 && item.title!=="Edit/Delete" && <div>{item.title}  {icon} </div>}
                              
                              {index > -1 && <div>{item.title} </div>}
                            </th>)
                        })
            }
            return (<thead>
            <tr>{returnArray}</tr>
            </thead>)
        };

        const getTableBody = () => {
            let tableRows: any = [];
            let modalRSOpen:Function;
            if(props.modalRSOpen !== undefined){
                modalRSOpen = props.modalRSOpen;
              }

            if (props.tableData.length) {
                props.tableData.forEach((itemArray, key) => {
                    if (itemArray.length) {
                        let tableColumns: any = [];
                        itemArray.forEach((item, key2) => {
                            const desc = isBlankNullOrUndefined(item.desc) ? '' : item.desc
                            if (itemArray[0].title !== undefined && itemArray[0].title.toString().toLowerCase() === 'totals') {
                                const htmlObject = document.createElement('div');
                                htmlObject.innerHTML = item.title.toString();
                                const el = htmlObject.getElementsByClassName('percentValues')
                                const numerator = el.length && el[0].getAttribute('data-numerator');
                                const denominator = el.length && el[0].getAttribute('data-denominator');
                                const type: any = el.length && el[0].getAttribute('data-type');

                                el.length ? tableColumns.push(
                                        <td className={'reports-table-totals'} id={item.id} style={item.style}
                                            key={item.key || key2}>
                                            <div
                                                className={'btn secondaryUnderline'}
                                                onClick={() => {
                                                    setNumerator(Number(numerator));
                                                    setDenominator(Number(denominator));
                                                    setType(type);
                                                    setOpenModal(true);
                                                }}
                                            >
                                                {el[0].innerHTML}
                                            </div>
                                        </td>
                                    ) :
                                    tableColumns.push(<td className={'reports-table-totals'} id={item.id}
                                                          style={item.style} key={item.key || key2}> {item.title} </td>)
                            } else if(!isBlankNullOrUndefined(item) && Number.isInteger(Number(item.title)) && item.title > 0 && !isBlankNullOrUndefined(props.source)) {
                                tableColumns.push(<td className= 'clickable sizeRos' id={item.id}  style={item.style} key={item.key||key2} onClick={() => modalRSOpen(itemArray[0].type === 'doctor' ? itemArray[0].providerList :itemArray[0].dbName !== undefined ? itemArray[0].dbName : itemArray[0].title, desc, itemArray[0].type === 'doctor' ? 'provider' : 'practice')}>{item.title}</td>)
                            } else {
                                let htmlObject = document.createElement('div');
                                htmlObject.innerHTML = isBlankNullOrUndefined(item.title) ? '' : item.title.toString();
                                const el = htmlObject.getElementsByClassName('percentValues')
                                const numerator = el.length && el[0].getAttribute('data-numerator');
                                const denominator = el.length && el[0].getAttribute('data-denominator');
                                const type: any = el.length && el[0].getAttribute('data-type');

                                el.length ? tableColumns.push(
                                        <td className={item.className} id={item.id} style={item.style}
                                            key={item.key || key2}>
                                            <div
                                                className={'btn secondaryUnderline'}
                                                onClick={() => {
                                                    setNumerator(Number(numerator));
                                                    setDenominator(Number(denominator));
                                                    setType(type);
                                                    setOpenModal(true);
                                                }}
                                            >
                                                {el[0].innerHTML}
                                            </div>
                                        </td>) :
                                    tableColumns.push(<td className={item.className} id={item.id} style={item.style}
                                                          key={item.key || key2}> {item.title} </td>)
                            }
                        });
                        tableRows.push(<tr key={key}>{tableColumns}</tr>)
                    }
                })
            }
            return (<tbody>{tableRows}</tbody>)
        };

        const TableData = getTableBody();
        const TableHead = getTableHeadings();
        setState({TableData, TableHead})
    }, [props.tableData])


    const calculationModal = (numerator: any, denominator: any, type: any) => {
        const titleType = split(type, '-')[0];
        const titleSubTitle = split(type, '-')[1];
        return (
            <Modal
                open={openModal}
                closeIcon={true}
                onClose={() => setOpenModal(false)}
            >
                <Modal.Header>
                    {titleType} Calculation
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <strong> Out
                            of {denominator} {titleSubTitle} </strong> | <strong> {numerator} = {titleType} </strong>
                    </div>
                    <br/>
                    <div> ({numerator} / {denominator}) x 100 = {Number((numerator / denominator * 100).toFixed(1))}%
                    </div>
                </Modal.Content>
            </Modal>
        );
    }

    return (<div className="ui card">
        {calculationModal(numerator, denominator, type)}
        <div className="content">
            <h5>{props.heading}</h5>
            <div className="table-responsive FreezeTable">
                <table id={`${props.id && props.id}`} style={props.style}
                       className={`${props.className !== '' ? `ui celled single line table unstackable tableStyle ${props.className}` : "table table-striped table-hover"}`}>
                    {states.TableHead}
                    {states.TableData}
                </table>
            </div>
        </div>
    </div>)

}
