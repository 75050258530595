import { Link } from 'react-router-dom';
import {
  AgeTypes,
  ByCodeTypes,
  InsuranceTypes,
  ReportInitialState,
  OutlookTypes
} from "./model/report";
import { DropdownItemProps, SemanticICONS } from 'semantic-ui-react';
import { StatusType } from './model/StatusType';
export const API_URL: string = process.env.NODE_ENV === "development"
  ? "http://localhost:5006"
  : "https://apireferout.cdp.dental";

  export interface SubMenux {
    pageId: number;
    name: string;
    icon: SemanticICONS;
    title: string;
    role: string[];
    hide?: boolean;
    customIcon?: string;
  }
export interface SubMenu {
  pageId: number;
  class: string;
  name: string;
  icon?: SemanticICONS;
  title: string;
  role: string[];
  hide?: boolean;
  customIcon?: string;
  subMenux?: SubMenux[];
}

export interface MainMenu {
  class: string;
  customIcon?: string;
  icon: SemanticICONS;
  name: string;
  pageId: number;
  partner: string;
  subMenus?: SubMenu[];
  title: string;
  role: [];
  hide?: boolean;
}

export const brands = [
  {text: 'SDD', value: 'SDD', key: 'SDD'},
  {text: '3CD', value: '3CD', key: '3CD'},
  {text: 'FSD', value: 'FSD', key: 'FSD'},
  {text: 'OTD', value: 'OTD', key: 'OTD'},
  {text: 'SMD', value: 'SMD', key: 'SMD'},
  {text: 'AMD', value: 'AMD', key: 'AMD'},
];

export const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
}

export const aptStatus:StatusType[] = [
  {id: 0, referStatus: '', statusColor: ''},
  {id: 1, referStatus: 'Scheduled', statusColor: '#b46851'},
  {id: 2, referStatus: 'Complete', statusColor: '#278b23'},
  {id: 3, referStatus: '', statusColor: ''},
  {id: 4, referStatus: '', statusColor: ''},
  {id: 5, referStatus: 'Broken', statusColor: '#ff0000'}
] 

export const doctorPracticeTypes: { [key: string]: string } = {'1': '1 Dr', '2': '2 Dr', '3': 'Denovo'};
export const createDrListOptions = () => {
  let drListOptionsArray: any = [{key: '0', value: 0, text: 'Select DrType'}];
  if (Object.keys(doctorPracticeTypes).length) {
    Object.keys(doctorPracticeTypes).forEach((key: string) => {
      drListOptionsArray.push({text: doctorPracticeTypes[key], value: key, key: key})
    });
  }
  return drListOptionsArray;
};
export const drListOptions = createDrListOptions();

export enum myPartners {
  SCHEDULE = 'Schedule',
  SITES = 'Sites',
  DENTIST = 'Dentist',
  LEADS = 'Leads',
  PCR = 'PCR',
  ADMIN = 'Admin',
  OS_PCR = 'OS PCR',
  ORTHO_PCR = 'Ortho PCR',
  SPOT = 'SPOT',
  DCRM = 'DCRM',
  SPORE = 'SPORE',
  SCHEDULER='SCHEDULER',
  CLAIMS_TEAM='CLAIMS TEAM',
  CALL_CENTER='CALL CENTER',
  CSR = 'CSR',
  DIRECTORY_ORG_CHART='DIRECTORY_ORGCHART',
  ADJUSTMENT = 'Adjustment',
  FIVE9 ='Five9',
  MDM ='MDM',
  MDHM ='MDHM',
  MDHMS ='MDHMS',
  ALERTS = 'ALERTS',
  PMD = 'PMD',
  RECRUIT = 'RECRUIT',
  XSD = 'XSD',
  PF = 'Patient Finance',
  CRED = 'Credentialing',
  MATRIX = 'User Matrix',
  REFERRALS='REFERRALS',
  MEMBERS='MEMBERS'
}

export const csrHeader = [
  {key: 'row', title: 'Row labels'},
  {key: 'apt count', title: 'Apt Count'},
  {key: 'show', title: 'Show'},
  {key: 'dr count', title: 'Dr Count'},
  {key: 'dentist', title: 'PTs/Dentist'},
  {key: 'confirmed apt', title: 'All Confirmed Apt/Dentist'},
  {key: 'apts confirmed', title: 'Tx APts Confirmed'},
  {key: 'solution', title: 'Confirmed Digitally'},
  {key: 'apt sparkline', title: 'APT 7am - 6pm'},
  {key: 'confirmed sparkline', title: 'Confirmed 7am - 6pm'},
];

export const ospcrHeader = [
  {key: 'FSDPNO', title: 'FSDPNO'},
  {key: 'SMDCAR', title: 'SMDCAR'},
  {key: 'SMDLVL', title: 'SMDLVL'},
  {key: 'GrandTotal', title: 'Grand Total'},  
];

export const practiceType =[
  {key:'All', text:'All', value:'all'},
  {key:'Oral Surgery', text:'Oral Surgery', value:'oral'},
  {key:'Ortho', text:'Ortho', value:'ortho'}
];

export const byAgeObjkeys:{[key:string]: AgeTypes} = { row4: '<3', row5: '3-5', row6: '6-8', row7: '9-11', row8: '12-14', row9: '15-17', row10: '18&over'};
export const byAgeMixObjkeys:{[key:string]: AgeTypes} = { row1: '<3', row2: '3-5', row3: '6-8', row4: '9-11', row5: '12-14', row6: '15-17', row7: '18&over', row8: 'total'};
export const byInsuranceObjkeys:{[key:string]: InsuranceTypes} = { row1: 'medicaid', row2: 'ppo', row3: 'cash', row4: 'total'};
export const outLookObjkeys:{[key:string]: OutlookTypes} = { row1: 'Patient Budget', row2: 'Completed Patients',
  row3: 'Show Rate', row4: 'Expected Show', row5: 'patients Needed', row6: 'Appointments Needed', row7: '% to Goal',row8: 'Days Left',
  row9: 'More Apts Needed/Day',};

export const byCodeTypes:ByCodeTypes[] = ['FV', 'RC', 'TX'];

export const reportInitialState:ReportInitialState = {
  filters: { practice: '', drType: 0, region: 0, date: null, month: "" },
  data: null,
  selectedPractices: [],
  refreshData: false
};

export const spotLightClasses = {
  green: 'spotLightGreen',
  red: 'spotLightRed',
  yellow: 'spotLightYellow'
};

export enum DateFormat {
  LastDay = "MM/DD ddd"
}

export enum Months { 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'}

export const csvHeader = ['Practice', 'Date', 'Apt Count', 'Show', 'Dr Count', 'PTs/Dentist', 'All Confirmed Apt/Dentist',
  'Tx APts Confirmed', 'Confirmed Digitally'];

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const CSRGraphOptions = {
  maintainAspectRatio: false,
  legend: {display: false},
  tooltips: {enabled: false},
  scales: {
    xAxes: [{
      ticks: {display: false},
      stacked: false,
      gridLines: {
        display: false,
        drawBorder: false
      },
    }],
    yAxes: [{
      ticks: {display: false},
      stacked: false,
      gridLines: {
        display: false,
        drawBorder: false
      },
    }]
  }
};

export const priorities:DropdownItemProps[] =[
  {key:'P1', text:'P1', value:'P1'},
  {key:'P2', text:'P2', value:'P2'},
  {key:'P3', text:'P3', value:'P3'},
  {key:'P4', text:'P4', value:'P4'},
  {key:'P5', text:'P5', value:'P5'},
];

export const leadStatus:DropdownItemProps[] =[
  {key:'Purge Won', text:'Purge Won', value:'Purge Won'},
  {key:'Purge Lost', text:'Purge Lost', value:'Purge Lost'}
];

export const pcrCodeTypes:ByCodeTypes[] = ['FV', 'RC', 'TX'];

export enum reportPageNames {
  budgetWorkDay = 'budget-by-work-day',
  age = 'age',
  ageMix = 'age-mix',
  practice = 'practice',
  splashBoard = 'splashboard',
  cpv = 'cpv',
  insurance = 'insurance',
  insuranceMix = 'insurance-mix',
  outlook = 'outlook',
  byProviderCpv = 'provider-cpv'
}

export const defaultProviderDetailsObj = {
  daysWorked: 0,
  cpv: 0,
  Fv: 0,
  Rc: 0,
  Tx: 0,
  medicaid: 0,
  ppo: 0,
  cash: 0,
  cpv3: 0,
  cpv4to10: 0,
  cpv11to21: 0,
  cpv22above: 0,
  provider: ''
};

export enum ByAgeObjByProviderKeys {
  '<=3' = '<=3',
  '4-10' = '4-10',
  '11-21' = '11-21',
  '22&over' = '22&over'
}

export const MDMHeader = [{
  header: 'Action',
  sortBy: null
}, {
  header: 'Fields',
  sortBy: 'field'
}, {
  header: 'Description',
  sortBy: 'description'
}, {
  header: 'Formula',
  sortBy: 'formula'
}, {
  header: 'Code',
  sortBy: 'code'
}, {
  header: 'Data Source',
  sortBy: 'dataSource'
}, {
  header: 'Report',
  sortBy: 'report'
}, {
  header: 'Page',
  sortBy: 'page'
}];

export const statusOptionsValidation = [{
  key: 0,
  text: 'Select Status',
  value: 0
  },{
  key: 1,
  icon: {name: 'check circle', color: 'grey'},
  text: 'grey',
  value: 1
},{
    key: 2,
    icon: {name: 'check circle', color: 'green'},
    text: 'green',
    value: 2
  },
  {
    key: 3,
    icon: {name: 'check circle', color: 'red'},
    text: 'red',
    value: 3
  },
];
export const statusOptions = [{
  key: 'all',
  text: 'Select Status',
  value: 'all'
},{
  key: 'active and assigned',
  icon: {name: 'check circle', color: 'green'},
  text: 'PT Assigned To Practice w/ Active Insurance',
  value: 'PT Assigned To Practice w/ Active Insurance'
},
  {
    key: 'unactive',
    icon: {name: 'delete', color: 'red'},
    text: 'PT Does NOT Have Active Insurance',
    value: 'PT Does NOT Have Active Insurance'
  },
  {
    key: 'active',
    icon: {name: 'font', color: 'red'},
    text: 'PT Has Active Insurance But Not Assigned To Practice',
    value: 'PT Has Active Insurance But Not Assigned To Practice'
  },
  {
    key: 'cant find members',
    icon: {name: 'question circle', color: 'yellow'},
    text: 'Can\'t Find Member. Check Subscriber ID or Birthdate',
    value: 'Can\'t Find Member. Check Subscriber ID or Birthdate'
  },
];

export const allowDeleteUserId = [ 163, 1710, 791, 1189, 1180 ];

export const indexValues = [ 0,1,2,3,4,5,6 ];

export const enabledcategoryList = ["Error", "Verified", "Main Dental Home"];
export const enabledAptStatusList = ["Scheduled", "Complete", "Broken"];
export const enabledAptCategoryList = ["Error", "Verified", "Main Dental Home"];

export const enabledStatusCarrierColor = ["grey", "green", "red"];
export const woList = ["woPaid", "woAppealed", "woNotAppealed","woDenied","woPracticeError"];
export const woListUp = ["Wo Paid", "Wo Appealed", "Wo Not Appealed","Wo Denied","Wo Practice Error"];

export const woWeek = ["sat", "fri", "thu","wed","tue","mon"];
export const woWeekUp = ["Sat", "Fri", "Thu","Wed","Tue","Mon"];


export const finalInsurancecategoryList  = [{ins:"Error",color:["grey", "green", "red"]},
  {ins:"Verified",color:["grey", "green", "red"]},
  {ins:"Main Dental Home",color:["grey", "green", "red"]}];

export const statusList : any = {
  "Error":{grey:0,green:0,red:0},
  "Verified":{grey:0,green:0,red:0},
  "Main Dental Home":{grey:0,green:0,red:0},
};






export const enabledCarrierList = ["MCNA", "DentaQuest", "TMHP", "UHC Medicaid"];
export const enabledCarrierColor = ["green", "redA", "redX", "noImage"];
export const finalInsurance = [{ins:"MCNA",color:["green", "redA", "redX", "noImage"]},
  {ins:"DentaQuest",color:["green", "redA", "redX", "noImage"]},
  {ins:"TMHP",color:["green", "redA", "redX", "noImage"]},
  {ins:"UHC Medicaid",color:["green", "redA", "redX", "noImage"]}];


export const providerList : any = {
  "DentaQuest" : "DENTA",
  "UHC Medicaid" : "UNITED HEALTH CARE"
};
export const insuranceList : any = {
    "MCNA" : {green:0,redA:0,redX:0,noImage:0},
    "DentaQuest" : {green:0,redA:0,redX:0,noImage:0},
    "TMHP" : {green:0,redA:0,redX:0,noImage:0,},
    "UHC Medicaid" : {green:0,redA:0,redX:0,noImage:0},
};

export const cardAppsMenu = [{
  key: 1,
  name: 'Sites',
  savePartnerUsage: false,
  icon: 'building',
  button: {
      redirect: 'sites',
      savePartnerUsage: myPartners.SITES,
      to: '',
      as: 'a',
      usageName: 'sites',
      grey: false,
      href: "",
      target: '_blank'
  }
  
},{
  key: 9,
  name: 'TOOLS',
  savePartnerUsage: false,
  icon: 'wrench',
  button: {
      redirect: 'http://dcrm.cdp.dental/home',
      savePartnerUsage: '',
      to: '',
      as: '',
      usageName: 'DCRM',
      grey: false,
      href: '',
      target: ''
  }
},{
  key: 0,
  name: 'Schedule',
  savePartnerUsage: false,
  icon: 'calendar alternate',
  button: {
      redirect: 'schedule',
      savePartnerUsage: myPartners.SCHEDULE,
      to: '',
      as: '',
      usageName: 'schedule',
      grey: false,
      href: '',
      target: ''
  }
  
},{
  key: 17,
  name: 'Alerts',
  savePartnerUsage: false,
  icon: 'bullhorn',
  button: {
      redirect: 'https://alerts.cdp.dental/alert',
      savePartnerUsage: '',
      to: '',
      as: Link,
      usageName: 'Alerts',
      grey: false,
      href: '',
      target: ''
  }
  
},{
  key: 3,
  name: 'Leads',
  savePartnerUsage: false,
  icon: 'users',
  button: {
      redirect: 'leads',
      savePartnerUsage: myPartners.LEADS,
      to: '',
      as: '',
      grey: false,
      usageName: 'leads',
      href: '',
      target: ''
  }
  
},{
  key: 5,
  name: 'Admin',
  savePartnerUsage: true,
  icon: 'user',
  button: {
      redirect: '',
      savePartnerUsage: myPartners.ADMIN,
      to: '',
      as: '',
      grey: true,
      usageName: 'admin',
      href: '',
      target: ''
  }

},{
  key: 6,
  name: 'Recruit',
  savePartnerUsage: false,
  icon: 'user',
  button: {
    redirect: 'recruiting',
    savePartnerUsage: myPartners.RECRUIT,
    to: '',
    as: '',
    grey: false,
    usageName: 'recruit',
    href: '',
    target: ''
  }
},
  {
    key: 28,
    name: 'Dentist',
    savePartnerUsage: false,
    icon: 'user md',
    button: {
      redirect: 'dentist',
      savePartnerUsage: myPartners.DENTIST,
      to: '',
      as: '',
      grey: false,
      usageName: 'dentist',
      href: '',
      target: ''
    }
},{
  key: 21,
  name: 'Patient Finance',
  savePartnerUsage: true,
  icon: 'suitcase',
  button: {
      redirect: '',
      savePartnerUsage: myPartners.PF,
      to: '',
      as: '',
      grey: true,
      usageName: '',
      href: '',
      target: ''
  }

},{
  key: 22,
  name: 'Credentialing',
  savePartnerUsage: false,
  icon: 'hospital',
  button: {
    redirect: 'credentialing',
    savePartnerUsage: myPartners.CRED,
    to: '',
    as: '',
    grey: false,
    usageName: 'credentialing',
    href: '',
    target: ''
  },
  
},
{
  key:23,
  name: 'Scheduler',
  savePartnerUsage:true,
  icon:'clock outline',
  button:{
    redirect:'',
    savePartnerUsage:myPartners.SCHEDULER,
    to:'',
    as:'',
    grey:true,
    usageName:'scheduler',
    href:'',
    target:''
  }
},
{
  key:24,
  name: 'Claims Team',
  savePartnerUsage:true,
  icon:'users',
  button:{
    redirect:'',
    savePartnerUsage:myPartners.CLAIMS_TEAM,
    to:'',
    as:'',
    grey:true,
    usageName:'claims_team',
    href:'',
    target:''
  }
},
{
  key:25,
  name: 'Call Center',
  savePartnerUsage:true,
  icon:'phone square',
  button:{
    redirect:'',
    savePartnerUsage:myPartners.CALL_CENTER,
    to:'',
    as:'',
    grey:true,
    usageName:'call_center',
    href:'',
    target:''
  }
}, {
    key:26,
    name: 'Directory&OrgChart',
    savePartnerUsage:true,
    icon:'sitemap',
    button:{
      redirect:'',
      savePartnerUsage:myPartners.DIRECTORY_ORG_CHART,
      to:'',
      as:'',
      grey:true,
      usageName:'directory_org_chart',
      href:'',
      target:''
    }
  },
{
  key:29,
  name:'Referrals',
  savePartnerUsage:true,
  icon:'user plus',
  button:{
    redirect:'',
    savePartnerUsage:myPartners.REFERRALS,
    to:'',
    as:'',
    grey:true,
    usageName:'referrals',
    href:'',
    target:''
    }
},
{
  key:30,
  name:'Members',
  savePartnerUsage:true,
  icon:'users',
  button:{
    redirect:'',
    savePartnerUsage:myPartners.MEMBERS,
    to:'',
    as:'',
    grey:true,
    usageName:'members',
    href:'',
    target:''
}
},
{
  key: 31,
  name: 'SPORE',
  savePartnerUsage: true,
  icon: 'users',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.SPORE,
    to: '/sporeService',
    as: Link,
    grey: false,
    usageName: 'spore',
    href: '',
    target: ''
  }
},
];

export const cardPagesMenu = [{
  key: 14,
  name: 'Five9 List',
  savePartnerUsage: true,
  icon: 'list ul',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.FIVE9,
    to: '/five9/five9list',
    as: Link,
    usageName: 'five9',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 6,
  name: 'OS PCR',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.OS_PCR,
    to: '/open-surgery-pcr-report',
    as: Link,
    usageName: 'OS_PCR',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 15,
  name: 'MDM',
  savePartnerUsage: true,
  icon: 'database',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.MDM,
    to: '/mdm',
    as: Link,
    usageName: 'mdm',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 11,
  name: 'CSR',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.CSR,
    to: '/clinical-scheduling-report',
    as: Link,
    usageName: 'CSR',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 4,
  name: 'Patient Care Report',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.PCR,
    to: '/patient-care-report/pcr-splashboard',
    as: Link,
    usageName: 'PCR',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 13,
  name: 'Adjustment',
  savePartnerUsage: true,
  icon: 'adjust',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.ADJUSTMENT,
    to: '/adjustment/adjustment-setup',
    as: Link,
    usageName: 'ADJUSTMENT',
    grey: false,
    href: '',
    target: ''
  }

},
{
  key: 16,
  name: 'Patient Validation',
  savePartnerUsage: true,
  icon: 'user secret',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.MDHM,
    to: '/patients/patient-validation',
    as: Link,
    usageName: 'mdhm',
    grey: false,
    href: '',
    target: ''
  }

},
{
  key: 8,
  name: 'SPOT',
  savePartnerUsage: true,
  icon: 'users',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.SPOT,
    to: '',
    as: '',
    grey: true,
    usageName: 'spot',
    href: '',
    target: ''
  }

},
{
  key: 7,
  name: 'Ortho PCR',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.ORTHO_PCR,
    to: '',
    as: '',
    grey: true,
    usageName: 'Ortho_PCR',
    href: '',
    target: ''
  }

},
{
  key: 18,
  name: 'Practice Manager Dashboard',
  savePartnerUsage: true,
  icon: 'dashboard',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.PMD,
    to: '/practice-manager-dashboard',
    as: Link,
    usageName: 'pmd',
    grey: false,
    href: '',
    target: ''
  }
},
{
  key: 20,
  name: 'Xray Service Dashboard',
  savePartnerUsage: true,
  icon: 'cogs',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.XSD,
    to: '/xray-service-dashboard',
    as: Link,
    usageName: 'xsd',
    grey: false,
    href: '',
    target: ''
  }
},
{
  key: 21,
  name: 'User Matrix',
  savePartnerUsage: true,
  icon: 'cog',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.MATRIX,
    to: '/user-matrix-report',
    as: Link,
    usageName: 'matrix',
    grey: false,
    href: '',
    target: ''
  }
}
];


export const pmdexception = "Could not retreive information for selected practice, please select another practice"
export const acceptHelpFileTypes = ".mp4, .pdf";


export const HelpPages: any = {
  110: { name: "Referrals - All Referrals" },
  111: { name: "Referrals - Setup > Ortho > Procedure Codes" },
  112: { name: "Referrals - Setup > Ortho > Status Setup" },
  113: { name: "Referrals - Setup > Ortho > Notification" },
  114: { name: "Referrals - Setup > Oral Surgery > Procedure Codes" },
  115: { name: "Referrals - Setup > Oral Surgery > Status Setup" },
  116: { name: "Referrals - Setup > Oral Surgery > Notification" },
  117: { name: "Referrals - Reports" },
  118: { name: "Referrals - Usage" }
}

export enum HELP_PAGE{
  REFERRALS_ALL = 110,
  REFERRALS_SETUP_ORTHO_PROC_CODE,
  REFERRALS_SETUP_ORTHO_STATUS,
  REFERRALS_SETUP_ORTHO_NOT,
  REFERRALS_SETUP_ORAL_PROC_CODE,
  REFERRALS_SETUP_ORAL_STATUS,
  REFERRALS_SETUP_ORAL_NOTIFICATION,
  REFERRALS_REPORTS,
  REFERRALS_USAGE
}

export const LEAD_STATUS = ['New', 'In Progress', 'Won', 'Lost'];

export const CALL_RESULT = [
  "Left Message",
  "Left Voice Mail",
  "Made Contact",
  "No Answer",
  "No Message Left",
  "Not in Service",
  "Not Interested",
  "Wrong Number"
];


export const PREFERRED_TIMES = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM',
    '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM'];

export const CALL_TYPE = ['Inbound', 'Outbound'];

export const LEAD_LOST_REASON = [
  "Attempts Completed",
  "Bad Reputation",
  "Duplicate",
  "Existing Patient",
  "Incorrect Contact Information",
  "Insurance",
  "Never Made Contact",
  "Not Interested",
  "Other",
  "Other Dentists",
  "Too Far"
];
