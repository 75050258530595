import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";

export const fetchOralSurgeryStatus = () => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    try {
        const resp = await api.get(`statusSetup/fetchAll`);
        dispatch({
            type: ActionType.FETCH_ORAL_SURGERY_STATUSES,
            payload: { oral_status: resp },
        });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

export const fetchOralSurgeryStatusWork = () => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    try {
        const resp = await api.get(`statusSetup/fetchAll`);
        dispatch({
            type: ActionType.FETCH_ORAL_SURGERY_STATUSES,
            payload: { oral_status: resp },
        });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

export const fetchByIdOralSurgeryStatus = (id: any) => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`statusSetup/oral-ortho-surgery/fetchSingle`, id);
        dispatch({
            type: ActionType.FETCH_BY_ID_ORAL_SURGERY_STATUS,
            payload: { oral_status: resp },
        });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

export const updateOralSurgeryStatus = (id: any) => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`statusSetup/oral-ortho-surgery/updateSingle`, id);
        dispatch({
            type: ActionType.FETCH_BY_ID_ORAL_SURGERY_STATUS,
            payload: { oral_status: resp },
        });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};


export const saveOralStatus = (statusInfo : any) => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`statusSetup/save`, statusInfo);
        dispatch({type: ActionType.SAVE_ORAL_SURGERY_STATUSES, payload: resp});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
}

export const deleteStatus = (id: any) => async (
    dispatch: Function,
    getState: Function,
    api: any
) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`statusSetup/delete`, id);
        dispatch({type: ActionType.DELETE_ORAL_SURGERY_STATUSES, payload: resp});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
}