import React from "react";
import Template from "../components/Template";
import StatusSetupX from "../components/StatusSetup";

function OrthoSetup() {
    const type = "ortho"

  return (
    <Template activeLink='setup'>
        <StatusSetupX type={type}/>
    </Template>
  );

}

export default OrthoSetup;