import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";


export const get = (params: any = {}) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(`patient/insight/${JSON.stringify(params)}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


export const add = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.post(`patient/insight`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


export const update = (insight: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const payload = {
            id: insight.id,
            notes: insight.notes,
        }
        const resp = await api.put(`patient/insight`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};


