// Add {hide: true} in object to hide any menu from menu bar
import { MainMenu } from "./constants";

export const menusArray: Array<MainMenu> = [

  {
    pageId: 1,
    name: "Referrals",
    class: "innerDropdown",
    icon: "sync",
    title: "REFERRALS",
    partner: "REFERRALS",
    role: [],
    subMenus: [],
  },
  {
    pageId: 13,
    name: "referral-work",
    class: "innerDropdown",
    icon: "briefcase",
    title: "REFERRAL WORK",
    partner: "REFERRAL WORK",
    role: [],
    subMenus: [],
  },
  {
    pageId: 2,
    name: "Setup",
    class: "innerDropdown",
    icon: "setting",
    title: "SETUP",
    partner: "SETUP",
    role: [],
    subMenus: [
      {
        pageId: 3,
        name: "Ortho",
        class: "innerDropdown",
        title: "Ortho",
        role: [],
        subMenux: [
          {
            pageId: 4,
            name: "Procedure Codes Ortho",
            icon: "clipboard",
            title: "PROCEDURE CODES",
            role: [],
          },
          {
            pageId: 5,
            name: "Ortho Status Setup",
            icon: "settings",
            title: "STATUS SETUP",
            role: [],
          },
        ],
      },
      {
        pageId: 7,
        name: "Oral Surgery",
        class: "innerDropdown",
        title: "Oral Surgery",
        role: [],
        subMenux: [
          {
            pageId: 8,
            name: "Procedure Codes Oral",
            icon: "clipboard",
            title: "PROCEDURE CODES",
            role: [],
          },
          {
            pageId: 9,
            name: "Oral Surgery Status Setup",
            icon: "settings",
            title: "STATUS SETUP",
            role: [],
          },
        ],
      },
    ],
  },
  {
    pageId: 11,
    name: "Reports",
    class: "innerDropdown",
    icon: "inbox",
    title: "REPORTS",
    partner: "REPORTS",
    role: [],
    subMenus: [
      {
        pageId: 12,
        name: "Refer Out Stats",
        class: "innerDropdown",
        title: "Refer Out Stats",
        role: [],
        subMenux: [
          {
            pageId: 13,
            name: "All Refer Out Stats",
            icon: "inbox",
            title: "All Refer Out Stats",
            role: [],
          },
        ],
      },
    ],
  },
  {
    pageId: 14,
    name: "Usage",
    class: "innerDropdown",
    // @ts-ignore
    icon: "chart-pie",
    title: "USAGE",
    partner: "USAGE",
    role: [],
    subMenus: [],
  }
];
