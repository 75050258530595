import React from "react";

import { ReactComponent as Inbox } from "./svg/inbox.svg";
import { ReactComponent as Setting } from "./svg/setting.svg";
import { ReactComponent as Settings } from "./svg/settings.svg";
import { ReactComponent as Sync } from "./svg/sync.svg";
import { ReactComponent as Bell } from "./svg/bell.svg";
import { ReactComponent as ChartPie } from "./svg/chart-pie.svg";
import { ReactComponent as Clipboard } from "./svg/clipboard.svg";
import {Icon} from "semantic-ui-react";

const icons = {
    "inbox": Inbox,
    "setting": Setting,
    "settings": Settings,
    "sync": Sync,
    "bell": Bell,
    "chart-pie": ChartPie,
    "clipboard": Clipboard,
};

export const SIcon = (props: IconProps) => {
    const { name, ...rest } = props;
    const SIcon = icons[name];
    return SIcon ? <SIcon {...rest} /> : <Icon color='black' size='large' className='mr10' name={name as any}/>;
}

export type IconPropsName = keyof typeof icons;

interface IconProps extends React.SVGProps<SVGSVGElement> {
    name: IconPropsName;
}