import {ActionType} from "../../model/actionTypes";

export const loadList = (practice: any, patientNumber: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOAD_LIST});
    try {
        const result = await api.get(`xray-images/${practice}/${patientNumber}`);
        dispatch({type: ActionType.LOAD_LIST_SUCCESS, items: result, count: result.count});
        return result;
    } catch (error) {
        let errorMessage = error.message;
        if (error.statusCode === 403) {
            errorMessage = "You are not authorized to access this page";
        }
        dispatch({type: ActionType.LOAD_LIST_FAIL, error: errorMessage});
    }
};