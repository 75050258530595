import * as React from "react";
import {Modal} from "semantic-ui-react";

interface Props {
    files: any,
    closeModal: any,
    type: string
}

function ViewFileModal(props: Props) {

    let selectedFiles = (props.files && props.files !== '') ? props.files.split(',') : [];
    selectedFiles = selectedFiles.filter((file: any) => (file.toLowerCase().indexOf((props.type === 'Video' ? ".mp4" : ".pdf")) > 0));

    return (
        <Modal
            open={true}
            onClose={props.closeModal}
            aria-labelledby="ModalHeader"
            centered={false}
            closeIcon={true}
            size="large"
        >
            <Modal.Content scrolling={true}>
                <div className="row">
                    <div className="col-12 text-center">
                        {
                            (selectedFiles && selectedFiles.length) ?
                                selectedFiles.map((file: any, key: number) => {
                                    return (<div key={key}>
                                        {(file.indexOf(".mp4") > 0) ?
                                            <video className="helpVideo" controls={true}>
                                                <source src={file} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                            :
                                            <div className="helpPdf">
                                                <a className="primaryColor mb-3 displayBlock" target="_blank"
                                                   href={file}>Open in full screen</a>
                                                <iframe title={`pdf-${key}`} src={file} height={'500px'}
                                                        width={'100%'}/>
                                            </div>
                                        }
                                    </div>)
                                })
                                :
                                <div>No file found.</div>
                        }
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default ViewFileModal;
