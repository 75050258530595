import { ActionType } from "../../model/actionTypes";
import {PatientFilters, PatientValidationRun, PatientValidationSetup, MDHMconfirmation} from "../../model/patients";
import {errorHandler} from "./auth";
import moment from "moment";


export const getPatients = (params: PatientFilters, reload: Boolean,patientData:any) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/getPatients"+stringifyParams);
        if (params.keyword.length) {
            if(params.keyword.length !== 0){
                dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: resp} });
                dispatch({ type: ActionType.LOADER, payload: false });
                return;
            }else{
                dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: patientData} });
            }

        }
        dispatch(filterPatientData(params, reload, resp));
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const filterPatientData = (params: PatientFilters, reload: Boolean, patientResponse: any) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const defaultPractice = getState().patients.defaultPractice;
    const practiceNames = getState().practice.practiceNames;
    let patientData = {};
    if (reload) {
        let locations = practiceNames;
        let practices = ['all'];
        if (defaultPractice && defaultPractice.practices !== 'all') {
            locations = defaultPractice.practices.split(',');
            practices = defaultPractice.practices.split(',');
        } 
        locations.forEach((name: string) => {
            patientData = Object.assign(patientData, {[name]: patientResponse[name]})
        })
        params.locations = locations;
        params.practices = practices;
        dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: patientData, patientData: patientResponse, filters: params} });
        dispatch({ type: ActionType.LOADER, payload: false });
    } else {
        const filters = getState().patients.filters;
        const carrierList = getState().patients.carrierIdentity;
        let data = {};
        let selectedCarrierList: any = []
        filters.insurance.forEach((value: string) => {
            let dataArr = carrierList.filter((carrier: any) => carrier.modifyCarrierName.trim() === value.trim());
            selectedCarrierList = selectedCarrierList.concat(dataArr);
        })
        filters.locations.forEach((name: string) => {
            const practiceData = patientResponse[name];
            let objArr: any = []
            practiceData.forEach((row:any) => {
                    objArr.push(row);
            })
            data = Object.assign(data, {[name]: objArr})
        })
        dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: data, patientData: patientResponse} });
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
    
    return patientData
}

export const onFiltersChange = (filters: any) => async (dispatch: Function, getState: Function) => {
    let patientValidationData = getState().patients.patientData;
    const carrierList = getState().patients.carrierIdentity;
    let data = {};
    let selectedCarrierList: any = []
    filters.insurance.forEach((value: string) => {
        let dataArr = carrierList.filter((carrier: any) => carrier.modifyCarrierName.trim() === value.trim());
        selectedCarrierList = selectedCarrierList.concat(dataArr);
    })
    filters.locations.forEach((name: string) => {
        const practiceData = patientValidationData[name];
        let objArr: any = []
        practiceData.forEach((row:any) => {
            const carrierName = row.CarrierName ? row.CarrierName.toLowerCase() : ''
            if (selectedCarrierList.length) {
                const isFound = selectedCarrierList.find((carrier: any) => carrier.carrierName.toLowerCase() === carrierName || carrier.modifyCarrierName.toLowerCase() === carrierName);
                if (isFound) {
                    const item = filterWithStatus(filters, row);
                    item && objArr.push(item);
                }
            } else {
                const item = filterWithStatus(filters, row);
                item && objArr.push(item);

            }
            
        })
        data = Object.assign(data, {[name]: objArr})
    })
    dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: data, filters: filters} });
    return data;
}

const filterWithStatus = (filters: any, row: any) => {
    let returnVal = true;

    if (filters.status !== 'all') {
        if(row.status !== filters.status){
            returnVal = false;
        }
    }

    if(filters.aptStatus !== 'all'){
        if(row.APT_Status.toLowerCase() !== filters.aptStatus){
            returnVal = false;
        }
    }

    if(returnVal){
        return row;
    }
}

export const getPdfFile = (params: object) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/getPdf"+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
};

export const runValidation = (payload : PatientValidationRun) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("patientValidation/runValidation", payload);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const runAllValidation = (data : any) => async (dispatch: Function, getState: Function, api: any) => {
    await api.post("patientValidation/runAllValidation", data);
    return;
};

export const  runVerifyInsValidation= (data:any, patientData: any) => async (dispatch: Function, getState: Function, api: any) => {
    
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.put("patientValidation/runVerifyValidation", data);
        dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: Object.assign({}, patientData)} });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const insuranceCategory = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/insuranceCategory");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getCarrierIdentity = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp =  await api.get("patientValidation/carrierIdentity");
        dispatch({type: ActionType.CARRIER_IDENTITY, payload: {carrierIdentity: resp}})
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch(err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const addMDHMconfirmation = (payload : MDHMconfirmation, isPTValidation:boolean) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp =  await api.post("patientValidation/addMDHMconfirmation", payload);
        if (isPTValidation) {
            if (payload.type === 'error') {
                let patientData = getState().patients.filteredPatientData;
                const user = getState().auth.user;
                const practiceData = patientData[payload.practice];
                const index = practiceData.findIndex((item: any) => item.PatNum === payload.patNum);
                if (index >= 0) {
                    const row = practiceData[index];
                    let mdhm = row.MDHM;
                    mdhm.description = payload.description;
                    mdhm.date = payload.date;
                    mdhm.status = payload.status;
                    mdhm.users = {id: user.id, firstName: user.firstname, lastName: user.lastname, email: user.email}
                    practiceData.slice(index, 1, mdhm);
                    patientData = Object.assign(patientData, {[payload.practice]: practiceData})
                    dispatch({
                        type: ActionType.PATIENTS_VALIDATION,
                        payload: {filteredPatientData: Object.assign({}, patientData)}
                    });
                }
            }
            if (payload.type === 'home') {
                let patientData = getState().patients.filteredPatientData;
                const user = getState().auth.user;
                const practiceData = patientData[payload.practice];
                const index = practiceData.findIndex((item: any) => item.PatNum === payload.patNum);
                if (index >= 0) {
                    const row = practiceData[index];
                    let home = row.home;
                    home.communicationMode = payload.communicationMode
                    home.description = payload.description;
                    home.date = payload.date;
                    home.users = {id: user.id, firstName: user.firstname, lastName: user.lastname, email: user.email}
                    practiceData.slice(index, 1, home);
                    patientData = Object.assign(patientData, {[payload.practice]: practiceData})
                    dispatch({
                        type: ActionType.PATIENTS_VALIDATION,
                        payload: {filteredPatientData: Object.assign({}, patientData)}
                    });
                }
            }
        }
        return resp;
    } catch(err) {
        return dispatch(errorHandler(err));
    }
}

export const getMDHMconfirmation = (params: object) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    try {
        const resp =  await api.get("patientValidation/getMDHMconfirmation"+stringifyParams);
        dispatch({ type: ActionType.MDHM_CONFIRMATION, payload: resp });
        return resp;
    } catch(err) {
        return dispatch(errorHandler(err));
    }
}

export const getErrorReport = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    let stringifyParams;
    if(params){
        params.startDate = moment(params.date).clone().startOf('month').format('YYYY-MM-DD');
        params.endDate = moment(params.date).clone().endOf('month').format('YYYY-MM-DD');
        stringifyParams = '/'+JSON.stringify(params);
        delete params.date
    }
    try {
        const resp =  await api.get("patientValidation/getErrorReport"+stringifyParams);
        dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_ERROR_REPORT, payload: {patientValidationErrorReport: resp} });
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch(err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
        
    }
}