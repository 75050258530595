import { Provider } from "../../model/provider";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:Provider = {
    providerList: [],
}
export const provider = createReducer(initialState, {
    [ActionType.FETCH_PROVIDER](state: Provider, action: Action<Provider>) {
        return Object.assign({...state}, action.payload);
    },
});
