import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as PCOralAction from "../redux/actions/procedureCodes";
import {useActions} from "../redux/actions";
import {ProcedureCodeLists, ProcedureCode, ProcedureCodeDetails} from "../model/procedureCodeList";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Icon, Input} from "semantic-ui-react";
import DynamicHtmlTable from "./DynamicHtmlTable";
import AddProcedureCodeListOral from "./AddProcedureCodeListOral";
import PopUpModal from "./PopUpModal";
import _ from "lodash";
import {HELP_PAGE} from "../constants";
import HelpMark from "./Help/HelpMark";


function ProcedureCodeList(props: ProcedureCodeLists) {
    const ProcedureCodesAction = useActions(PCOralAction);
    const initialState: ProcedureCode = {
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        column:-1,
        direction:'sort',
        searchKeyword:''
    };
    const initialFormState:ProcedureCodeDetails ={procedureCode: '', description: ''};
    const [state, setStates] = useState(initialState);
    const [formState, setFormStates] = useState({showForm:false, id:0});
    const [initialValue, setFormInitialStates] = useState(initialFormState);
    const [deletePopUpState, setDeletePopUpState]= useState({showPopUp:false, id:0});
    let timeout:any=0
    const [rawData,setRawData]=useState<ProcedureCodeDetails[
    ] | []>([])
    const [codeExistErrorMessage,setCodeExistErrorMessage]=useState<{message:string,set:boolean}>({message:'',set:false})
    useEffect(() => {
       getProcedureCodeDetails();
    }, []);
    const [allProcedureCodes,setAllProcedureCodes]=useState([])


    const getProcedureCodeDetails = () => {
        ProcedureCodesAction.getProcedureCodeListDetails().then((res1:any)=>{
            setAllProcedureCodes(res1)
             ProcedureCodesAction.fetchProcedureCodeList().then((res:[]) => {
            if(res && res.length) {
                const modRes=res.map((item:any)=>{

                    const result:any=_.find(res1,function(obj:any){
                        if(obj.procedureCode.includes(item.procedureCode))
                            return true
                        return false
                    })

                    const abbrDesc=typeof result==="undefined"?"":result.abbrDesc
                    const procedureCategory=typeof result==="undefined"?"":result["OD ProcCat"]
                    return {
                        ...item,
                        abbrDesc,
                        procedureCategory
                    }
                })
                const TableData = pushTableData(modRes as []);
                const TableHead = TableHeadData();
                setRawData(res)
                setStates({...state, TableData, TableHead});
            }
        });
        })
    };

    const onCancel = ()=>{
        setFormStates( {showForm:false, id:0});
        setFormInitialStates(initialFormState);
    };

    const saveProcedureCodeDetails = (values:ProcedureCodeDetails)=>{
        if(formState.id) {
            values.id=formState.id;
            ProcedureCodesAction.updateProcedureCodeDetails(values).then((res:[])=> {
                getProcedureCodeDetails();
            });
        } else {
            values.type = 'oral'
            const foundRecord=rawData.some(data=>data.procedureCode===values.procedureCode)
            if(foundRecord){
                setCodeExistErrorMessage({
                    message:`This Procedure code ${values.procedureCode} already exist`,
                    set:true
                })
                return 
            }
            ProcedureCodesAction.saveProcedureCodeDetails(values).then((res:[])=>{
                getProcedureCodeDetails();
            });
        }
    };


    const editList = (item:ProcedureCodeDetails)=>{
        setFormStates({showForm:true, id:item.id||0});
        setFormInitialStates({procedureCode: item.procedureCode, description: item.description})
    };

    const deleteList = (id:number)=>{
        setDeletePopUpState({showPopUp:true, id:id});
    };

    const deletePopUpHandler = ()=>{
        ProcedureCodesAction.deleteProcedureCodeDetails({id:deletePopUpState.id}).then((res:[])=> {
            getProcedureCodeDetails();
        });
        setDeletePopUpState({showPopUp:false, id:0});
    };

    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Procedure Code'};
        TableHead[1]={title:'Abbr Desc'}
        TableHead[2] = {title: 'Description'}
        TableHead[3]={title:'Procedure Category'}
        TableHead[4] = {title: 'Edit/Delete'}
        return TableHead;
    };

    const pushTableData = (procedureCodeDetails:[]) => {
        const TableData = new Array();
        procedureCodeDetails.forEach((item: any) => {            
                const editButton=<><Icon onClick={()=>editList(item)} className='mr10' name='edit' /><Icon onClick={()=>deleteList(item.id)} name='delete' /></>
                TableData.push([
                    {title: item.procedureCode},
                    {title:item.abbrDesc},
                    {title: item.description},
                    {title:item.procedureCategory},
                    {title: editButton}
                ])
            });
        return TableData;
    };

    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const data = rawData && rawData.filter((element: ProcedureCodeDetails) => {
            return (element.procedureCode!.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
              || element.description!.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
            )
          });
          const TableData = pushTableData(data as []);
          setStates({...state, searchKeyword: searchKeyword, TableData: TableData});
          clearTimeout(timeout);
        }, 500);
      };
    const handleSort=(clickedColumn:number)=>()=>{
        let {column,TableData,direction}=state
        if(column !==clickedColumn){
            column=clickedColumn
            TableData=_.sortBy(TableData,o=>o[clickedColumn].title)
            direction='sort ascending'
            setStates({...state,TableData:TableData,column,direction})
            return
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    }
    return (
        <Template activeLink='ProcedureCodeList'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        Oral Surgery Procedure Code List ({state.TableData.length})
                        <HelpMark pageId={HELP_PAGE.REFERRALS_SETUP_ORAL_PROC_CODE}/>
                    </h2>
                    <p/>
                </div>
                <div className="content pb0">
                <Input className='mb10' icon='search' placeholder="Search" onChange={onSearchChange}/>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    { !formState.showForm &&
                        <Button primary onClick={() => setFormStates({...formState, showForm:true})}>Add Codes</Button>
                    }
                    {(formState.showForm) &&
                        <AddProcedureCodeListOral initialValue={initialValue}
                                      onCancel={onCancel} saveProcedureCodeDetails={saveProcedureCodeDetails}
                                      procedureCodeOptions={allProcedureCodes.map((item:any)=>{return {value:item.procedureCode,text:item.procedureCode,key:item.procedureCode}})}
                                      allProcedureCodes={allProcedureCodes}
                                      />
                    }
                </div>
            </div>
            <div className="table-adjAcknowledgement">

            <DynamicHtmlTable
                key={0}
                className={''}
                tableHead={state.TableHead}
                heading={""}
                tableData={state.TableData}
                onHeadClick={handleSort}
                sortedColumn={state.column}
                direction={state.direction}
            />
            </div>
            { deletePopUpState.showPopUp &&
                <PopUpModal headerText="Delete Selected Record" message="Are you sure you want to delete?" size='mini' hidePopUpModal={()=>setDeletePopUpState({showPopUp:false, id:0})} deletePopUpHandler={deletePopUpHandler} showLabel={true} action='delete'/>
            }
            {
                codeExistErrorMessage.set&&
                <PopUpModal headerText="Existing Procedure Code" message={codeExistErrorMessage.message} size="mini" hidePopUpModal={()=>{
                    setCodeExistErrorMessage({message:'',set:false})
                }}
                deleteOptionsHander={()=>{}} action=""
                />
            }
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        procedureCodes: state.procedureCodes,
    };
}
export default connect(mapStateToProps)(ProcedureCodeList);

