import { ActionType } from "../../model/actionTypes";
import {SendReportAction,SendReportOSAction,ReloadBudgetAction,SendReportPVSAction,SendReportPVAAction} from "../../model/report";
import {errorHandler} from "./auth";
import {AdjustmentFilters} from "../../model/adjustment";
import { isBlankNullOrUndefined } from "../../utils/common";

export const fetchReportData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCare${params}`);
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: response } });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchReportPerPracticeData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCarePerPractice${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_PER_PRACTICE, payload: { reportPerPracticeData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_PER_PRACTICE, payload: { reportPerPracticeData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchReportDataLastMonths = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCareLastMonths${params}`);
		dispatch({ type: ActionType.FETCH_REPORT_LAST_MONTHS, payload: { reportDataLastMonths: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_LAST_MONTHS, payload: { reportDataLastMonths: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchScheduleData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/schedule${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SCHEDULE, payload: { scheduleData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SCHEDULE, payload: { scheduleData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchBudgetData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/budget${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET, payload: { budgetData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET, payload: { budgetData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchBudgetDataPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/budgetPerPractice${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PER_PRACTICE, payload: { budgetDataPerPractice: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PER_PRACTICE, payload: { budgetDataPerPractice: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchAptData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/apt${params}`);
		dispatch({ type: ActionType.FETCH_APT, payload: { aptData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_APT, payload: { aptData: [] } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchClinicalScheduleData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.get(`report/clinicalSchedule/${params}`);
        dispatch({ type: ActionType.FETCH_CLINICAL_SCHEDULE, payload: { clinicalScheduleData: response.data } });
        dispatch({ type: ActionType.LOADER, payload: false });
        return response.data;
    } catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			dispatch({ type: ActionType.FETCH_CLINICAL_SCHEDULE, payload: { clinicalScheduleData: [] } });
		  return dispatch(errorHandler(err));
    }
};

export const sendReportData:SendReportAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        await api.post(`report/sendEmail`, params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return true;
    } catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			return dispatch(errorHandler(err));
    }
};

export const sendMailCSR = (practice: string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const userId = getState().auth.user.id;
		await api.get(`report/sendMailCSR/${practice}/${userId}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
export const sendMailOSR:SendReportOSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailOSR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailNewOSR:SendReportOSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailNewOSR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};




export const fetchOSPCRData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/openSurgeryPCRReports/${params}`);
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRData: [] } });
		return dispatch(errorHandler(err));
	}
};



export const fetchUsage = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/usage/All`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveUsagePTV = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("report/ptv/saveUsage", usage);
		dispatch({ type: ActionType.SAVE_USAGE_PTV, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const fetchUsagePTV = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/ptv/usage/All`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
//WoPTV-----------------------------------------------------------------------------------------
export const fetchUsageWO = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get("report/wo/usage"+stringifyParams);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};


export const hideUsagePTV = (userId: number, hideUsage: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/ptv/hide/usage`, {userId, hideUsage});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const flushUsageDataPTV = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/ptv/flush/usage`, {userId});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};






export const hideUsage = (userId: number, hideUsage: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/hide/usage`, {userId, hideUsage});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const flushUsageData = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/flush/usage`, {userId});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const reloadBudgetData:ReloadBudgetAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {

		const response = await api.post(`report/budget/osBudget`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getPatientValidationReport = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	let stringifyParams;
	if(params){
		stringifyParams = JSON.stringify(params)
	}
	try {
		const response = await api.get(`patientValidation/patientValidationActionReport/${stringifyParams}`);
		dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_REPORT, payload: { patientValidationData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_REPORT, payload: { patientValidationData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const sendMailPVS:SendReportPVSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPVS`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailPVA:SendReportPVAAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPVA`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const mdhmWriteOffReport = (params: AdjustmentFilters) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("report/mdhmReport"+stringifyParams);
		dispatch({ type: ActionType.MDHM_WRITE_OFF_REPORT, payload: { mdhWriteOffReport: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const mdhDWriteOffDReport = (params: any) => async (dispatch: Function, getState: Function, api: any) => {

	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("report/mdhmDReport"+stringifyParams);
		dispatch({ type: ActionType.MDHM_WRITE_OFF_D_REPORT, payload: { mdhWriteOffDReport: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchUserMatrix = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get('report/userMatrix'+stringifyParams);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchBudgetDataPeriodical = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	let stringifyParams = '/'+JSON.stringify(params);
	try {
		const response = await api.get("report/budget/selectday"+stringifyParams);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PERIODICAL, payload: false});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PERIODICAL, payload: false});
		return dispatch(errorHandler(err));
	}
};

export const fetchReferOutStats = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	try {
		const response = await api.get('report/getReferOutStats' + stringifyParams);
		dispatch({ type: ActionType.FETCH_REFER_OUT_STATS, payload: { referOutStats: response }});
		return response;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const saveProviderList = (providerList:any) => async (dispatch: Function, getState: Function, api: any) => {
	
	if(providerList.length > 0) {
		dispatch({ type: ActionType.FETCH_PROVIDER_LIST, payload: { providerList: providerList }});
	} 
};

export const sendReferStatsReportDetails = (filterData: {}) => async (dispatch: Function, getState: Function, api: any) => {
	if(!isBlankNullOrUndefined(filterData)){
		
		dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("report/sendEmailReferStats", filterData);
		dispatch({ type: ActionType.SEND_EMAIL_REFER_STATS });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
	}
	
};





