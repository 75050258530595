import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";


export const saveSetup = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post("userSetup/save", payload);
        dispatch({ type: ActionType.DEFAULT_PRACTICES, payload: payload});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getSetup = (params?: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
         params.userId = getState().auth.user.id;
        let resp = await api.get(`userSetup/get/${JSON.stringify(params)}`);
        if (!resp) {
            resp = {
                value: 'all',
                userId: params.userId
            }
        }
        dispatch({ type: ActionType.DEFAULT_PRACTICES, payload: resp});
        dispatch({type: ActionType.LOADER, payload: false});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}