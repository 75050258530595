import React, { Component, useEffect, useState } from 'react'
import { Dropdown, Popup, Table, Form, Button, Icon, Input } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import moment from 'moment'
import _ from 'lodash'
import { PracticeList } from '../model/practice';
import { useActions } from "../redux/actions";
import * as referralActions from "../redux/actions/referrals";
import { ReferalExt } from '../model/referral';
import * as partnersActions from "../redux/actions/partners";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import PatientInsight from "./PatientInsight";
import PatientCallLog from "./PatientCallLog";
import { StatusType } from '../model/StatusType';
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {aptStatus} from "../constants"
import { capitalizeFirstLetter } from '../utils/common';

interface Props {
    detailsModalType: any
    detailsModal: any
    reportData: any
    eventClick: any
    practiceList: any
    openFamilyView?: Function
    statuses: any
    auth:any
}

interface SetupData {
    confirm: boolean;
    deleteId: any;
    type: any;
    editItem: any;
    column: any;
    data: any;
    direction: any;
    changeArray: any[];
};



const ReferralOutTableData: React.FC<Props> = (props) => {
    let initialState: SetupData = {
        data: props.reportData,
        confirm: false,
        deleteId: false,
        type: false,
        editItem: false,
        column: null,
        direction: null,
        changeArray: [],
    }
    const [state, setStates] = useState(initialState);
    const referralAction = useActions(referralActions);
    const StatusActions = useActions(statusActions);
    const [oralSurgeryStatus, setOralSurgeryStatus] = useState({});

    useEffect(() => {
        StatusActions.fetchOralSurgeryStatus().then((res: any) => {
            setOralSurgeryStatus(res);
        })
    },[]);

    let changeMachine: any[] = []
    const gotoUDR = (item: any) => {
        const { Practice, PatNum } = item;
        if (props.openFamilyView)
            props.openFamilyView(PatNum, Practice, Practice)
    }

    const gotoRefIn = (item: any, practice: number) => {
        const { refInPractice, refInPatId} = item;

        if (props.openFamilyView){
            if (practice === 1){
                props.openFamilyView(refInPatId, refInPractice, refInPractice)
            }
            
        }
            
    }
    
    const handleSort = (clickedColumn: any) => () => {
        const { column, data, direction } = state;
        if (column !== clickedColumn) {

            setStates({
                ...state,
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        setStates({
            ...state,
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };


    const trimString = (str: any, len: any, addDots: any) => {
        return (
            (str && str.length > len) ?
                (addDots !== false ?
                    str.substring(0, len - 3) :
                    str.substring(0, len - 3) + '...') :
                str);
    }

    const onChange = (e: any) => {
        let editItem = state.editItem;
        editItem[e.target.name] = e.target.value;
        setStates({ ...state, editItem });
    };

    /** @param {string} value */
    const onChangeDrop = (e: any, a: any) => {
        let editItem = state.editItem;
        editItem[a.name] = a.value;
        setStates({ ...state, editItem });
    };

    const onChangeDate = (event: any, data: any) => {
        let editItem = state.editItem;
        editItem[event] = data;
        setStates({ ...state, editItem });
    }


    const submitEdit = () => {
        let obj: ReferalExt = {
            PatNum_Date: state.editItem.PatNum_Date,
            speciality: state.editItem.speciality === undefined || state.editItem.speciality === null ? '' : state.editItem.speciality,
            refOutPractice: state.editItem.refOutPractice === undefined || state.editItem.refOutPractice === null ? '' : state.editItem.refOutPractice,
            refOutDate: state.editItem.refOutDate === undefined || state.editItem.refOutDate === null ? '' : state.editItem.refOutDate,
            refOutPatId: state.editItem.refOutPatId === undefined || state.editItem.refOutPatId === null ? '' : state.editItem.refOutPatId,
            refInPractice: state.editItem.refInPractice === undefined || state.editItem.refInPractice === null ? '' : state.editItem.refInPractice,
            refInPatId: state.editItem.refInPatId === undefined || state.editItem.refInPatId === null ? '' : state.editItem.refInPatId,
            aptDate: state.editItem.aptDate === undefined || state.editItem.aptDate === null ? '' : state.editItem.aptDate,
            aptStatus: state.editItem.aptStatus === undefined || state.editItem.aptStatus === null ? '' : state.editItem.aptStatus,
            callLog: state.editItem.callLog === undefined || state.editItem.callLog === null ? '' : state.editItem.callLog,
            insights: state.editItem.insights === undefined || state.editItem.insights === null ? '' : state.editItem.insights,
            care: state.editItem.care === undefined || state.editItem.care === null ? 0 : state.editItem.care,
            statusId: state.editItem.statusId === undefined || state.editItem.statusId === null ? '' : state.editItem.statusId
        };

        referralAction.saveReferalsExt(obj)
        changeMachine.push(obj);
        setStates({ ...state, editItem: false, changeArray: changeMachine });
    };

    const handleEdit = (item: any) => {

        const regExp = /[a-sA-S]/g;
        if(regExp.test(item.refOutDate)){
            item.refOutDate = new Date(moment().toString())
            setStates({ ...state, editItem: item });
        } else {
            item.refOutDate = new Date(moment(item.refOutDate).toString())
            setStates({ ...state, editItem: item });
        }
        setStates({ ...state, editItem: item });
    };

    const practiceOptionsEdit = props.practiceList.map((practice: any) => ({
        key: practice.id,
        value: practice.practice,
        text: practice.practice,
    }));

    const submitChange = (editItem:any) => {
        let updateObj = {} as any;
        const fields = ['PatNum_Date', 'speciality', 'refOutPractice', 'refOutDate', 'refInPractice', 'refOutDate',
            'aptStatus', 'callLog', 'insights', 'care', 'refInPatId', 'statusId'];
        fields.forEach((field: string) => updateObj[field] = editItem[field] !== null 
        && editItem[field] !== '' ? editItem[field] : undefined);
        referralAction.saveReferalsExt(updateObj);
        setStates({...state, editItem: null});
    }

    const updateUserInfo = (patientCallLog:any, dataKey:number,PatNum_Date:string, stateLog:any) => {
        const {data} = state;
        let editItem:any = Object.keys(data).find((x: any) => x.PatNum_Date === PatNum_Date);
        const StatusId = Object.assign({}, editItem);
        editItem.key = dataKey;
        editItem.user = props.auth.user.username;
        editItem.note = patientCallLog.notes;
        editItem.refInPatId = stateLog.referInPatientNumber;
        editItem.statusId = stateLog.statusId;
        editItem.refInPractice = stateLog.refInPractice
        setStates({...state, editItem})
        if(StatusId.statusId !== editItem.statusId || StatusId.refInPractice !== editItem.refInPractice){
            submitChange(editItem);
        }
    }

    const getStatusX = (id: number, type: string) => {
        let stat = {
            statusColor: '',
            referStatus: '',
            type: '',
            sequence: ''
        };
        let defaultStat = {
            statusColor: '',
            referStatus: '',
            type: '',
            sequence: ''
        }

        if(type = 'refIn') {
            Object.values(oralSurgeryStatus).forEach((item: any, key) => {
                if(item.type === "oral" && item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started") {
                    defaultStat = item;
                }
                if(item.type === "ortho" && item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started"){
                    defaultStat = item;
                }
                if(item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started"){
                    defaultStat = item;
                }
    
                if(item.id === id) {
                    stat = item
                }
            })
        } else {
            aptStatus.forEach((statusType: StatusType) => {
                if(statusType.id === 0) {
                    //defaultStat = statusType;
                    defaultStat.referStatus = statusType.referStatus;
                    defaultStat.statusColor = statusType.statusColor;

                }
                if(statusType.id === id) {
                    stat.referStatus = statusType.referStatus;
                    stat.statusColor = statusType.statusColor;
                }
            }); 
        }

        
        if(id === undefined || id === null) {
            return (
                <div
                    className="roundedButton"
                    style={{background: defaultStat ? defaultStat.statusColor : ''}}
                >
                    <div className="roundedButtonText">{defaultStat.referStatus}</div>
                </div>
            )
        }
        else {
            return (
                <div
                    className="roundedButton"
                    style={{background: stat ? stat.statusColor : ''}}
                >
                    <div className="roundedButtonText">{stat.referStatus}</div>
                </div>
            )
        }
    }

    const dropDownStatus = (type: any) => {
        let stats: any = [];
        Object.values(oralSurgeryStatus).forEach((item: any, key) => {
            if(item.type === type) {
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus
                })
            }
            else if (item.type === type) {
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus
                })
            }
         if(type=='n/a' && typeof(item.type)==='string' && item.referStatus!==null){
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus
                })
            }
        });
        return stats;
    }

    const getHeader = (detailsModal: any, eventClick: any) => {
        const { column, direction } = state;
        const direction2 = direction ? 'down' : 'up';
        if (detailsModal === 'report') {
            return (
                <tr>
                    <th className={`cursorPointer ${column === 'practice' ? 'active' : ''}`} onClick={handleSort('practice')}>
                        <div>
                            Ref-Out Practice
                            <i className={`sort amount ${direction2} icon ml-05`}></i>
                        </div>
                    </th>
                    <th className={`cursorPointer ${column === 'patientId' ? 'active' : ''}`} onClick={handleSort('patientId')}>
                        Ref-Out Patient #
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'practiceI' ? 'active' : ''}`} onClick={handleSort('refInPractice')}>
                        Ref-In Practice
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'patientIdI' ? 'active' : ''}`} onClick={handleSort('patientIdI')}>
                        Ref-In Patient #
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'statusId' ? 'active' : ''}`} onClick={handleSort('status')}>
                        Status
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th>Action</th>
                </tr>
            );
        } else {
            return (
                <tr>
                    <th className={`cursorPointer ${column === 'practice' ? 'active' : ''}`} onClick={handleSort('practice')}>
                        <div>
                            Ref-Out Practice
                            <i className={`sort amount ${direction2} icon ml-05`}></i>
                        </div>
                    </th>
                    <th className={`cursorPointer ${column === 'patientId' ? 'active' : ''}`} onClick={handleSort('patientId')}>
                        Ref-Out Patient #
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'practiceI' ? 'active' : ''}`} onClick={handleSort('refInPractice')}>
                        Ref-In Practice
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'patientIdI' ? 'active' : ''}`} onClick={handleSort('patientIdI')}>
                        Ref-In Patient #
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th style={{ minWidth: '150px' }} className={`cursorPointer ${column === 'statusId' ? 'active' : ''}`} onClick={handleSort('status')}>
                        Status
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                    </th>
                    <th>
                        Speciality
                    </th>
                    <th>Send Date</th>
                    <th>Apt Date</th>
                    <th className={`cursorPointer ${column === 'status' ? 'active' : ''}`} onClick={handleSort('aptStatus')}>
                        Apt Status
                        <i className={`sort amount ${direction2} icon ml-05`}></i>
                        </th>
                    <th>Procedure Category</th>
                    <th>Call Log</th>
                    <th>Insights</th>
                    <th>Care</th>
                    <th>External Referral</th>
                    <th>Action</th>
                </tr>
            );
        }
    }

    const getTableRows = (detailsModal: any, reportData: any, eventClick: any) => {
        const { data } = state;
        const { detailsModalType } = props

        let returnArray: any = [];
        let totalPrice = 0;
        let uniKey = 0;


        if (detailsModal === 'report') {

            if (Object.keys(data).length) {
                data.map((item: any, key: number) => {
                    uniKey++;
                    let ct_total = '$' + (item.ContractTotal ? item.ContractTotal.toFixed(2) : '0.00');
                    let discount = '$' + (item.TreatmentDiscount ? item.TreatmentDiscount.toFixed(2) : '0.00');
                    ct_total = ct_total.replace('$-', '-$');
                    discount = discount.replace('$-', '-$');
                    returnArray.push(
                        <Table.Row key={key}>
                            <Table.Cell>{item.practice}</Table.Cell>
                            <Table.Cell className="primaryColor cursorPointer" onClick={() => gotoUDR(item)}>{item.patientId}</Table.Cell>
                            <Table.Cell>
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ? <Form.Field>
                                    <Dropdown style={{ minWidth: '70px' }} search options={
                                        props.practiceList
                                            ? [{
                                                key: 'All',
                                                value: 'All',
                                                text: 'All'
                                            }, ...props.practiceList.map((practice: PracticeList) => {
                                                return {
                                                    key: practice.id,
                                                    value: practice.practice,
                                                    text: practice.practice,
                                                };
                                            })]
                                            : []
                                    } name='refInPractice' fluid single selection placeholder='Select Practice'
                                        onChange={(e, a) => onChangeDrop(e, a)} defaultValue={state.editItem.refInPractice} />
                                </Form.Field> : item.refInPractice}
                            </Table.Cell>
                            <Table.Cell>
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ? <Form.Field>
                                    <Input style={{ minWidth: '70px' }} name='refInPatId' fluid placeholder='Patient #'
                                        onChange={(e) => onChange(e)} defaultValue={state.editItem.refInPatId} />
                                </Form.Field> : <div className="primaryColor cursorPointer" onClick={() => gotoRefIn(item, 1)}>{item.refInPatId}</div>}
                            </Table.Cell>
                            <Table.Cell className="statusSetupCell">
                                {
                                    state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ?
                                        <Dropdown
                                            search
                                            className='mr10 mb15'
                                            name="statusId"
                                            placeholder="Status"
                                            selection
                                            options={dropDownStatus(item.speciality)}
                                            onChange={(e, a) => onChangeDrop(e, a)}
                                        /> : getStatusX(item.statusId, 'refIn')
                                }
                            </Table.Cell>
                            <Table.Cell className="pb0">
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ?
                                    <span><Button primary className="mr10 mb10" onClick={submitEdit}>Update</Button>
                                        <Button secondary className="mb10"
                                            onClick={() => setStates({ ...state, editItem: false })}>Cancel</Button></span>
                                    : <span className="mb10 float-left"><Icon name='edit' className="mr15 cursorPointer"
                                        onClick={() => handleEdit(item)} /></span>}
                            </Table.Cell>
                        </Table.Row>
                    );
                });

                return <tbody>{returnArray}</tbody>
            } else {
                return (
                    <tbody>
                        <tr>
                            <td colSpan={7}>No Record Found</td>
                        </tr>
                    </tbody>
                );
            }
        } else {
            if (Object.keys(data).length) {
                let price = '';
                let pp_balance = '';
                let pp_due = '';
                let ct_total = '';
                let discount = '';
                
                data.map((item: any, key: number) => {
                    totalPrice += item.price;
                    uniKey++;
                    price = '$' + item.price.toFixed(2);
                    pp_balance = '$' + (item.PP_Balance ? item.PP_Balance.toFixed(2) : '0.00');
                    ct_total = '$' + (item.ContractTotal ? item.ContractTotal.toFixed(2) : '0.00');
                    discount = '$' + (item.TreatmentDiscount ? item.TreatmentDiscount.toFixed(2) : '0.00');
                    pp_due = '$' + (item.Current_Balance ? item.Current_Balance.toFixed(2) : '0.00');
                    pp_balance = pp_balance.replace('$-', '-$');
                    ct_total = ct_total.replace('$-', '-$');
                    discount = discount.replace('$-', '-$');
                    pp_due = pp_due.replace('$-', '-$');
                    const options = dropDownStatus(item.speciality)

                    returnArray.push(
                        <tr key={key}>
                            <Table.Cell className="table-col-sticky">{capitalizeFirstLetter(item.practice)}</Table.Cell>
                            <Table.Cell>{item.patientId}</Table.Cell>
                            <Table.Cell>
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ? <Form.Field>
                                    <Dropdown style={{ minWidth: '70px' }} options={
                                        props.practiceList
                                            ? [{
                                                key: 'All',
                                                value: 'All',
                                                text: 'All'
                                            }, ...props.practiceList.map((practice: PracticeList) => {
                                                return {
                                                    key: practice.id,
                                                    value: practice.practice,
                                                    text: practice.practice,
                                                };
                                            })]
                                            : []
                                    } name='refInPractice' fluid single selection placeholder='Select Practice'
                                        onChange={(e, a) => onChangeDrop(e, a)} defaultValue={state.editItem.refInPractice} />
                                </Form.Field> : item.refInPractice}
                            </Table.Cell>

                            <Table.Cell>
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ? <Form.Field>
                                    <Input style={{ minWidth: '70px' }} name='refInPatId' fluid placeholder='Patient #'
                                        onChange={(e) => onChange(e)} defaultValue={state.editItem.refInPatId} />
                                </Form.Field> : item.refInPatId}
                            </Table.Cell>
                            <Table.Cell className="statusSetupCell">
                                {
                                    state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ?
                                        <Dropdown
                                            search
                                            className='mr10 mb15'
                                            name="statusId"
                                            placeholder="Status"
                                            selection
                                            options={dropDownStatus(item.speciality)}
                                            onChange={(e, a) => onChangeDrop(e, a)}
                                        /> : getStatusX(item.statusId, 'refIn')
                                }
                            </Table.Cell>
                            {item.speciality === 'oral' ? <Table.Cell>Oral Surgery</Table.Cell> : <Table.Cell>Ortho</Table.Cell>}
                            <Table.Cell>{item.dateOfService}</Table.Cell>
                            <Table.Cell>
                                {
                                    state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ?
                                        <Form>
                                            <Form.Field className='mw-120'>
                                                <DatePicker
                                                    selected={state.editItem.refOutDate}
                                                    dateFormat='yyyy-MM-dd'
                                                    onChange={(date) => onChangeDate('refOutDate', date)}
                                                />
                                            </Form.Field>
                                        </Form> :
                                        item.refOutDate !== null && item.refOutDate !== undefined ? moment(item.refOutDate).format("MM-DD-YYYY") : item.refOutDate
                                }
                            </Table.Cell>
                            <Table.Cell className="statusSetupCell">
                                {getStatusX(item.aptStatus, 'refOut')}
                            </Table.Cell>
                            <Table.Cell>{data.procat}</Table.Cell>
                            <Table.Cell><PatientCallLog patientId={item.PatNum} refInPatId={item.refInPatId} practice={item.Practice}
                                speciality={item.speciality}
                                updateUserInfo={updateUserInfo} dropDownStatus={options} practiceOptionsEdit={practiceOptionsEdit} statusId={item.statusId}
                                dataKey={key} PatNum_Date={item.PatNum_Date} refInPractice={item.refInPractice} /></Table.Cell>
                            <Table.Cell><PatientInsight patientId={item.PatNum} practice={item.Practice} /></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>{item.External_Referral !== null ? item.External_Referral : ''}</Table.Cell>
                            <Table.Cell className="pb0">
                                {state.editItem && state.editItem.PatNum_Date === item.PatNum_Date ?
                                    <span><Button primary className="mr10 mb10" onClick={submitEdit}>Update</Button>
                                        <Button secondary className="mb10"
                                            onClick={() => setStates({ ...state, editItem: false })}>Cancel</Button></span>
                                    : <span className="mb10 float-left"><Icon name='edit' className="mr15 cursorPointer"
                                        onClick={() => handleEdit(item)} /></span>}
                            </Table.Cell>
                        </tr>
                    );
                });
                return <tbody>{returnArray}</tbody>
            } else {
                return (
                    <tbody>
                        <tr>
                            <td colSpan={15}>No Record Found</td>
                        </tr>
                    </tbody>
                );
            }
        }
    }


    const { reportData, detailsModal, eventClick } = props;

    return (
        <div className="table-responsive FreezeTable">

            <Table sortable className="table tableCustomStyle mb0">
                <thead>
                    {getHeader(detailsModal, eventClick)}
                </thead>
                {reportData && getTableRows(detailsModal, reportData, eventClick)}
            </Table>
        </div>
    );

}

export default ReferralOutTableData;