import React from "react";
import {Button, Modal, Icon} from "semantic-ui-react";

function CookieModal (props: any) {

  return (
              <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
          <Modal.Header>Cookies are disabled in browser window.</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <p>
                it seems like Cookies are disable on your browser. Please enable by clicking on the
                EYE <Icon name={"eye slash"}></Icon> on the top right corner of the browser window OR by going into the settings.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
                content="OK"
                primary={true}
                onClick={() => props.handleClose()}
            />
          </Modal.Actions>
        </Modal>
    );
}

export default CookieModal;
