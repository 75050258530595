import React, {useState} from "react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as patientHistoryAction from "../../redux/actions/patientInsight";
import {Button, Form, Icon, Input, Modal, TextArea, TextAreaProps} from "semantic-ui-react";

interface SavePatientHistoryProps {
    patientInsight: any;
    onSaved: Function;
}

const SavePatientInsight = (props: SavePatientHistoryProps) => {
    const actions = useActions(patientHistoryAction);
    const initialState: any = {
        showModal: false,
        patientInsight: props.patientInsight || {} as any
    };
    const [state, setState] = useState(initialState);

    const isEdit = props.patientInsight && props.patientInsight.id;

    const onOpen = (showModal: boolean) => {
        setState({...state, showModal});
    };

    const onSave = async () => {
        if (isEdit)
            await actions.update(state.patientInsight);
        else
            await actions.add(state.patientInsight);
        onOpen(false);
        props.onSaved()
    }

    const onFieldUpdate = async (e: any, data: TextAreaProps) => {
        const {patientInsight} = state;
        patientInsight.notes = data.value;
        setState({...state, patientInsight});
    }

    return (<div className='d-inline-block'>
        <Button onClick={() => onOpen(true)} secondary={isEdit} primary={!isEdit}>
            <Icon className='text-white' name='edit'/>&nbsp;
            {isEdit ? 'Edit' : `Add`}
        </Button>
        {
            state.showModal &&
            <Modal open={state.showModal} onClose={() => onOpen(false)} closeIcon={true}>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Practice</label>
                            <Input type='text' disabled={true} value={state.patientInsight.practice}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Notes</label>
                            <TextArea value={state.patientInsight.notes} onChange={onFieldUpdate}/>
                        </Form.Field>
                        <Form.Field>
                            <Button
                                disabled={!state.patientInsight.notes}
                                primary={true}
                                onClick={() => onSave()}
                            >
                                Save
                            </Button>
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </Modal>
        }
    </div>);
};

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(SavePatientInsight);
