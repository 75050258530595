import React, { useState } from "react";
import { Button, Icon, Form } from 'semantic-ui-react';
import ColorPicker from "react-pick-color";
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {useActions} from "../redux/actions";

export const SaveStatusModal = (
    props: {
        openStatusModal: any,
        setOpenStatusModal: any,
        setOralSurgeryStatus: any,
        id: any,
        type: String}
) => {
    const [color, setColor] = useState("#fff");
    const [sequence, setSequence] = useState(0);
    const [referStatus, setReferStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const StatusActions = useActions(statusActions);
    const modalRef = React.useRef<HTMLDivElement>(null);

    const closeSaveStatusModal = () => {
        props.setOpenStatusModal(false);
    }

    const closeModalByRef = (e: any) => {
        if(modalRef.current === e.target)
            props.setOpenStatusModal(false);
    }

    const handleSubmit = (event: any) => new Promise((resolve, reject) => {
        event.preventDefault();
        const payload = {
            sequence,
            referStatus,
            statusColor: color,
            type: props.type
        }

        setLoading(true);
        StatusActions.saveOralStatus(payload).then((res : any) => {
            StatusActions.fetchOralSurgeryStatus().then((res : any) => {

                setLoading(false);
                props.setOralSurgeryStatus(res);
                props.setOpenStatusModal(false);
            })
        })
    });

    return(
        props.openStatusModal ? <>
            <div className="modalContainer" ref={modalRef} onClick={(e) => closeModalByRef(e)}>
                <div className="modalWrapper">
                        <Icon className="close-btn float-right mt-5 mr-5 red cursorPointer" name="close" onClick={() => closeSaveStatusModal()} />
                        <Form className="form" onSubmit={(event) => handleSubmit(event)}>
                            <div className="vertical-align mr20">
                                <Form.Field>
                                    <label>SEQUENCE</label>
                                    <input
                                        className='form-control'
                                        type={"number"}
                                        placeholder="0"
                                        min="0"
                                        value={sequence}
                                        required
                                        onChange={(e) => setSequence(parseInt(e.target.value))}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>REFER STATUS</label>
                                    <input
                                        className='form-control'
                                        type={"text"}
                                        placeholder="status"
                                        value={referStatus}
                                        required
                                        onChange={(e) => setReferStatus(e.target.value)}
                                    />
                                </Form.Field>
                                <Button type={"submit"} primary>Save</Button>
                            </div>

                            <ColorPicker hideAlpha={true} className="colorPicker mr10" color={color} onChange={(color) => setColor(color.hex)} />
                        </Form>
                </div>
            </div>
        </>: null
    )
}
