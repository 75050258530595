import React from "react";
import { ActionType } from "../../model/actionTypes";
import {PMD, PMDSetup} from "../../model/pmd";
import {errorHandler} from "./auth";

export const saveSetup = (data: PMDSetup) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp =  await api.post("practiceManagerDashboard/setup", data);
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: { data } });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: '' });
        return dispatch(errorHandler(err));
    }
}

export const getSetup = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("practiceManagerDashboard/setup");
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getMCONPWlist = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const resp =  await api.get(`practiceManagerDashboard/getPMDMCONPWlist${params}`);
        dispatch({ type: ActionType.PMD_MCO_NPW_LIST, payload: { mco_npw: resp[0].npo} });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp[0].npo;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getMCORCNlist = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const resp =  await api.get(`practiceManagerDashboard/getPMDMCORCNlist${params}`);
        dispatch({ type: ActionType.PMD_MCO_RCN_LIST, payload: { mco_rcn: resp[0].rcno} });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp[0].rcno;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getPMDTasks = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmddtasks =  await api.get(`practiceManagerDashboard/getPMDTasks${params}`);
        dispatch({ type: ActionType.PMD_TASKS, payload: pmddtasks });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmddtasks;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getPMDBiller = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdbiller =  await api.get(`practiceManagerDashboard/getPMDBiller${params}`);
        dispatch({ type: ActionType.PMD_BILLER, payload: pmdbiller });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdbiller;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const fetchScheduleDataPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/schedule${params}`);
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchMonthlyShiftsPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/shifts${params}`);
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchMGeneralDailyShiftsPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/generaldailyshifts${params}`);
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchMHygienistDailyShiftsPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/hygienistsdailyshifts${params}`);
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.PMD_SCHEDULE, payload: { scheduleData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchleadReports = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
        params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/leadsReports${params}`);
		dispatch({ type: ActionType.LEADS_REPORT, payload: { reportData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.LEADS_REPORT, payload: { reportData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const getPMDScheduleCoordinator = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleCoordinator =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinator${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_COORDINATOR, payload: pmdScheduleCoordinator });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleCoordinator;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getPMDScheduleCoordinatorNoFutureWtX = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleCoordinatorWtX =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinatorWtX${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_COORDINATOR_WTX, payload: pmdScheduleCoordinatorWtX });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleCoordinatorWtX;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getPMDScheduleCoordinatorNoFutureApt = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleCoordinatorApt =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinatorApt${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_COORDINATOR_APT, payload: pmdScheduleCoordinatorApt });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleCoordinatorApt;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const fetchPriorityReports = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get(`practiceManagerDashboard/priorityReport`);
        dispatch({ type: ActionType.PMB_PRIORITY, payload: { priorityReport: response } });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.PMB_PRIORITY, payload: { priorityReport: [] } });
        return dispatch(errorHandler(err));
    }
}

export const getPMDScheduleToday30DayApts = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleToday30DayApts=  await api.get(`practiceManagerDashboard/getPMDScheduleToday30DayApts${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_TODAY_30_DAY_APTS, payload: pmdScheduleToday30DayApts });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleToday30DayApts;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDScheduleToday30DayCompletd = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleToday30DayCompletd=  await api.get(`practiceManagerDashboard/getPMDScheduleToday30DayCompletd${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_TODAY_30_DAY_COMPLETED, payload: pmdScheduleToday30DayCompletd });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleToday30DayCompletd;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDScheduleTodayPlanScheduledApts = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleTodayPlanScheduledApts=  await api.get(`practiceManagerDashboard/getPMDScheduleTodayPlanScheduledApts${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_TODAY_30_DAY_PLAN_SCHEDULED_APTS, payload: pmdScheduleTodayPlanScheduledApts });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleTodayPlanScheduledApts;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDScheduleTodaySchAptToday = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });    
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleTodaySchAptToday=  await api.get(`practiceManagerDashboard/getPMDScheduleTodaySchAptToday${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_TODAY_SCH_APT_TODAY, payload: pmdScheduleTodaySchAptToday });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleTodaySchAptToday;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDScheduleTodayPtSeenToday = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdScheduleTodayPtSeenToday=  await api.get(`practiceManagerDashboard/getPMDScheduleTodayPtSeenToday${params}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_TODAY_PT_SEEN_TODAY, payload: pmdScheduleTodayPtSeenToday });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdScheduleTodayPtSeenToday;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDTodaysProduction = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        params = (params) ? "/"+params : "";
        const pmdTodaysProduction=  await api.get(`practiceManagerDashboard/getPMDTodaysProduction${params}`);
        dispatch({ type: ActionType.PMD_TODAYS_PRODUCTION, payload: pmdTodaysProduction });
        dispatch({ type: ActionType.LOADER, payload: false });
        return pmdTodaysProduction;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getPMDScheduleChartAptFillDayApt = (param:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    let stringifyParams;
    try {
        let params:any={};
        params.practice = param;
        params.day = "1";
        stringifyParams = '/'+JSON.stringify(params);
        const pmdScheduleChartAptFillDayApt1=  await api.get(`practiceManagerDashboard/getPMDScheduleChartAptFillDayApt${stringifyParams}`);
        params.day = "2";
        stringifyParams = '/'+JSON.stringify(params);
        const pmdScheduleChartAptFillDayApt2=  await api.get(`practiceManagerDashboard/getPMDScheduleChartAptFillDayApt${stringifyParams}`);
        dispatch({ type: ActionType.PMD_SCHEDULE_CHART_APTFILL_DAYAPT, payload: pmdScheduleChartAptFillDayApt1 });
        dispatch({ type: ActionType.PMD_SCHEDULE_CHART_APTFILL_DAYAPT, payload: pmdScheduleChartAptFillDayApt2 });
        dispatch({ type: ActionType.LOADER, payload: false });
        const aptFill:any={day1:pmdScheduleChartAptFillDayApt1,day2:pmdScheduleChartAptFillDayApt2}
        return aptFill;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}; 