import * as React from "react";
import {Redirect, Route} from "react-router";
import SignIn from "./container/SignIn";
import Referrals from "./container/Referrals";
import ProcedureCodesOrtho from "./components/ProcedureCodesOrtho";
import OralSurgerySetup from "./container/OralSurgerySetup";
import ProcedureCodesOral from "./components/ProcedureCodesOral";
import Reports from "./container/Reports";
import StatusSetup from "./container/StatusSetup";
import OrthoSetup from "./container/OrthoSetup";

import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute";
import UsageReport from './components/UsageReport';

import LoginForm from "./components/LoginForm";
import ReferralWorkContainer from "./container/ReferralWork";


function AppRoutes(props: any) {
    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const RedirectTo = (props: any) => isAuthenticated ? <Redirect to="/referrals"/> : <SignIn {...props} />;


    return (
        <div>
            <Route
                exact={true}
                path="/"
                render={props => RedirectTo(props)}
            />

            <Route
                exact={true}
                path="/login"
                component={LoginForm}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/referrals"
                component={Referrals}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/referral-work"
                component={ReferralWorkContainer}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/setup/procedure-codes-ortho"
                component={ProcedureCodesOrtho}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/setup/oral-surgery"
                component={OralSurgerySetup}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/setup/procedure-codes-oral"
                component={ProcedureCodesOral}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/setup/oral-surgery-status-setup"
                component={StatusSetup}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/setup/ortho-status-setup"
                component={OrthoSetup}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/reports"
                component={Reports}
            />
          <PrivateRoute
            {...defaultProtectedRouteProps}
            path="/usage"
            component={UsageReport}
          />
        </div>
    );
}

export default AppRoutes;
