import React, {useState} from 'react'
import { Icon } from 'semantic-ui-react'

interface ComponentProps {
    openStatusMenu: boolean
    statusTitle: string
    getMonthlyData: (type: string) => void
    handleStatusMenuClick: () => void
    reportData: any
    reportType: string
    statuses: any
    totals: any
}

export default function RefferalStatusOptions(props: ComponentProps) {

    let type = props.statusTitle.split(' ')[0].toLowerCase();
    const stats = Object.values(props.statuses).filter(function (status: any) {
        return status.type == type
    });
    let referrals: any = [];

    Object.keys(props.reportData).forEach((key) => {
        Object.values(props.reportData[key]).forEach((referral: any) => {
            referral.forEach((item: any) => {
                referrals.push(item);
            });
        })
    })

    let totals: any = [];
    referrals.forEach((ref: any, key: number) => {
        if(totals === undefined || totals[ref.speciality] === undefined) {
            totals[ref.speciality] = [];
            let count = 0;

            if(ref.refInPractice === null && ref.statusId === null) {
                totals[ref.speciality].filter((id: any) => id.ref === ref.patientId ? count++ : null);
                if(count < 1 ) {
                    totals[ref.speciality].push({name: 'Not Started', ref: ref.patientId})
                }
            }
            else {
                stats.forEach((stat: any) => {
                    if(ref.statusId === stat.id) {
                        totals[ref.speciality].filter((id: any) => id.ref === ref.patientId ? count++ : null);
                        if(count < 1 ) {
                            totals[ref.speciality].push({name: stat.referStatus, ref: ref.patientId})
                        }
                    }
                })
            }
        }
        else {
            let count = 0;
            if(ref.refInPractice === null && ref.statusId === null) {
                totals[ref.speciality].filter((id: any) => id.ref === ref.patientId ? count++ : null);
                if(count < 1) {
                    totals[ref.speciality].push({name: 'Not Started', ref: ref.patientId})
                }
            }
            stats.forEach((stat: any) => {
                if(ref.statusId === stat.id) {
                    totals[ref.speciality].filter((id: any) => id.ref === ref.patientId ? count++ : null);
                    if(count < 1) {
                        totals[ref.speciality].push({name: stat.referStatus, ref: ref.patientId})
                    }
                }
            })
        }
    })
    let notStartedTotal = props.totals[props.reportType];
    stats.forEach((item: any, key: any) => {
        totals[type].forEach((refType: any) => {
            if (refType.name === item.referStatus && refType.name !== "Not Started") {
               if(notStartedTotal > 0) notStartedTotal--
            }
        })
    })

    return (
        <div
            className="dropdown-contentx"
            style={{display: props.openStatusMenu ? "" : "none"}}
        >
            <div className="dropdownHeader">
                <div className="dropdownHeaderText">
                    <p>
                        {props.statusTitle}
                    </p>
                    <Icon
                        name="close"
                        className="ml-auto"
                        onClick={() => props.handleStatusMenuClick()}
                    />
                </div>
            </div>
            {
                stats ? stats.map((item: any, key: any) => {
                    let tot = 0;
                    totals[type].forEach((refType: any) => {
                        if(refType.name === item.referStatus && refType.name.toLowerCase() !== "not started") {
                            if(props.totals[props.reportType] > 0) tot++
                        }
                    })
                    return (
                        <div key={key} className="statusSetup">
                            <div
                                className="roundedButton"
                                style={{background: item.statusColor}}
                            >
                                <div className="roundedButtonText">{item.referStatus}</div>
                            </div>
                            <p>
                                {
                                    item.referStatus.toLowerCase() === "not started" ? notStartedTotal : tot
                                }
                            </p>
                        </div>
                    );

                }) : null
            }
            <div className="viewAllContainer">
                <div
                    className="viewAllButton"
                    onClick={() => {
                        props.handleStatusMenuClick()
                        props.getMonthlyData(props.reportType)
                    }}
                >
                    <p>View All</p>
                </div>
            </div>
        </div>
    )
}
