import React from "react";
import Template from "../components/Template";
import ReferralsX from "../components/Referrals";

function Referrals() {

  return (
    <Template activeLink='referrals'>
      <ReferralsX/>
    </Template>
  );

}

export default Referrals;