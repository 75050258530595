import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import DrNoteModal from "./DrNote";
import { Dimmer, Loader, Card, Table, Grid } from 'semantic-ui-react';
import { RootState } from '../../model';
import { connect } from 'react-redux';

interface FamilyTableViewProps {
  BrokenChildTable: any
  selectedPatient: any
  selectedPracticeName: any
  treatmentPlanCalculation: any
  aptResult?: any
  treatmentList?: any
  commlog?: any
  treatmentPlan?: any
}
const FamilyTableView: React.FC<FamilyTableViewProps> = (props) => {

  const initialState = {
    ignoreTX: {},
    note: null,
  }

  const [state, setState] = useState(initialState);


  const hideDrModal = () => {
    setState({ ...state, note: null })
  }
  const trimString = (str: any, len: any, addDots: any) => {
    return (
      (str && str.length > len) ?
        (addDots !== false ?
          str.substring(0, len - 3) :
          str.substring(0, len - 3) + '...') :
        str);
  }
  const notedLoaded = (treatmentList: any) => {
    for (let i = 0; i < treatmentList.length; i++) {
      const keys = Object.keys(treatmentList[i])
      if (keys.includes("grpnote")) {
        return true
      }
    }
    return "";
  }


  const {
    BrokenChildTable,
    selectedPatient,
    selectedPracticeName,
    treatmentPlanCalculation,
    aptResult,
    treatmentList,
    commlog,
    treatmentPlan
  } = props;

  const { note } = state


  return (
    <div>
      {note && <DrNoteModal note={note} hideDrModal={hideDrModal} selectedPatient={selectedPatient}
        selectedPracticeName={selectedPracticeName} />}
      <div className="BCTable wd100" >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={6} tablet={16} mobile={16}>
            <Card className="pd0 mb20 minHeight360" >
              <Card.Content>
                <Card.Header >
                  <h3 className="headingSub text-md" > Patient Appointment List </h3>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="scroller" >
                  <Table striped className="tableCustomStyle TableHeaderFixed">
                    <thead>
                      <tr>
                        <th className="text-sm th-min-width-unset">PatNum</th>
                        <th className="text-sm th-min-width-unset">AptDateTime</th>
                        <th className="text-sm th-min-width-unset">APT_Status</th>
                        <th className="text-sm th-min-width-unset">Note</th>
                      </tr>
                    </thead>
                    {
                      (aptResult && aptResult.length) ?
                        <tbody>
                          {aptResult.map((item: any, key: number) =>
                            <tr key={key}>
                              <td className="text-sm">{item.PatNum}</td>
                              <td className="text-sm">{item.AptDateTime ? item.AptDateTime : ''}</td>
                              <td className="text-sm">{item.APT_Status}</td>
                              <td className="text-sm">
                                {item.Note ?
                                  <div>
                                    <a className="primaryColor text-sm" data-tip data-for={`aptResult${key}`}>
                                      {trimString(item.Note, 15, false)}
                                    </a>
                                    <ReactTooltip id={`aptResult${key}`} place="right" type="light" effect="float" border={true}>
                                      {item.Note}
                                    </ReactTooltip>
                                  </div> : ''}
                              </td>
                            </tr>)
                          }
                        </tbody>
                        : <Dimmer active inverted>
                          <Loader inverted>Loading</Loader>
                        </Dimmer>}
                    {
                      (
                          !aptResult && <tbody><tr key='totalTr'><td colSpan={4}>No Record found.</td></tr></tbody>
                      )
                    }
                  </Table>
                </div>
              </Card.Content>
            </Card>
            </Grid.Column>
            <Grid.Column computer={10} tablet={16} mobile={16}>
            <Card className="pd0 mb20 minHeight360" >
              <Card.Content>
                <Card.Header >
                  <h3 className="headingSub text-md" > Patient Treatment List </h3>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="scroller">
                  <Table striped className="tableCustomStyle TableHeaderFixed">
                    <thead>
                    <tr><
                      th className="text-sm th-min-width-unset">PatNum</th>
                      <th className="text-sm th-min-width-unset">ProcDate</th>
                      <th className="text-sm th-min-width-unset">Production</th>
                      <th className="text-sm th-min-width-unset">Procedures <br /> Code Category</th>
                      <th className="text-sm th-min-width-unset">Dr. Note</th>
                    </tr>
                    </thead>
                    {
                      (treatmentList && treatmentList.length) ?
                        !notedLoaded(treatmentList) ?
                          <tbody><tr key='totalTr'><td colSpan={4}><Loader inverted>Loading</Loader></td></tr></tbody>
                          :
                          <tbody>
                            {treatmentList.map((item: any, key: number) =>
                              <tr key={key}>
                                <td className="text-sm">{item.PatNum}</td>
                                <td className="text-sm">{item.ProcDate ? item.ProcDate : ''}</td>
                                <td className="text-sm">{(item.Production) ? parseFloat(item.Production).toFixed(2) : item.Production}</td>
                                <td className="text-sm">
                                  {item.Proc_Category ?
                                    <div>
                                      <a className="primaryColor text-sm" data-tip data-for={`Procedures${key}`}>
                                        {trimString(item.Proc_Category, 15, false)}
                                      </a>
                                      <ReactTooltip id={`Procedures${key}`} place="right" type="light" effect="float" border={true}>
                                        {item.Proc_Category}
                                      </ReactTooltip>
                                    </div> : ''
                                  }
                                </td>
                                <td className="primaryColor cursorPointer text-sm">
                                  {
                                    item.grpnote ?
                                      <span onClick={() => setState({ ...state, note: item.grpnote })}>
                                        {trimString(item.grpnote, 15, false)}
                                      </span>
                                      : ''
                                  }
                                </td>
                              </tr>
                            )}
                          </tbody>
                        :
                        (!treatmentList ?
                          <tbody><tr key='totalTr'><td colSpan={4}><Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer></td></tr></tbody>
                          :
                          <tbody><tr key='totalTr'><td colSpan={4}>No Record found.</td></tr></tbody>)
                    }
                  </Table>
                </div>
              </Card.Content>
            </Card>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={6} tablet={16} mobile={16}>
            <Card className="pd0 mb20 minHeight360" >
              <Card.Content>
                <Card.Header >
                  <h3 className="headingSub text-md"> CommLog History </h3>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="scroller">
                  <Table className="table tableCustomStyle TableHeaderFixed">
                    <thead>
                      <tr>
                        <th className="text-sm th-min-width-unset">PatNum</th>
                        <th className="text-sm th-min-width-unset">CommDateTime</th>
                        <th className="text-sm th-min-width-unset">CommType</th>
                        <th className="text-sm th-min-width-unset">Note</th>
                      </tr>
                    </thead>
                    {
                      (commlog && commlog.length) ?
                        <tbody>
                          {commlog.map((item: any, key: number) =>
                            <tr key={key}>
                              <td className="text-sm">{item.PatNum}</td>
                              <td className="text-sm">{item.CommDateTime ? item.CommDateTime : ''}</td>
                              <td className="text-sm">{item.CommType}</td>
                              <td className="text-sm">
                                {item.Note ?
                                  <div>
                                    <a className="primaryColor text-sm" data-tip data-for={`commlog${key}`}>
                                      {trimString(item.Note, 15, false)}
                                    </a>
                                    <ReactTooltip id={`commlog${key}`} place="right" type="light" effect="float" border={true}>
                                      {item.Note}
                                    </ReactTooltip>
                                  </div> : ''}
                              </td>
                            </tr>
                          )}
                        </tbody>
                        :
                        (!commlog ?
                          <tbody><tr key='totalTr'><td colSpan={4}><Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer></td></tr></tbody>
                          :
                          <tbody><tr key='totalTr'><td colSpan={4}>No Record found.</td></tr></tbody>)
                    }
                  </Table>
                </div>
              </Card.Content>
            </Card>
            </Grid.Column>

            <Grid.Column computer={10} tablet={16} mobile={16}>
            <Card className="pd0 mb20 minHeight360" >
              <Card.Content>
                <Card.Header >
                  <h3 className="headingSub text-md" > Open Treatment Plan </h3>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <div className="scroller" >
                  <Table className="tableCustomStyle TableHeaderFixed">
                    <thead>
                    <tr>
                      <th className="text-sm th-min-width-unset">PatNum</th>
                      <th className="text-sm th-min-width-unset">ProcDate</th>
                      <th className="text-sm th-min-width-unset">ProcNum</th>
                      <th className="text-sm th-min-width-unset">ToothNum</th>
                      <th className="text-sm th-min-width-unset">ProcCode</th>
                      <th className="text-sm th-min-width-unset">ProcFee</th>
                      <th className="text-sm th-min-width-unset">PC_cat</th>
                    </tr>
                    </thead>

                    {
                      (BrokenChildTable.treatmentPlan && Object.keys(BrokenChildTable.treatmentPlan).length && selectedPatient !== undefined &&
                        treatmentPlan[selectedPatient.PT_Num]) ?
                        <tbody>
                          {treatmentPlan[selectedPatient.PT_Num].map((item: any, key: number) =>
                            <tr key={key}>
                              <td className="text-sm">{item.PatNum}</td>
                              <td className="text-sm">{item.ProcDate ? item.ProcDate : ''}</td>
                              <td className="text-sm">{item.ProcNum}</td>
                              <td className="text-sm">{item.ToothNum}</td>
                              <td className="text-sm">{item.ProcCode}</td>
                              <td className="text-sm">{item.ProcFee}</td>
                              <td className="text-sm">{item.PC_cat}</td>
                            </tr>
                          )}
                          {<tr key='totalTr'>
                            <td colSpan={4} style={{ textAlign: 'right' }}>Total: </td>
                            <td><strong>{(treatmentPlanCalculation.txPlanTotal) ? parseFloat(treatmentPlanCalculation.txPlanTotal).toFixed(2) : treatmentPlanCalculation.txPlanTotal}</strong></td>
                            <td colSpan={2}></td>
                          </tr>}
                        </tbody>
                        :
                        <tbody><tr key='totalTr'><td colSpan={7}>No Record found.</td></tr></tbody>}
                  </Table>
                </div>
              </Card.Content>
            </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )


}


function mapStateToProps(state: RootState) {
  return {
    aptResult: state.billing.familyData.aptResult,
    treatmentList: state.billing.familyData.treatmentList,
    treatmentPlan: state.billing.familyData.treatmentPlan,
    commlog: state.billing.familyData.commlog


  };
}

export default connect(mapStateToProps)(FamilyTableView);
