import moment from 'moment';
import { ActionType } from "../../model/actionTypes";





export const checkCurrentDayData = (abbr:string, params:any) => async (dispatch:Function, getState:Function, api:any) => {
  try {
    let res = await api.get(`patient-ref/api/calendar/${abbr}${params}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const hideErrorModal = () => async (dispatch:Function, getState:Function, api:any) => {
  dispatch({ type: ActionType.CLOSE_ERROR_MESSAGE, action: null });
}

export const storeTodayProduction = (billingData:any, type = 'calendar') => async (dispatch:Function, getState:Function) => {
  let prodAdjWo = 1;
  let user = getState().get('auth').get('user');
  if(user && user.UdaDrList)
    prodAdjWo = user.UdaDrList.prodAdjWo
  let todayProduction = getState().get('billing').get('todayProduction');
  if(!todayProduction) {
    todayProduction = { originalPrice: 0, price: 0, report: null, reportDetails: null, todayPractices: [] }
  }
  const todayDate = moment().format('Y-MM-DD');
  // const todayDate = '2018-12-14';

  if(type === 'calendar' && billingData && billingData.production.orderByDate && billingData.production.orderByDate[todayDate]) {
    todayProduction.originalPrice = billingData.production.orderByDate[todayDate].price
    todayProduction.price = billingData.production.orderByDate[todayDate].price
    if(billingData.writeoff.orderByDate && billingData.writeoff.orderByDate[todayDate]) {
      todayProduction.price -= billingData.writeoff.orderByDate[todayDate].price
    }
    if(billingData.adjustment.orderByDate && billingData.adjustment.orderByDate[todayDate]) {
      todayProduction.price += billingData.adjustment.orderByDate[todayDate].price
    }
    if(todayProduction.price && todayProduction.price > 0 && prodAdjWo) {
          todayProduction.price = todayProduction.price * prodAdjWo / 100;
    }
    todayProduction.price = todayProduction.price.toFixed(2);
  } else if(type === 'report' && billingData && Object.keys(billingData.production.reportData).length && billingData.production.reportData[todayDate]) {
    todayProduction.report = billingData.production.reportData[todayDate];
  } else if(type === 'reportDetails' && billingData && Object.keys(billingData.production.reportData).length && billingData.production.reportData[todayDate]) {
    todayProduction.reportDetails = billingData.production.reportData[todayDate];
  }
  if(type === 'report') {
    let todayPractices:any = [];
    // Get today practice names
    if(billingData && Object.keys(billingData.production).length && billingData.production.orderByDate[todayDate]) {
      todayPractices = Object.keys(billingData.production.orderByDate[todayDate]);
      if(todayPractices.indexOf('price') >= 0) {
        todayPractices.splice(todayPractices.indexOf('price'), 1);
      }
    }
    todayProduction.todayPractices = todayPractices;
  }
  dispatch({ type: ActionType.SAVE_TODAY_PRODUCTION, todayProduction: todayProduction });
}

export const getBillingData = (params:any) => async (dispatch:Function, getState:Function, api:any) => {
  dispatch({ type: ActionType.LOAD });
  let user = getState().get('auth').get('user');
  const startMonth = params.startDate ? moment(params.startDate).format('Y-M') : moment().format('Y-M');
  const currentMonth = moment().format('Y-M');

  if(!user.UdaDrList.abbr) {
    dispatch({ type: ActionType.LOAD_FAIL, error: "Please add Dentist's details" });
  }
  if(params) {
    params = '/'+JSON.stringify(params);
  }
  try {
    let res = await api.get(`patient-ref/api/calendar/${user.UdaDrList.abbr}${params}`);
    dispatch({ type: ActionType.LOAD_SUCCESS, res: res });
    let storeTodayFlag = false;
    // store today data if its not current month
    if(startMonth === currentMonth) {
      dispatch(storeTodayProduction(res));
      storeTodayFlag = true;
    }

    if(params) {
      dispatch({ type: ActionType.SAVE_PARAMS, params: params })
    }
    // Get report data if existingUser
    if(res && Object.keys(res.production).length) {
      dispatch(getReportData(storeTodayFlag));
    }
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
  }
};

export const getNextDaysData = (params:any, load = '') => async (dispatch:Function, getState:Function, api:any) => {

  if(load !== '')
    dispatch({ type: ActionType.LOAD });

  let user = getState().get('auth').get('user');
  if(!user.UdaDrList.abbr || !user.UdaDrList.userId) {
    dispatch({ type: ActionType.FAIL_NEXT_DAYS, error: "Please add Dentist's details" });
  }
  if(params) {
    const currentMonth = moment().format('Y-MM-01');
    if(params.startDate === currentMonth) {
      params.startDate = moment().format('Y-MM-DD');
    }
    params = '/'+JSON.stringify(params);
  } else {
    const startDate = moment().format('Y-MM-DD');
    params = {
      startDate: startDate,
      endDate: moment(startDate).endOf('month').format('Y-MM-DD')
    }
    params = '/'+JSON.stringify(params);
  }
  try {
    let res = await api.get(`patient-ref/api/next-days/${user.UdaDrList.abbr}/${user.UdaDrList.userId}${params}`);
    dispatch({ type: ActionType.LOAD_NEXT_DAYS, res: res });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.FAIL_NEXT_DAYS, error: err.message });
  }
};

export const getReportData = (storeTodayFlag:any, url?:any) => async (dispatch:Function, getState:Function, api:any) => {
  let params = getState().get('billing').get('billingParams');
  let user = getState().get('auth').get('user');
  let dispatchResponse = false;
  try {
    if(!url) {
      url = `patient-ref/api/details1/${user.UdaDrList.abbr}${params}`;
      dispatchResponse = true;
    }
    let res = await api.get(url);
    if(dispatchResponse) {
      dispatch({ type: ActionType.LOAD_REPORT_SUCCESS, res: res });
    }

    // store today data if its not current month
    if(storeTodayFlag) {
      dispatch(storeTodayProduction(res, 'report'));
    }

    dispatch(getReportDetailsData(storeTodayFlag));
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_REPORT_FAIL, error: err.message });
  }
};
//------------------------------------------------------------------------------
export const getReportDetailsData = (storeTodayFlag:any, url?:any) => async (dispatch:Function, getState:Function, api:any) => {
  let params = getState().get('billing').get('billingParams');
  let user = getState().get('auth').get('user');
  let dispatchResponse = false;
  if(!url) {
    url = `patient-ref/api/details2/${user.UdaDrList.abbr}${params}`;
    dispatchResponse = true;
  }
  try {
    let res = await api.get(url);
    if(dispatchResponse) {
      dispatch({ type: ActionType.LOAD_REPORT_DETAILS_SUCCESS, res: res });
    }

    // store today data if its not current month
    if(storeTodayFlag) {
      dispatch(storeTodayProduction(res, 'reportDetails'));
    }

    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_REPORT_FAIL, error: err.message });
  }
};

export const getBillingDetails = (abbr:string) => async (dispatch:Function, getState:Function, api:any) => {
  try {
    let res = await api.get(`patient-ref/api/udrBillingDetails/${abbr}`);
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_SUCCESS, res: res });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_FAIL, error: err.message });
  }
};
export const getMedical = (patientId:number,practice:string) => async (dispatch:Function, getState:Function, api:any) => {
  try {

    api.get(`patient-ref/api/medical/${patientId}/${practice}`)
        .then((medical:any) => {
          dispatch({type: ActionType.LOAD_MEDICAL,  payload:medical});
        });
  } catch (err) {
    dispatch({ type: ActionType.LOAD_MEDICAL_FAIL, res:null});
  }
}
export const getFamilyPatientDetails = (patientId:number, practice:string) => async (dispatch:Function, getState:Function, api:any) => {
  let res = Object.assign({}, getState().get('billing').get('familyData'));
  res.aptResult = null;
  res.treatmentList = null;
  res.commlog = null;
  dispatch({ type: ActionType.LOAD_FAMILY, res: res });
  let params = { "func": "brokenListChild", "location": practice, "PatNum": patientId, "Guarantor": patientId }
  try {
    params.func = 'tablePTLInfo';
    api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
    .then( (treatmentList:any) => {
      res.treatmentList = treatmentList;
      dispatch({ type: ActionType.LOAD_TREATMENT_LIST, state: res, result: res.treatmentList });
    });

    params.func = 'tableCLHInfo';
    api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
    .then( (commlog:any) => {
      res.commlog = commlog;
      dispatch({ type: ActionType.LOAD_TREATMENT_LIST, state: res, result: res.commlog });
    });

    params.func = 'aptResult';
    api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
    .then( (aptResult:any) => {
      res.aptResult = aptResult;
      dispatch({ type: ActionType.LOAD_TREATMENT_LIST, state: res, result: res.aptResult });
    });

    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_FAMILY_FAIL, error: 'Family view api failed. Error: '+err.message });
  }
};

export const getFamilyPatients = (patientId:number, practice:string) => async (dispatch:Function, getState:Function, api:any) => {
  let res = Object.assign({}, getState().billing.initialFamilyData);
  dispatch({ type: ActionType.LOAD_FAMILY, payload: res });
  let params:any = { "func": "brokenListChild", "location": practice, "PatNum": patientId, "Guarantor": patientId }
  try {
    res.brokenListChild = await api.get(`patient-ref/broken-api/${JSON.stringify(params)}`);
    dispatch({ type: ActionType.LOAD_BILLING_FAMILY_SUCCESS, payload: res.brokenListChild });
    if(res.brokenListChild && res.brokenListChild.length) {
      params.func = 'tablePTLInfo';
      api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
      .then( (treatmentList:any) => {
        res.treatmentList = treatmentList;
        dispatch({ type: ActionType.LOAD_TREATMENT_LIST, state: res, payload: res.treatmentList });
      });

      params.func = 'tableCLHInfo';
      api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
      .then( (commlog:any) => {
        res.commlog = commlog;
        dispatch({ type: ActionType.LOAD_COMMLOG, state: res, payload: res.commlog });
      });

      params.func = 'tableOTPInfo';
      api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
      .then( (treatmentPlan:any) => {
        res.treatmentPlan = treatmentPlan;
        dispatch({ type: ActionType.LOAD_TREATMENT_PLAN, state: res, payload: res.treatmentPlan });
      });

      params.func = 'aptResult';
      api.get(`patient-ref/broken-api/${JSON.stringify(params)}`)
      .then( (aptResult:any) => {
        res.aptResult = aptResult;
        dispatch({ type: ActionType.LOAD_APT_RESULT, state: res, payload: res.aptResult });
      });

      params.PT_Num = []
      res.brokenListChild.forEach((item: any) => {
        params.PT_Num.push(item.PT_Num);
      });
      let temp: any = {};
      const patientObjects = [];
      params.func = 'insurance';
      const response = await api.get(`patient-ref/broken-api/${JSON.stringify(params)}`);
      const BrokenChild = res.brokenListChild;
      if( response && response.length) {
        for (let i = 0; i < BrokenChild.length; i++) {
          temp = Object.assign({}, BrokenChild[i]);
          for (let j = 0; j < response.length; j++) {
            if (response[j].PatNum === temp.PatNum) {
              temp.Insurance = {};
              temp.Insurance.DateLastVerified = response[j].DateLastVerified;
              temp.Insurance.Insurance = response[j].Insurance;
              temp.Insurance.Visit_Count = response[j].Visit_Count;
              temp.Insurance.show_rate = response[j].show_rate;
              temp.Insurance.total_apts = response[j].total_apts;
            }
          }
          patientObjects.push(temp);
        }
        res.brokenListChild = patientObjects;
        dispatch({ type: ActionType.LOAD_BILLING_FAMILY_SUCCES, payload: res.brokenListChild });
      }

      //dispatch(fetchInsuranceDetails(params, res))
    } else {
      dispatch({ type: ActionType.LOAD_BILLING_FAMILY_FAIL, error: "Family view api failed." });
    }

    // return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_FAMILY_FAIL, error: 'Family view api failed. Error: '+err.message });
  }
};

export const fetchInsuranceDetails = async (params:any, res:any) => async (dispatch:Function, getState:Function, api:any) => {
  params.PT_Num = []
  res.brokenListChild.forEach( (item:any) => {
    params.PT_Num.push(item.PT_Num);
  });
  let temp:any = {};
  const patientObjects = [];
  params.func = 'insurance';
  const response = await api.get(`patient-ref/broken-api/${JSON.stringify(params)}`);
  const BrokenChild = res.brokenListChild;
  if( response && response.length) {
    for (let i = 0; i < BrokenChild.length; i++) {
      temp = Object.assign({}, BrokenChild[i]);
      for (let j = 0; j < response.length; j++) {
        if (response[j].PatNum === temp.PatNum) {
          temp.Insurance = {};
          temp.Insurance.DateLastVerified = response[j].DateLastVerified;
          temp.Insurance.Insurance = response[j].Insurance;
          temp.Insurance.Visit_Count = response[j].Visit_Count;
          temp.Insurance.show_rate = response[j].show_rate;
          temp.Insurance.total_apts = response[j].total_apts;
        }
      }
      patientObjects.push(temp);
    }
    res.brokenListChild = patientObjects;
  }
  return res;
}

export const updateReportData = (param:any) => async (dispatch:Function, getState:Function, api:any) => {
  try {
    let user = getState().get('auth').get('user');
    const details1 = `patient-ref/api/details1/${user.UdaDrList.abbr}${param}`;
    const details2 = `patient-ref/api/details2/${user.UdaDrList.abbr}${param}`;
    const storeTodayFlag = true;
    dispatch(getReportData(storeTodayFlag, details1));
    dispatch(getReportDetailsData(storeTodayFlag, details2));
    return true;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_FAIL, error: err.message });
  }
};

export const saveParams = (params:any) => async (dispatch:any) => {
  try {
    dispatch({ type: ActionType.SAVE_PARAMS, params: params });
    return ;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_FAIL, error: err.message });
  }
}

export const sendDrNote = (reportObj:any) => async (dispatch:Function, getState:Function, api:any) => {
  try {
    let res = await api.post('patient-ref/sendDrNote',  {data:reportObj});
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_SUCCESS, res: res });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_FAIL, error: err.message });
  }
};

export const sendReport = (reportObj:any) => async (dispatch:Function, getState:Function, api:any) => {
  reportObj.params = getState().get('billing').get('billingParams');
  try {
    let res = await api.post('patient-ref/sendReport', {data:reportObj});
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_SUCCESS, res: res });
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_BILLING_PRACTICES_FAIL, error: err.message });
  }
};

export const sendFamilyReport = (reportObj:any) => async (dispatch:Function, getState:Function, api:any) => {
  try {
    let res = await api.post('patient-ref/sendFamilyReport', {data:reportObj});
    return res;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
  }
};

export const changeIntervalReqCount = () => async (dispatch:Function, getState:Function, api:any) => {
  let intervalReqCount = getState().get('billing').get('intervalReqCount');
  intervalReqCount = (intervalReqCount < 10) ? intervalReqCount+1 : 0;
  dispatch({ type: ActionType.CHANGE_INTERVAL_REQUEST_COUNT, res: intervalReqCount });
};