import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const fetchProvider = (userId: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.post("providersInfo/getProviderList", userId);
        dispatch({ type: ActionType.FETCH_PROVIDER, payload: { providerList: response }});
        return response;
    } catch (error) {
        return dispatch(errorHandler(error));
    }
};