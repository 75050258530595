import React from "react";
import Template from "../components/Template";
import OralSurgerySetupX from "../components/OralSurgerySetup";

function Dashboard() {

  return (
    <Template activeLink='setup'>
      <OralSurgerySetupX/>
    </Template>
  );

}

export default Dashboard;