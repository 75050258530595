import React from "react";
import Template from "../components/Template";
import ReferralWork from "../components/ReferralWork";

function ReferralWorkContainer() {

    return (
        <Template activeLink='referral-work'>
            <ReferralWork/>
        </Template>
    );

}

export default ReferralWorkContainer;