import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import { isBlankNullOrUndefined } from "../utils/common";

function ModalRS (props: any) {
    const getPracticeInfo = props.practiceInfo;
    const referOutStats = props.report.referOutStats;

    let typeData:any = [];
    let title = 'Referal Out Stats Details';
    const desc = isBlankNullOrUndefined(props.desc) ? '' : props.desc;
    if ( props.type === 'provider' && desc) {
        switch (desc) {
            case 'patientCount':
                typeData = getPracticeInfo;
                title = title + ' PT Count for Dr ' + getPracticeInfo[0].Dr_Name
                break;
            case 'fvCount' :
                getPracticeInfo.forEach((item: any) => {
                    if(item.ProcDate === item.FirstVisit && item.PatNum === item.PatNum) {
                        typeData.push(item)
                    }
                });
                title = title + ' PT Count for Dr ' + getPracticeInfo[0].Dr_Name
                break;

            case 'oralReferrals':
                getPracticeInfo.forEach((item: any) => {
                    if(item.type.includes('oral')) {
                        typeData.push(item)
                    }
                });
                title = title + ' PT Count for Dr ' + getPracticeInfo[0].Dr_Name
                break;

            case 'orthoReferrals':
                getPracticeInfo.forEach((item: any) => {
                    if(item.type.includes('ortho')) {
                        typeData.push(item)
                    }
                });
                title = title + ' PT Count for Dr ' + getPracticeInfo[0].Dr_Name
                break;
        }
    }
    else {
        title = title + ' for the practice ' + referOutStats[getPracticeInfo].practice.name;
        if(desc === 'patientCount')
            typeData = referOutStats[getPracticeInfo].ptCount.data;
        else if(desc === 'fvCount')
            typeData = referOutStats[getPracticeInfo].fvPtCount.data;
        else if(desc === 'oralReferrals')
            typeData = referOutStats[getPracticeInfo].oralReferrals.data;
        else if(desc === 'orthoReferrals')
            typeData = referOutStats[getPracticeInfo].orthoReferrals.data;
        else
            typeData = [];
    }

    

    let yearData:any = [];
    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        const info = ['Practice','Patient Number', 'Date of Service','Doctor'];
        info.forEach((item: any, key: number) => {
                returnArray.push(
                    <th key={key} className="header-os-inner">{item}
                    </th>);
            });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const getTableRows = () => {
        let returnArrayInfo: React.ReactElement[] = [];
        if (typeData && typeData.length > 0){
            typeData.map((item: any, key: number) => {
                const practice = props.practice.practiceListAll.find((p: any) => p.practiceDBName === item.practice)
                returnArrayInfo.push(<tr key={key}>
                    <td>{practice.practice}</td>
                    <td>{item.PatNum}</td>
                    <td>{item.ProcDate}</td>
                    <td>{item.Dr_Name}</td></tr>);
            });
        }else{
            returnArrayInfo.push(<tr><td colSpan={4}>no records found</td></tr>);
        }
        return (<tbody>{returnArrayInfo}</tbody>);
    }

    return (
        <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header>{title}
                <Button className="float-right"
                    content="Close"
                    primary={true}
                    onClick={() => props.handleClose()}
                /><Button primary={true} className="float-right"
                    onClick={() => props.sendReferStatsDetailsEmail(typeData, getPracticeInfo, props.detailsModalType, title)}>
                    Send Report
                </Button>
            </Modal.Header>
            <Modal.Content scrolling={true} >
                <Modal.Description>
                    <div>
                        <div className="table-responsive">
                            <table className="statsTable tableCustomStyle mb0">
                                {getTableHeadings()}
                                {getTableRows()}
                            </table>
                        </div>

                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => props.handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
        providerList: state.provider.providerList,
    };
}
export default connect(mapStateToProps)(ModalRS);
