import { ActionType } from "../../model/actionTypes";
import { ReferalExt } from "../../model/referral";
import {errorHandler} from "./auth";

export const allReferral = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });

    let stringifyParams;
	if(params){
		stringifyParams = JSON.stringify(params)
	}
    try {
        const resp = await api.get(`referrals/getAll/${stringifyParams}`,{},{},getState().auth.accessToken);
        dispatch({ type: ActionType.FETCH_REFERRALS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const searchReferral = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });

    let stringifyParams;
	if(params){
		stringifyParams = JSON.stringify(params)
	}
    try {
        const resp = await api.get(`referrals/search/${stringifyParams}`,{},{},getState().auth.accessToken);
        dispatch({ type: ActionType.FETCH_REFERRALS_WORK, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const saveReferalsExt = (obj:ReferalExt) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("referrals/saveReferal", obj);
        dispatch({ type: ActionType.REFERRALS_EXT_SAVE_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
