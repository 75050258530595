import { Patients } from "../../model/patients";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";

const initialState:Patients = {
	patientData: {},
	filteredPatientData: {},
	carrierIdentity: [],
	defaultPractice: undefined,
	filters: {
		practices: [],
		status: 'all',
		insurance: undefined,
		keyword: '',
		date: ''
	},
	patientValidationErrorReport: {}
};

export const patients = createReducer<Patients>(initialState, {
	[ActionType.PATIENTS_VALIDATION](state: Patients, action: Action<Patients>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.PATIENT_VALIDATION_DEFAULT_PRACTICES](state: Patients, action: Action<Patients>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.CARRIER_IDENTITY](state: Patients, action: Action<Patients>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PATIENT_VALIDATION_ERROR_REPORT](state: Patients, action: Action<Patients>) {
		return Object.assign({...state}, action.payload);
	}
});
