import React from 'react'
import moment from 'moment'
import { Button, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {getBillingDetails, getMedical} from '../../redux/actions/billing';
import { RootState } from '../../model';

interface MedicalProps {
    selectedPT_Num:any
    selectedPracticeName:any
    selectedPractice:any
    hideMedical:Function
    medical:any
}

const Medical = (props: MedicalProps) => {

     /* componentDidMount = () => {
         const {selectedPT_Num,selectedPracticeName} = props;
         if(selectedPT_Num&&selectedPracticeName) {
             getMedical(selectedPT_Num,selectedPracticeName);
         }

     } */

  
         const {selectedPracticeName,selectedPractice} = props;

        const {allergy,medication,service,medical,MedUrgNote}=props.medical;

        return(
            <Modal
                trigger={<Button>Scrolling Content Modal</Button>}
                open={true}
                onClose={() => props.hideMedical()}
                centered={false}
                style = {{ overflow: 'auto' }}
                closeIcon
                size={ "large" }
                className={  "" }
            >
                <Modal.Header>Medical</Modal.Header>

                <Modal.Content scrolling className="pt-0">
                    <Modal.Description className="mt20">
                        {medication&&medication.length>0&&
                         <p>Medication : {medication.map((r:any)=>r.Medications).join(" , ")}</p>}
                        {allergy&&allergy.length>0&&
                        <p>Allergy :    {allergy.map((r:any)=>r.allergy).join(" , ")}</p>}
                        {service&&service.length>0&&
                        <p>Service :    {service.map((r:any)=>r.Service).join(" , ")}</p>}

                        {MedUrgNote&&MedUrgNote.length>0&&
                        <p>Medical Urgent note:    {MedUrgNote.map((r:any)=>r.MedUrgNote).join(" , ")}</p>}

                        {(!medication||medication.length==0)
                        &&(!allergy||allergy.length==0)
                        &&(!service||service.length==0)
                        &&(!MedUrgNote||MedUrgNote.length==0)&&
                            <p>No Data</p>
                        }

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                </Modal.Actions>

            </Modal>
        );
    
}

    function mapStateToProps(state: RootState) {
        return {
            user: state.auth.user,
            medical: state.billing.medical,
        };
    }
    
    export default connect(mapStateToProps)(Medical);