import { ReferralList } from "../../model/referral";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";


const initialState:ReferralList = {
    referrals: [],
	production: {}
}

export const referrals = createReducer(initialState, {
	[ActionType.FETCH_REFERRALS](state: ReferralList, action: Action<ReferralList>) {
		return Object.assign({...state},action.payload);
	},
	[ActionType.FETCH_REFERRALS_WORK](state: ReferralList, action: Action<ReferralList>) {
		return Object.assign({...state},{referrals: action.payload});
	}
});