import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getDentalPartners = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({ type: ActionType.LOAD_DENTAL_PARTNERS, payload: {} });
        const userId = getState().auth.user.id;
        const resp = await api.get(`getPartnersList/${userId}`, {}, {}, getState().auth.accessToken);
        dispatch({ type: ActionType.LOAD_DENTAL_PARTNERS_SUCCESS, payload: resp });
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const saveDentalPartnerUsage = (name: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({ type: ActionType.SAVE_DENTAL_PARTNERS_USAGE, payload: {} });
        const data = {
            partnerName: name,
            userId: getState().auth.user.id
        };
        const resp = await api.post("report/savePartners", data);
        dispatch({ type: ActionType.SAVE_DENTAL_PARTNERS_USAGE_SUCCESS, payload: resp });
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};
export const saveMyFavourite = (name: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const data = {
            partnerName: name,
            userId: getState().auth.user.id
        };
        const resp = await api.post("saveMyFavourite", data);
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};
export const removeMyFavourite = (name: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const data = {
            partnerName: name,
            userId: getState().auth.user.id
        };
        const resp = await api.post("removeMyFavourite", data);
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};
export const getMyFavourite = () => async (dispatch: Function, getState: Function, api: any) => {

    try {
        const userId = getState().auth.user.id;
        const resp = await api.get(`getMyFavourite/${userId}`, {}, {}, getState().auth.accessToken);
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};