import React from "react";
import Template from "../components/Template";
import StatusSetupX from "../components/StatusSetup";

function StatusSetup() {
    const type = 'oral'
    return (
        <Template activeLink='statusSetup'>
            <StatusSetupX type={type}/>
        </Template>
    );

}

export default StatusSetup;