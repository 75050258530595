import * as React from "react";
import {useState} from "react";
import HelpPageModal from "./HelpPageModal";
import helpIcon from '../../styles/images/help-icon.png';

interface Props {
    pageId: any
}

function HelpMark(props: Props) {


    const initialState: any = {
        helpPageModal: false
    }

    const [state, setStates] = useState(initialState);

    const openHelpPage = () => {
        setStates({helpPageModal: true});
    };

    const hideHelpPageModal = () => {
        setStates({helpPageModal: false});
    };

    return (
        <div className="helpMark">
            <span onClick={() => openHelpPage()}><img alt="help" className="cursorPointer" src={helpIcon}/></span>
            {state.helpPageModal &&
            <HelpPageModal hideHelpPageModal={hideHelpPageModal} pageId={props.pageId}/>
            }
        </div>
    );
}

export default HelpMark;
