import React from "react";
import { Button, Icon, Form } from 'semantic-ui-react';
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {useActions} from "../redux/actions";


export const DeleteStatusModal = (props: {deleteStatusModal: any, SetDeleteStatusModal: any, id: any, setData: any}) => {
    const StatusActions = useActions(statusActions);
    const modalRef = React.useRef<HTMLDivElement>(null);
    const closeDeleteStatusModal = () => {
        props.SetDeleteStatusModal(false);
    }
    const closeDelModalByRef = (e: any) => {
        if(modalRef.current === e.target)
            props.SetDeleteStatusModal(false);
    }
    const handleSubmit = (event: any) => new Promise((resolve, reject) => {
        event.preventDefault();
        const payload = {
            id: props.id
        }
        StatusActions.deleteStatus(payload).then((res : any) => {
            StatusActions.deleteStatus(payload).then((res : any) => {
                StatusActions.fetchOralSurgeryStatus().then((res: any) => {
                    props.setData(res);
                    props.SetDeleteStatusModal(false);
                })
            })
        })
    });

    return(
        props.deleteStatusModal ? <>
            <div className="modalContainer" ref={modalRef} onClick={(e) => closeDelModalByRef(e)}>
                <div className="modalWrapper">
                    <Icon className="close-btn float-right mt-5 mr-5 red cursorPointer" name="close" onClick={() => closeDeleteStatusModal()} />
                    <Form className="form" onSubmit={(event) => handleSubmit(event)}>
                        <div className="justify-content-center align-items-center">
                            <div className="delete-modal-icon-container">
                                <Icon  className="icon-delete" name="warning circle" />
                            </div>
                            <p className="mb10">Are You Sure You Want To Delete This Status?</p>
                            <div className="delete-modal-buttons">
                                <Button secondary onClick={() => closeDeleteStatusModal()}>Cancel</Button>
                                <Button type={"submit"} primary>Confirm</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>: null
    )
}
