import {Status} from "../../model/oralSurgeryStatuses";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";

export const oralSurgeryStatuses= createReducer({}, {
    [ActionType.FETCH_ORAL_SURGERY_STATUSES](state: Status, action: Action<Status>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.SAVE_ORAL_SURGERY_STATUSES](state: Status, action: Action<Status>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.DELETE_ORAL_SURGERY_STATUSES](state: Status, action: Action<Status>) {
        return Object.assign({...state}, action.payload);
    },[ActionType.FETCH_BY_ID_ORAL_SURGERY_STATUS](state: Status, action: Action<Status>) {
        return Object.assign({...state}, action.payload);
    },[ActionType.UPDATE_ORAL_SURGERY_STATUS](state: Status, action: Action<Status>) {
        return Object.assign({...state}, action.payload);
    }
});