/**
 * simulate server latency (default is 1000 ms)
 * @param ms
 */
export const sleep = (ms: number = 1000) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * stringify a JSON object
 * @param o
 */
export const stringify = (o: Object) => JSON.stringify(o, null, 2);

export const sortBy = (dataArray: any[], key?: string) => {
  return dataArray.sort((a, b) => {
    return (key && key.length > 0) ? (a[key] > b[key]) ? 1 : -1 : (a > b) ? 1 : -1;
  });
  
};

export const verifyDefaultPractice = (practice: string) => {
  const str = practice;
  const arr = str.split(",");
  if (arr.length > 1) {
    const index = str.split(",").indexOf("all");
    if (index > -1) {
      return str.split(",").filter(function(value, index, arr) {
        return value !== 'all';
      });
    }
  }
  return str.split(",");
}

export const findPractice = (arr: Array<any>, value: string) => {
  let result;
  arr.forEach((o:any) => {
    if (o.practiceDBName === value|| o.practiceName === value 
      ||o.practiceabbr === value || o.practice === value) {
      result = o;
      return true;
    }
  });
  return result;
}

export const capitalizeFirstLetters = (s: string, n:number) => {
  if (s === 'referout'){
    return `Referal Out: ${n}`;
  } else if (s === 'referin') {
    return `Referal In: ${n}`;
  } else {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  
}

export const capitalizeFirstLetter = (s?: string) => {
  if (s===undefined) {
    return '';
  } else {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}

export const isBlankNullOrUndefined=(value:any)=>{
  if(value === '' || value ===undefined || value === null || value === {}) {
    return true
  } else {
    return false
  }
}

/**
 * Valid Array
 * @param arr 
 * @returns 
 */
 export const validArray = (arr: any) => arr && Array.isArray(arr) && !!arr.length;
