import { Practice, PracticeList, RegionsList, PracticeInfo } from "../../model/practice";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:Practice = {
	regionManagersOptions: [],
	schedulePMDDataOptions : [],
	practiceOptions: [],
	regionPractices: [],
	regionOptions: [],
	practiceAbbr: {},
	drPractices: {},
	practiceInfo : {},
	practiceNames : [],
	practiceList: [],
	practiceListAll: [],
	regionList: [],
	practiceInfoList: []
}
export const practice = createReducer(initialState, {
	[ActionType.FETCH_PRACTICES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_PRACTICES](state: PracticeList, action: Action<PracticeList>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_ALL_PRACTICES](state: PracticeList, action: Action<PracticeList>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PRACTICES_INFO](state: PracticeInfo, action: Action<PracticeInfo>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REGIONS](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REGIONMANAGERS](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PMD_SCHEDULES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	}
});
