import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as PCOrthoAction from "../redux/actions/procedureCodes";
import {useActions} from "../redux/actions";
import {ProcedureCodeLists, ProcedureCode, ProcedureCodeDetails} from "../model/procedureCodeList";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Icon} from "semantic-ui-react";
import DynamicHtmlTable from "./DynamicHtmlTable";
import AddProcedureCodeList from "./AddProcedureCodeList";
import PopUpModal from "./PopUpModal";
import HelpMark from "./Help/HelpMark";
import {HELP_PAGE} from "../constants";
import CountUpTimer from "./CountUpTimer";


function ProcedureCodeList(props: ProcedureCodeLists) {
    const ProcedureCodesAction = useActions(PCOrthoAction);
    const initialState: ProcedureCode = {
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        column:-1,
        direction:'sort',
        searchKeyword:''
    };
    const initialFormState:ProcedureCodeDetails ={procedureCode: '', description: ''};
    const [state, setStates] = useState(initialState);
    const [formState, setFormStates] = useState({showForm:false, id:0});
    const [initialValue, setFormInitialStates] = useState(initialFormState);
    const [deletePopUpState, setDeletePopUpState]= useState({showPopUp:false, id:0});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
       getProcedureCodeDetails();
    }, []);


    const getProcedureCodeDetails = () => {

        setLoading(true);
        ProcedureCodesAction.fetchProcedureCodeListOrtho().then((res:[]) => {
            if(res && res.length) {
                const TableData = pushTableData(res);
                const TableHead = TableHeadData();
                setStates({...state, TableData, TableHead});
            }
            setLoading(false);
        });

    };

    const onCancel = ()=>{
        setFormStates( {showForm:false, id:0});
        setFormInitialStates(initialFormState);
    };

    const saveProcedureCodeDetails = (values:ProcedureCodeDetails)=>{
        if(formState.id) {
            values.id=formState.id;

            setLoading(true);
            ProcedureCodesAction.updateProcedureCodeDetails(values).then((res:[])=> {
                getProcedureCodeDetails();
                setLoading(false);
            });
        } else {
            values.type = 'ortho';

            setLoading(true);
            ProcedureCodesAction.saveProcedureCodeDetails(values).then((res:[])=>{
                getProcedureCodeDetails();
                setLoading(false);
            });
        }
    };


    const editList = (item:ProcedureCodeDetails)=>{
        setFormStates({showForm:true, id:item.id||0});
        setFormInitialStates({procedureCode: item.procedureCode, description: item.description})
    };

    const deleteList = (id:number)=>{
        setDeletePopUpState({showPopUp:true, id:id});
    };

    const deletePopUpHandler = ()=>{

        setLoading(true);
        ProcedureCodesAction.deleteProcedureCodeDetails({id:deletePopUpState.id}).then((res:[])=> {
            
            setLoading(false);
            getProcedureCodeDetails();
        });
        setDeletePopUpState({showPopUp:false, id:0});
    };

    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Procedure Code'};
        TableHead[1] = {title: 'Description'}
        TableHead[2] = {title: ''}
        return TableHead;
    };

    const pushTableData = (procedureCodeDetails:[]) => {
        const TableData = new Array();
        procedureCodeDetails.forEach((item: any) => {
                const editButton=<><Icon onClick={()=>editList(item)} className='mr10' name='edit' /><Icon onClick={()=>deleteList(item.id)} name='delete' /></>;
                TableData.push([
                    {title: item.procedureCode},
                    {title: item.description},
                    {title: editButton}
                ])
            });
        return TableData;
    };
    return (
        <Template activeLink='ProcedureCodeList'>
            {
                loading &&
                <CountUpTimer />
            }
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        Procedure Code List Ortho
                        <HelpMark pageId={HELP_PAGE.REFERRALS_SETUP_ORTHO_PROC_CODE}/>
                    </h2>
                    <p/>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    { !formState.showForm &&
                        <Button primary onClick={() => setFormStates({...formState, showForm:true})}>Add Codes</Button>
                    }
                    {(formState.showForm) &&
                        <AddProcedureCodeList initialValue={initialValue}
                                      onCancel={onCancel} saveProcedureCodeDetails={saveProcedureCodeDetails}/>
                    }
                </div>
            </div>
            <div className="table-adjAcknowledgement">

            <DynamicHtmlTable
                key={0}
                className={''}
                tableHead={state.TableHead}
                heading={""}
                tableData={state.TableData}
            />
            </div>
            { deletePopUpState.showPopUp &&
                <PopUpModal headerText="Delete Selected Record" message="Are you sure you want to delete?" size='mini' hidePopUpModal={()=>setDeletePopUpState({showPopUp:false, id:0})} deletePopUpHandler={deletePopUpHandler} showLabel={true} action='delete'/>
            }
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        procedureCodesOrtho: state.procedureCodesOrtho
    };
}
export default connect(mapStateToProps)(ProcedureCodeList);

