import React, {useEffect, useState} from 'react';

function OralSurgerySetupX(props: any) {

    const notGrey="star-not-grey";
     const grey="star-grey";
    return (
        <div className="calendar-container">
          <div className="row">
            <div className="col-12">
              <h2 className="float-left">Oral Surgery Setup</h2>
              <div className="float-right mb10 topDropdown careTop">
               
              </div>
            </div>
          </div>
          <div className="ui card">
            <div className="content">
             
            </div>
          </div>
        </div>
    )
}

export default OralSurgerySetupX;