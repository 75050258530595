import React, { Component } from 'react'
import moment from 'moment'
import { Button, Modal} from 'semantic-ui-react'
import {  sendDrNote } from '../../redux/actions/billing';

interface DrNoteModalProps {
    note:any
    selectedPracticeName:any
    selectedPatient:any
    hideDrModal:Function
}

const DrNoteModal: React.FC<DrNoteModalProps> = (props) => {

 const sendMail=()=>{
     alert('Sending email...')
     const {note,selectedPracticeName,  selectedPatient} =props;
     let title=`Dr Note ${selectedPracticeName} ${selectedPatient.PatNum} ${selectedPatient.LastVisit}\r`
     sendDrNote({drNotes:title+'\r'+note})
 }
    
        const {note,selectedPracticeName,  selectedPatient} =props;
        const noteArr=note.split(/(\r\n|\n|\r)/gm)
        return(
            <Modal
                trigger={<Button>Scrolling Content Modal</Button>}
                open={true}
                onClose={() => props.hideDrModal()}
                centered={false}
                style = {{ overflow: 'auto' }}
                closeIcon
                size={ "large" }
                className={  "" }
            >
                    <Modal.Header>Dr Note {selectedPracticeName} {selectedPatient.PatNum} ({selectedPatient.LastVisit}) </Modal.Header>

                <Modal.Content scrolling className="pt-0">
                    <Modal.Description className="mt20">
                        {noteArr.map((item:any)=>item.length>2?<div>{item}</div>:"")}
                    </Modal.Description>
                </Modal.Content>


                <Modal.Actions>
                    <Button primary className="mr10" onClick={ () =>sendMail() }>
                        Send
                    </Button>
                </Modal.Actions>

            </Modal>
        );
    
}

export default DrNoteModal;