import { Report } from "../../model/report";
import { App } from "../../model";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:Report = {
	reportData: null,
	reportPerPracticeData: null,
	reportDataLastMonths: null,
	scheduleData: null,
	budgetData: null,
	budgetDataPerPractice: null,
	aptData:null,
    clinicalScheduleData: {},
	oSPCRData: {},
	patientValidationData:{},
	loginVia: '',
	mdhWriteOffReport : {},
	mdhWriteOffDReport:{},
	referOutStats: {},
	providerList: []
};
export const report = createReducer(initialState, {
	[ActionType.FETCH_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REPORT_PER_PRACTICE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REPORT_LAST_MONTHS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_SCHEDULE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_BUDGET](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_BUDGET_PER_PRACTICE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
    [ActionType.FETCH_CLINICAL_SCHEDULE](state: Report, action: Action<Report>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.SEND_REPORT](state: Report, action: Action<Report>) {
        return Object.assign({...state}, action.payload);
    },
	[ActionType.FETCH_OPEN_SURGERY](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_APT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.SAVE_USAGE_PTV](state: Number, action: Action<App>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.UPDATE_OS_BUDGET](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PATIENT_VALIDATION_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.MDHM_WRITE_OFF_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.MDHM_WRITE_OFF_D_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REFER_OUT_STATS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PROVIDER_LIST](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	}
});
