import React, {useEffect, useState} from "react";
import {useActions} from "../../redux/actions";
import moment from "moment";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {PracticeList} from "../../model/practice";
import * as practiceActions from "../../redux/actions/practice";
import * as referralActions from "../../redux/actions/referrals";
import HelpMark from "../Help/HelpMark";
import {HELP_PAGE, aptStatus} from "../../constants";
import {Button, Dimmer, Dropdown, Form, Grid, Icon, Input, Loader, Modal, Pagination, Table} from "semantic-ui-react";
import {ReferalExt, Referral} from "../../model/referral";
import PatientCallLog from "../PatientCallLog";
import PatientInsight from "../PatientInsight";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FindMeModal from "./FindMeModal";
import * as billingActions from '../../redux/actions/billing';
import FamilyView from "../BillingDetailsModal/FamilyView";
import { capitalizeFirstLetter, findPractice, verifyDefaultPractice } from "../../utils/common";
import * as statusActions from "../../redux/actions/oralSurgeryStatuses";
import _ from 'lodash';
import { StatusType } from "../../model/StatusType";
import * as Actions from "../../redux/actions/userSetup";
import { Patients } from "../../model/patients";
import { SetupFields } from "../../enums/userSetpField";
import { SetupAppName } from "../../enums/userSetup";
import { UserSetup } from "../../model/userSetup";
import { statusId } from "../../enums/statusId";

interface ReferralWorkProps {
    referrals: Array<Referral & ReferalExt>;
    practiceList: Array<PracticeList>;
    practiceListAll: Array<PracticeList>;
    openFamilyView?: Function;
    familyData: any;
    auth: any;
    userSetup: UserSetup;
}

const maxRows = 10;

const ReferralWork = (props: ReferralWorkProps) => {
    const practiceAction = useActions(practiceActions);
    const referralAction = useActions(referralActions);
    const billingAction = useActions(billingActions);
    const StatusActions = useActions(statusActions);
    const userSettingsActions = useActions(Actions);
    const [oralSurgeryStatus, setOralSurgeryStatus] = useState({});
    const initialFilter: any = {
        startDate: new Date(moment().startOf('month').toString()),
        endDate: new Date(moment().endOf('month').toString()),
    };
    const initialPracticeFilter: any = {
        practice: [],
    };
    const [filter, setFilter] = useState(initialFilter);
    const [practiceFilter, setPracticeFilter] = useState(initialPracticeFilter);

    useEffect(() => {
        setLoading(true);
        loadReferral();
        StatusActions.fetchOralSurgeryStatusWork().then((res: any) => {
            setOralSurgeryStatus(res);
        })
    }, [filter]);

    const initialState :any = {modalOpen: false, activePage: 1 as any, editItem: null as any,selectedPT_Num: null,stateHistory: [] as any, detailsModalType: '', detailsModal: false, selectedPractice: '', selectedPracticeName: '', column: null, direction: 'descending',};
    const [state, setState] = useState(initialState);



    const loadReferral = async (defaultstringPractice?: string[]) => {
        let userSetup = props.userSetup;
        let defaultPractices: any = [];
        let practiceListAll = props.practiceListAll;
        if (!practiceListAll || practiceListAll.length === 0) {
            practiceListAll = await practiceAction.fetchAllPractices({ type: 'all' });
        }
        if (!props.userSetup.value) {
            let userParams = { field: SetupFields.Practice, appId: SetupAppName.FIELD };
            userSetup = await userSettingsActions.getSetup(userParams);
            if (userSetup && userSetup.value !== undefined) {

                const practiceOptions = userSetup.value.split(',');
                practiceOptions.forEach((practiceOption: any) => {
                    if (practiceOption !== 'all') {
                        const practice = findPractice(practiceListAll, practiceOption) || { practice: 'all' };
                        if (practice.practice !== 'all') {
                            defaultPractices.push(practice.practice)
                        }
                    }
                })

            }
        } else {
            if (practiceFilter.practice.length !== 0 || defaultstringPractice !== undefined && defaultstringPractice.length !== 0) {
                if(defaultstringPractice !== undefined && defaultstringPractice[0] !== "All"){
                    defaultPractices = defaultstringPractice;
                } if(defaultstringPractice===undefined) {
                    defaultPractices = practiceFilter.practice;
                }
            } else {
                const practiceOptions = userSetup !== undefined && userSetup.value !== undefined ? userSetup.value.split(',') : ['all'];
                practiceOptions.forEach((practiceOption: any) => {
                    if (practiceOption !== 'all') {
                        const practice = findPractice(practiceListAll, practiceOption) || { practice: 'all' };
                        if (practice.practice !== 'all') {
                            defaultPractices.push(practice.practice)
                        }
                    }
                })

            }

        }

        if(defaultPractices.length === 0 && defaultstringPractice !== undefined && defaultstringPractice[0] !== "All") {
            defaultPractices = practiceFilter.practice;
        }
        let params = {
            startDate: moment(filter.startDate).format('Y-MM-DD'),
            endDate: moment(filter.endDate).format('Y-MM-DD'),
            filterPractice: defaultPractices,
        }
        if (Array.isArray(params.filterPractice) && params.filterPractice.length === 0) {
            params.filterPractice = ['All']
        }
        params.filterPractice = params.filterPractice.join(',');
        referralAction.searchReferral(params).finally(() => setLoading(false));
        setPracticeFilter({ ...practiceFilter, practice: defaultPractices });
    
    }

    const getStatusX = (id: number, type: string, specialty: string) => {
        let stat = {
            statusColor: '',
            referStatus: '',
            type: '',
            sequence: ''
        };
        let defaultStat = {
            statusColor: '',
            referStatus: '',
            type: '',
            sequence: ''
        }

        if(type = 'refIn') {
            Object.values(oralSurgeryStatus).forEach((item: any) => {
                if(item.type === "oral" && item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started") {
                    defaultStat = item;
                }
                if(item.type === "ortho" && item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started"){
                    defaultStat = item;
                }
                if(item.referStatus!== null && item.referStatus.toLowerCase().trim() === "not started"){
                    defaultStat = item;
                }
    
                if(item.id === id) {
                    stat = item
                }
                if((id === undefined || id === null) && specialty === 'ortho' && item.sequence=== 0) {
                    stat = item;
                }
            })
        } else {
            aptStatus.forEach((statusType: StatusType) => {
                if(statusType.id === 0) {
                    defaultStat.referStatus = statusType.referStatus;
                    defaultStat.statusColor = statusType.statusColor;

                }
                if(statusType.id === id) {
                    stat.referStatus = statusType.referStatus;
                    stat.statusColor = statusType.statusColor;
                }
            }); 
        }

        
        if(id === undefined || id === null) {
            return (
                <div
                    className="roundedButton"
                    style={{background: defaultStat ? defaultStat.statusColor : ''}}
                >
                    <div className="roundedButtonText">{defaultStat.referStatus}</div>
                </div>
            )
        }
        else {
            return (
                <div
                    className="roundedButton"
                    style={{background: stat ? stat.statusColor : ''}}
                >
                    <div className="roundedButtonText">{stat.referStatus}</div>
                </div>
            )
        }
    }

    const onSearch = (searchData: any) => {
        toggleModal(false);
        if (!searchData) return;
        setLoading(true);
        const startDate = moment('2000-01-01');
        const endDate = moment();
        let params = {
            startDate: startDate.format('Y-MM-DD'),
            endDate: endDate.format('Y-MM-DD'),
            ...searchData
        }
        referralAction.searchReferral(params).finally(() => setLoading(false));
    }

    const onParamsChange = (name: string, value: any) => {
        setState({...state, activePage: 1});
        if(name === 'practice'){
            if(value.length < 1){
                value = ['All'];
            }
            loadReferral(value);
        } else if (name === 'type' || name === 'status') {
            setPracticeFilter({...practiceFilter, [name]: value});
        }else {
            setFilter({...filter, [name]: value});
        }
    }

    const onEdit = (data: any) => {
        const regExp = /[a-sA-S]/g;
        if(regExp.test(data.refOutDate)){
            data.refOutDate = new Date(moment().toString())
            setState({ ...state, editItem: data });
        } else {
            data.refOutDate = new Date(moment(data.refOutDate).toString())
            setState({ ...state, editItem: data });
        }
        
    }

    const onEditItemChange = (name: string, value: any) => {
        let {editItem} = state;
        editItem[name] = value;
        setState({...state, editItem})
    }


    const openFamilyView = async (selectedPT_Num: any, practice: any, practiceName: any) => {
        if (state.selectedPT_Num !== selectedPT_Num || state.selectedPractice !== practice)
            await billingAction.getFamilyPatients(selectedPT_Num, practice);

        const stateObj = {
            detailsModal: 'familyView',
            detailsModalType: 'familyView',
            selectedPT_Num: selectedPT_Num,
            selectedPractice: practice,
            selectedPracticeName: practiceName
        }
        if (state.stateHistory !== null && state.stateHistory !== undefined && (!state.stateHistory[state.stateHistory.length - 1] ||
            state.stateHistory[state.stateHistory.length - 1].detailsModalType !== 'familyView'))
            state.stateHistory.push(stateObj);
        const selectedPractice = (props.practiceListAll || []).find(f => f.practice == practice || f.practice == practiceName);
        setState({
            ...state,
            detailsModalType: 'familyView',
            selectedPT_Num: selectedPT_Num,
            selectedPractice: (selectedPractice || {}).practiceDBName || practice,
            selectedPracticeName: practiceName,
            stateHistory: state.stateHistory
        });
    }

    const updateUserInfo = (patientCallLog:any, dataKey:number,PatNum_Date:string, stateLog:any) => {
        let editItem:any = props.referrals.find((x: any) => x.PatNum_Date === PatNum_Date);
        editItem.key = dataKey;
        editItem.user = props.auth.user.username;
        editItem.note = patientCallLog.notes;
        editItem.refInPatId = stateLog.refInPatId === undefined ? '': stateLog.refInPatId;
        editItem.statusId = stateLog.statusId === undefined ? 0 : stateLog.statusId;
        editItem.refInPractice = stateLog.refInPractice === undefined ? '' : stateLog.refInPractice;
            submitChange(editItem);
        
    }

    const submitChange = (editItem:any) => {
        let updateObj = {} as any;
        const fields = ['PatNum_Date', 'speciality', 'refOutPractice', 'refOutDate', 'refInPractice', 'refOutDate',
            'aptStatus', 'callLog', 'insights', 'care', 'refInPatId', 'statusId'];
        fields.forEach((field: string) => updateObj[field] = editItem[field] !== null 
        && editItem[field] !== '' ? editItem[field] : undefined);
        referralAction.saveReferalsExt(updateObj);
        setState({...state, editItem: null});
    }
    
    const dropDownStatus = (typeParam: any) => {
        let stats: any = [];
        let type = practiceFilter.type === undefined || practiceFilter.type === '' ? typeParam : practiceFilter.type;
        Object.values(oralSurgeryStatus).forEach((item: any) => {
            if(item.type === type) {
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus
                })
            }
            else if (item.type === type) {
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus
                })
            }
         if(type=='n/a' && typeof(item.type)==='string' && item.referStatus!==null){
            const customStyle = item.type === 'ortho' ? {background: '#2986cc'} : {background: '#ef5d53'};
                stats.push({
                    key: item.id,
                    value: item.id,
                    text: item.referStatus,
                    style: customStyle
                })
            }
        });
        return stats;
    }
    const onChangeDrop = (e: any, a: any) => {
        let editItem = state.editItem;
        editItem[a.name] = a.value;
        setState({ ...state, editItem });
    };

    const submitEdit = () => {
        let updateObj = {} as any;
        const fields = ['PatNum_Date', 'speciality', 'refOutPractice', 'refOutDate', 'refInPractice', 'refOutDate',
            'aptStatus', 'callLog', 'insights', 'care', 'refInPatId', 'statusId'];
        fields.forEach((field: string) => updateObj[field] = state.editItem[field] !== null && state.editItem[field] !== '' ? state.editItem[field] : undefined);
        referralAction.saveReferalsExt(updateObj);
        setState({...state, editItem: null});
    }

    const getRow = (data: any, key: number) => {
        data.key = key;
        const options = dropDownStatus(data.speciality);
        return <Table.Row key={`row_${key}`}>
            <Table.Cell>
                {
                    state.editItem && state.editItem.key === key ?
                        <Button
                            size='tiny'
                            color='orange'
                            content='Update'
                            onClick={() => submitEdit()}
                        /> :
                        <Button
                            size='mini'
                            secondary={true}
                            icon='edit'
                            onClick={() => onEdit(data)}
                        />
                }
            </Table.Cell>
            <Table.Cell>{data.PatFName} {data.PatLName}</Table.Cell>
            <Table.Cell><span style={{textTransform: 'capitalize'}}>{data.Practice}</span></Table.Cell>
            <Table.Cell>{<td className="primaryColor cursorPointer"  onClick={() =>openFamilyView(data.PatNum, data.Practice, data.Practice)}>{ data.PatNum }</td>}</Table.Cell>
            <Table.Cell>
                {
                    state.editItem && state.editItem.key === key ?
                        <Dropdown
                            clearable={true}
                            options={practiceOptionsEdit}
                            value={state.editItem.refInPractice}
                            search={true}
                            selection={true}
                            onChange={(e, data) => onEditItemChange('refInPractice', data.value)}
                        /> :
                        data.refInPractice
                }
            </Table.Cell>
            <Table.Cell>
                {
                    state.editItem && state.editItem.key === key ?
                        <Input
                            value={state.editItem.refInPatId}
                            onChange={(e, data) => onEditItemChange('refInPatId', data.value)}
                        /> :
                        <td className="primaryColor cursorPointer"  onClick={() =>openFamilyView(data.refInPatId, data.refInPractice, data.refInPractice)}>{ data.refInPatId }</td>
                }
            </Table.Cell>
            <Table.Cell className="statusSetupCell">{
                state.editItem && state.editItem.key === key  ?
                    <Dropdown
                        search
                        className='mr10 mb15'
                        name="statusId"
                        placeholder="Status"
                        selection
                        options={options}
                        onChange={(e, a) => onChangeDrop(e, a)}
                    /> :
                    getStatusX(data.statusId, 'refIn', data.speciality)

            }</Table.Cell>
            <Table.Cell>{data.user}</Table.Cell>
            <Table.Cell>{data.note}</Table.Cell>
            <Table.Cell>{data.procat}</Table.Cell>
            <Table.Cell><PatientCallLog patientId={data.PatNum} refInPatId={data.refInPatId} practice={data.Practice} speciality={data.speciality} 
            updateUserInfo={updateUserInfo} dropDownStatus={options} practiceOptionsEdit={practiceOptionsEdit} statusId={data.statusId} 
            dataKey={data.key} PatNum_Date={data.PatNum_Date} refInPractice={data.refInPractice}/></Table.Cell>
            <Table.Cell><PatientInsight patientId={data.PatNum} practice={data.Practice}/></Table.Cell>
            <Table.Cell>{data.updatedAt}</Table.Cell>
            <Table.Cell>{data.ProcDate}</Table.Cell>
            {data.speciality === 'oral' ? <Table.Cell>Oral Surgery</Table.Cell> : <Table.Cell>{capitalizeFirstLetter(data.speciality)}</Table.Cell>}
            <Table.Cell>
                {
                    state.editItem && state.editItem.key === key ?
                        <Form>
                            <Form.Field className='mw-120'>
                                <DatePicker
                                    selected={state.editItem.refOutDate}
                                    dateFormat='yyyy-MM-dd'
                                    onChange={(date) => onEditItemChange('refOutDate', date)}
                                />
                            </Form.Field>
                        </Form> :
                        moment(data.refOutDate).format('YYYY-MM-DD')
                }
            </Table.Cell>
            <Table.Cell className="statusSetupCell">{getStatusX(data.aptStatus, 'refOut', data.speciality)}</Table.Cell>
            <Table.Cell>{data.External_Referral !== null ? data.External_Referral : ""}</Table.Cell>
            <Table.Cell>{data.care}</Table.Cell>
        </Table.Row>;
    }

    const practiceOptions = props.practiceListAll.map((practice) => ({
        key: practice.id,
        value: practice.practice,
        text: practice.practice,
    }));

    const practiceOptionsEdit = props.practiceListAll.map((practice) => ({
        key: practice.id,
        value: practice.practice,
        text: practice.practice,
    }));

    const typeOptions = ['Ortho', 'Oral'].map((type, key) => (
        
        {
        key: key,
        value: type.toLowerCase(),
        text: type,
        style: type.toLowerCase() === 'ortho' ? {background: '#2986cc'} : {background: '#ef5d53'}
        }
       ));
       
    const toggleModal = (toggle: boolean) => {
        setState({...state, modalOpen: toggle})
    }

    const getPaginated = () => {
        
        if (!(props.referrals && props.referrals.length > 0)) return [];
        const start = (state.activePage - 1) * maxRows;
        const end = start + maxRows;
        return filtered().slice(start, end);
    };

    let direction2= state.direction === 'descending' ? 'down' : 'up';;


    const filtered = () => {
        let { direction, column } = state;
        column = column === undefined ? null : column;
        if(!practiceFilter.status && !practiceFilter.type && column !== null && direction === 'descending')
            return _.sortBy(props.referrals, [column])
        else if(!practiceFilter.status && !practiceFilter.type && column !== null && direction === 'ascending')
            return props.referrals.sort((a:any, b:any) => {
                if (a[column] > b[column])
                    return -1;
                if (a[column] < b[column])
                    return 1;
                return 0;
            });
        else if(!practiceFilter.status && practiceFilter.type && column !== null && direction == 'ascending') {
            return props.referrals.sort((a:any, b:any) => {
                if (a[column] > b[column])
                    return -1;
                if (a[column] < b[column])
                    return 1;
                return 0;
            }).filter(item => item.speciality === practiceFilter.type);
        }
        else if (practiceFilter.type && column !== null && direction === 'descending')
            return _.sortBy(props.referrals.filter((f: any) => f.speciality === practiceFilter.type), [column]);
        else if (!practiceFilter.status && !practiceFilter.type && column === null) 
            return props.referrals;
        else if(practiceFilter.status && column===null){
           return  props.referrals.filter((f: any) => {
               if(practiceFilter.status=== statusId.OralNotStarted){
                   return f.statusId===null || f.statusId===undefined || f.statusId === practiceFilter.status
               } 
               if(f.statusId===null || f.statusId===undefined && f.speciality === 'ortho'){
                f.statusId = statusId.OrthoNotstarted;
                return f.statusId === practiceFilter.status;
               }
               if(f.statusId===null || f.statusId===undefined && f.speciality === 'oral'){
                f.statusId = statusId.OralNotStarted;
                return f.statusId === practiceFilter.status;
               }
                return f.statusId === practiceFilter.status;
            })
        }
        else 
            return props.referrals.filter((f: any) => f.speciality === practiceFilter.type).sort((a:any, b:any) => {
                if (a[column] > b[column])
                    return -1;
                if (a[column] < b[column])
                    return 1;
                return 0;
            });    
    }
    const hideDetailsModal = () => {
        setState({...state, detailsModalType: ''});
    }

    const setLoading = (loading: boolean) => {
        setState({...state, loading})
    }

    const changeTab = (selectedPT_Num: any) => {
        if (state.selectedPT_Num !== selectedPT_Num)
            billingActions.getFamilyPatientDetails(selectedPT_Num, state.selectedPractice);
        setState({ 'selectedPT_Num': selectedPT_Num });
    }

    const handleSort = (clickedColumn: any) => () => {
        const { column, direction } = state;
        if (column !== clickedColumn) {

            setState({
                ...state,
                column: clickedColumn,
                direction: 'ascending',
            });

            return
        }

        setState({
            ...state,
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };
   

    return (
        <div>
            {
                state.modalOpen &&
                <FindMeModal onSearch={onSearch} onClose={() => toggleModal(false)} practices={practiceOptions}/>
            }
            {
                state.detailsModalType === 'familyView' &&
                <Modal
        open={true}
        onClose={hideDetailsModal}
        centered={false}
        style = {{ overflow: 'hidden' }}
        closeIcon
        size={ "fullscreen" }
        className={ "familyView" }
      >
        { state.detailsModalType === 'familyView' ?
            <Modal.Header
                size={'tiny'}
            >
                Patient Family View
            </Modal.Header>
          : <Modal.Header></Modal.Header>}

        <Modal.Content
            scrolling
            className="pt-0"
            size={'tiny'}
        >
        {
            <div className="table-responsive overflow-unset">
              {
                state.detailsModalType === 'familyView' ? <FamilyView selectedPractice={state.selectedPractice} selectedPT_Num={state.selectedPT_Num} selectedPracticeName={state.selectedPracticeName} BrokenChildTable={props.familyData} BrokenChild={props.familyData.brokenListChild} changeTab={changeTab} /> :
                ''
              }
            </div>
        }
        </Modal.Content>
      </Modal>
            }
            <Grid>
                <Grid.Row>
                    <Grid.Column floated='left' width='16'>
                        <h2><span className='float-left'>All Referrals ({filtered().length})
                            <HelpMark pageId={HELP_PAGE.REFERRALS_ALL}/></span></h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width='16'>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label><Icon name='calendar alternate'/> Start Date</label>
                                    <DatePicker
                                        selected={filter.startDate}
                                        dateFormat='yyyy-MM-dd'
                                        onChange={(date) => onParamsChange('startDate', date)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><Icon name='calendar alternate'/> End Date</label>
                                    <DatePicker
                                        selected={filter.endDate}
                                        dateFormat='yyyy-MM-dd'
                                        onChange={(date) => onParamsChange('endDate', date)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><Icon name='hospital outline'/> Practice</label>
                                    <Dropdown
                                        clearable={true}
                                        options={practiceOptions}
                                        value={practiceFilter.practice}
                                        search={true}
                                        selection={true}
                                        multiple={true}
                                        onChange={(e, data) => onParamsChange('practice', data.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><Icon name='options'/>Specialty</label>
                                    <Dropdown
                                        clearable={true}
                                        options={typeOptions}
                                        value={practiceFilter.type}
                                        search={true}
                                        selection={true}
                                        onChange={(e, data) => onParamsChange('type', data.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><Icon name='options'/> Status</label>
                                    <Dropdown
                                        clearable={true}
                                        options={dropDownStatus('n/a')}
                                        value={practiceFilter.status}
                                        search={true}
                                        selection={true}
                                        onChange={(e, data) => onParamsChange('status', data.value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <br/>
                                    <Button className='float-right' secondary={true} onClick={() => toggleModal(true)}>
                                        <Icon name='search'/> Find Me
                                    </Button>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table size='small' striped={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <Table.HeaderCell className={`cursorPointer ${state.column === 'PatLName' ? 'active' : ''}`} onClick={handleSort('PatLName')}>Patient<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '170px' }} className={`cursorPointer ${state.column === 'Practice' ? 'active' : ''}`} onClick={handleSort('Practice')}>Refer Out Practice<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '170px' }} className={`cursorPointer ${state.column === 'PatNum' ? 'active' : ''}`} onClick={handleSort('PatNum')}>Refer Out PatNum<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '150px' }} className={`cursorPointer ${state.column === 'refInPractice' ? 'active' : ''}`} onClick={handleSort('refInPractice')}>Refer In Practice<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '150px' }} className={`cursorPointer ${state.column === 'refInPatId' ? 'active' : ''}`} onClick={handleSort('refInPatId')}>Refer In PatNum<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell className={`cursorPointer ${state.column === 'statusId' ? 'active' : ''}`} onClick={handleSort('statusId')}>Status<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '120px' }} className={`cursorPointer ${state.column === 'user' ? 'active' : ''}`} onClick={handleSort('user')}>Username<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell className={`cursorPointer ${state.column === 'note' ? 'active' : ''}`} onClick={handleSort('note')}>Notes<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '170px' }} className={`cursorPointer ${state.column === 'procat' ? 'active' : ''}`} onClick={handleSort('procat')}>Procedure Category<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell>Call Log</Table.HeaderCell>
                        <Table.HeaderCell>Insights</Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '150px' }} className={`cursorPointer ${state.column === 'updatedAt' ? 'active' : ''}`} onClick={handleSort('updatedAt')}>Last Contact<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '150px' }} className={`cursorPointer ${state.column === 'ProcDate' ? 'active' : ''}`} onClick={handleSort('ProcDate')}>Reffered Date<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '120px' }} className={`cursorPointer ${state.column === 'speciality' ? 'active' : ''}`} onClick={handleSort('speciality')}>Speciality<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '120px' }} className={`cursorPointer ${state.column === 'refOutDate' ? 'active' : ''}`} onClick={handleSort('refOutDate')}>Apt Date<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '120px' }} className={`cursorPointer ${state.column === 'aptStatus' ? 'active' : ''}`} onClick={handleSort('aptStatus')}>Apt Status<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '150px' }} className={`cursorPointer ${state.column === 'External_Referral' ? 'active' : ''}`} onClick={handleSort('External_Referral')}>External Referral<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                        <Table.HeaderCell className={`cursorPointer ${state.column === 'care' ? 'active' : ''}`} onClick={handleSort('care')}>Care<i className={`sort amount ${direction2} icon ml-05`}></i></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        getPaginated().map((data, key) => getRow(data, key))
                    }
                </Table.Body>
            </Table>

            <br/>
            <Pagination
                onPageChange={(e, data) => setState({...state, activePage: data.activePage})}
                defaultActivePage={state.activePage}
                totalPages={Math.ceil(filtered().length / maxRows)}
            />
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        referrals: state.referrals.referrals,
        practiceList: state.practice.practiceList,
        practiceListAll: state.practice.practiceListAll,
        familyData: state.billing.familyData,
        auth: state.auth,
        userSetup: state.userSetup
    };
}

export default connect(mapStateToProps)(ReferralWork);
