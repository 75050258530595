import React from "react";
import { Button, Icon, Form } from 'semantic-ui-react';
import ColorPicker from "react-pick-color";
import * as statusActions from "../redux/actions/oralSurgeryStatuses";
import {useActions} from "../redux/actions";

export const EditStatusModal = (
    props: {
        openEditStatusModal: any,
        setOpenEditStatusModal: any,
        setOralEditSurgeryStatus: any,
        id: any,
        data: any,
        setData: any,
        type: String}
) => {
    const StatusActions = useActions(statusActions);
    const modalRef = React.useRef<HTMLDivElement>(null);

    const closeSaveStatusModal = () => {
        props.setOpenEditStatusModal(false);
    }

    const closeModalByRef = (e: any) => {
        if(modalRef.current === e.target)
            props.setOpenEditStatusModal(false);
    }

    const handleSubmit = (event: any) => new Promise((resolve, reject) => {
        event.preventDefault();
        const payload = {
            sequence: props.data.sequence,
            referStatus: props.data.referStatus,
            statusColor: props.data.statusColor,
            type: props.type,
            id: props.id
        }
        StatusActions.updateOralSurgeryStatus(payload).then((res : any) => {
            StatusActions.fetchOralSurgeryStatus().then((res : any) => {
                props.setOralEditSurgeryStatus(res);
                props.setOpenEditStatusModal(false);
            })
        })
    });

    return(
        props.openEditStatusModal ? <>
            <div className="modalContainer" ref={modalRef} onClick={(e) => closeModalByRef(e)}>
                <div className="modalWrapper">
                    <Icon className="close-btn float-right mt-5 mr-5 red cursorPointer" name="close" onClick={() => closeSaveStatusModal()} />
                    <Form className="form" onSubmit={(event) => handleSubmit(event)}>
                        <div className="vertical-align mr20">
                            <Form.Field>
                                <label>SEQUENCE</label>
                                <input
                                    className='form-control'
                                    type={"number"}
                                    placeholder="0"
                                    min="0"
                                    value={props.data.sequence}
                                    required
                                    onChange={(e) => props.setData({...props.data, sequence: e.target.value})}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>REFER STATUS</label>
                                <input
                                    className='form-control'
                                    type={"text"}
                                    placeholder="status"
                                    value={props.data.referStatus}
                                    required
                                    onChange={(e) => props.setData({...props.data, referStatus: e.target.value})}
                                />
                            </Form.Field>
                            <Button type={"submit"} primary>Save</Button>
                        </div>

                        <ColorPicker
                                hideAlpha={true}
                                className="colorPicker mr10"
                                color={props.data.statusColor}
                                onChange={(color) => {
                                    props.setData({...props.data, statusColor: color.hex})
                                }
                            }
                        />
                    </Form>
                </div>
            </div>
        </>: null
    )
}
