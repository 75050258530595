import React, { Component } from 'react'
import moment from 'moment'
import { Button, Modal, ModalHeader} from 'semantic-ui-react'
import ReferralOutTableData from './ReferralOutTableData'
import FamilyView from './BillingDetailsModal/FamilyView';

interface Props {
	hideDetailsModal : any
    detailsModalType: any
    detailsModal: any
    onSelectEvent: any
    sendReport: any
    reportData: any
    eventClick: any
    eventDate: any
    productionPrice: any
    startDate: any
    showPracticeList: any
    practicePriceList: any
    todayProduction: any,
    practiceList: any,
    openFamilyView?:Function
    selectedPractice?:any
    selectedPT_Num?:any
    selectedPracticeName?:any
    familyData?:any
    changeTab?:any
    statuses: any
    auth:any
}

function ReferralDetailsModal(props: Props) {
    const { reportData, detailsModalType, detailsModal, eventDate, startDate, practicePriceList, practiceList, showPracticeList } = props;
    let { eventClick, productionPrice} = props;

  let tableData: any = {}
  if (reportData && detailsModalType === 'oralRefOut') {
    tableData = reportData['oralRefOut'];
  } else if (reportData && detailsModalType === 'orthoRefOut') {
    tableData = reportData['orthoRefOut'];
  } else if (reportData && detailsModalType === 'oralRefIn') {
    tableData = reportData['oralRefIn'];
  } else if (reportData && detailsModalType === 'orthoRefIn') {
    tableData = reportData['orthoRefIn'];
  } else if (reportData && detailsModalType === 'referin') {
    tableData = reportData['referin'];
  } else if (reportData && detailsModalType === 'referout') {
    tableData = reportData['referout'];
  }
  else if (reportData && detailsModalType === 'earnings' && reportData['patients']) {
    tableData = reportData['patients'];
  } else if (detailsModal === 'report' && reportData === null) {
    tableData = {};
  } else if (detailsModalType === 'familyView') {

  }
  if (eventClick === 'day' && tableData && Object.keys(tableData).length && tableData[eventDate]) {
    tableData = { [eventDate]: tableData[eventDate] };
  } else if (eventClick === 'day' && tableData && Object.keys(tableData).length && !tableData[eventDate]) {
    tableData = {}
  }

    let tableDataArray: any= []
    if(tableData && Object.keys(tableData).length) {
      Object.keys(tableData).forEach( (key) => {
        tableData[key].forEach( (item:any) => {
          tableDataArray.push(item);
        });
      });
    }

    const getTitle = (detailsModalType: string) => {
      switch (detailsModalType) {
        case 'familyView':
          return 'Patient Family View';

        case 'oralRefOut':
          return 'Oral Surgery Referrals (Out)';

        case 'oralRefIn':
          return 'Oral Surgery Referrals (In)';

        case 'orthoRefOut':
          return 'Ortho Referrals (Out)';

        case 'referin':
          return 'Ortho Referrals (In)';

        case 'orthoRefIn':
          return 'Ortho Referrals (In)'
      
        default:
          return '';
      }
    }

    return(
      <Modal
        open={true}
        onClose={props.hideDetailsModal}
        centered={true}
        className={detailsModalType === 'familyView' ? 'onFamilyView' : ''}
        size={detailsModalType === 'familyView' ? 'fullscreen' : 'large'}
        closeIcon={true}
      >
        <ModalHeader>{getTitle(detailsModalType)}</ModalHeader>
        <Modal.Content>
            <div className="table-responsive overflow-unset">
              {
                detailsModalType === 'familyView' ?
                    <FamilyView selectedPractice={props.selectedPractice} selectedPT_Num={props.selectedPT_Num} selectedPracticeName={props.selectedPracticeName} BrokenChildTable={props.familyData} BrokenChild={props.familyData.brokenListChild} changeTab={props.changeTab} /> :
                    <ReferralOutTableData
                        detailsModal={detailsModal}
                        reportData={tableDataArray}
                        openFamilyView={props.openFamilyView}
                        practiceList={practiceList}
                        eventClick={eventClick}
                        detailsModalType={detailsModalType}
                        statuses={props.statuses}
                        auth={props.auth}
                    />
              }
            </div>
        </Modal.Content>

        {( tableData && Object.keys(tableData).length) ?
          <Modal.Actions>
            <Button primary className="mr10" onClick={() => props.sendReport(tableDataArray, 'ReferralReport', detailsModalType, getTitle(detailsModalType)) }>
              Send Report
            </Button>
          { (detailsModalType !== 'earnings' && detailsModal === 'report') && <Button primary className="mr-0" onClick={ () => props.onSelectEvent({ type: detailsModalType, id: eventDate }, 'details', eventClick) }>
            Report Details
          </Button> }
        </Modal.Actions> : ''
        }
      </Modal>
    );

}

export default ReferralDetailsModal;
